import React from 'react';
import '../css/Spinner.css'; // Assuming you have CSS for the spinner

const Spinner = () => {
  return (
    <div className="spinner">
      <div className="loader" ></div>
    </div>
  );
};

export default Spinner;
