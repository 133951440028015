import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Typography,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    List,
    ListItem,
    ListItemText,
    Container,
    Grid,
    Stepper,
    Step,
    StepLabel,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles'; // Added import
import {
    ArrowBack,
    EventAvailable,
    Person,
    Email,
    Phone,
} from '@mui/icons-material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import {
    format, parseISO, startOfDay, addDays,
    differenceInCalendarDays,
    startOfWeek,
} from 'date-fns';

import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';

// Initialize i18n
if (!i18n.isInitialized) {
    const language = (navigator.language || navigator.userLanguage).split('-')[0];
    i18n.use(initReactI18next).init({
        resources: {
            en: {
                translation: {
                    "welcome_message": "Welcome to Our Booking System",
                    "new_booking": "New Booking",
                    "reschedule_booking": "Reschedule Booking",
                    "cancel_booking": "Cancel Booking",
                    "select_a_service": "Select a Service",
                    "available_slots": "Available Slots for {{serviceName}}",
                    "previous_week": "Previous Week",
                    "next_week": "Next Week",
                    "back": "Back",
                    "select_booking_to_reschedule": "Select the booking you'd like to reschedule",
                    "select_booking_to_cancel": "Select the booking you'd like to cancel",
                    "enter_your_details": "Enter Your Details",
                    "confirm_reschedule": "Confirm Reschedule",
                    "full_name": "Full Name",
                    "email": "Email",
                    "mobile": "Mobile",
                    "cancel": "Cancel",
                    "book_appointment": "Book Appointment",
                    "confirm_reschedule": "Confirm Reschedule",
                    "reschedule_booking_title": "Reschedule Booking",
                    "cancel_booking_title": "Cancel Booking",
                    "search": "Search",
                    "failed_to_fetch_services": "Failed to fetch services.",
                    "failed_to_fetch_booking_slots": "Failed to fetch booking slots.",
                    "error_fetching_services": "Error fetching services:",
                    "error_fetching_booking_slots": "Error fetching booking slots:",
                    "error_booking_slot": "Error booking slot:",
                    "error_rescheduling_slot": "Error rescheduling slot:",
                    "error_canceling_booking": "Error canceling booking:",
                    "failed_to_book_appointment": "Failed to book appointment. Please try again.",
                    "booking_successful": "Booking successful!",
                    "failed_to_reschedule_appointment": "Failed to reschedule appointment. Please try again.",
                    "reschedule_successful": "Reschedule successful!",
                    "failed_to_cancel_booking": "Failed to cancel booking. Please try again.",
                    "booking_cancelled_successfully": "Booking cancelled successfully!",
                    "error_when_cancelling_booking": "Error when cancelling booking. Please contact us.",
                    "select_action": "Select Action",
                    "select_service": "Select Service",
                    "choose_time": "Choose Time",
                    "enter_details": "Enter Details",
                    "pending_booking": "Booking your appointment...",
                    "pending_reschedule": "Rescheduling your appointment...",
                    "pending_cancel": "Cancelling the booking...",
                },
            },
            es: {
                translation: {
                    "welcome_message": "Bienvenido a nuestro sistema de reservas",
                    "new_booking": "Nueva Reserva",
                    "reschedule_booking": "Reprogramar Reserva",
                    "cancel_booking": "Cancelar Reserva",
                    "select_a_service": "Selecciona un Servicio",
                    "available_slots": "Horarios disponibles para {{serviceName}}",
                    "previous_week": "Semana Anterior",
                    "next_week": "Próxima Semana",
                    "back": "Atrás",
                    "select_booking_to_reschedule": "Seleccione la reserva que desea reprogramar",
                    "select_booking_to_cancel": "Seleccione la reserva que desea cancelar",
                    "enter_your_details": "Ingrese sus datos",
                    "confirm_reschedule": "Confirmar Reprogramación",
                    "full_name": "Nombre Completo",
                    "email": "Correo Electrónico",
                    "mobile": "Teléfono Móvil",
                    "cancel": "Cancelar",
                    "book_appointment": "Reservar Cita",
                    "confirm_reschedule": "Confirmar Reprogramación",
                    "reschedule_booking_title": "Reprogramar Reserva",
                    "cancel_booking_title": "Cancelar Reserva",
                    "search": "Buscar",
                    "failed_to_fetch_services": "No se pudo obtener los servicios.",
                    "failed_to_fetch_booking_slots": "No se pudo obtener los horarios de reserva.",
                    "error_fetching_services": "Error al obtener servicios:",
                    "error_fetching_booking_slots": "Error al obtener horarios de reserva:",
                    "error_booking_slot": "Error al reservar horario:",
                    "error_rescheduling_slot": "Error al reprogramar horario:",
                    "error_canceling_booking": "Error al cancelar reserva:",
                    "failed_to_book_appointment": "No se pudo reservar la cita. Por favor, inténtelo de nuevo.",
                    "booking_successful": "¡Reserva exitosa!",
                    "failed_to_reschedule_appointment": "No se pudo reprogramar la cita. Por favor, inténtelo de nuevo.",
                    "reschedule_successful": "¡Reprogramación exitosa!",
                    "failed_to_cancel_booking": "No se pudo cancelar la reserva. Por favor, inténtelo de nuevo.",
                    "booking_cancelled_successfully": "¡Reserva cancelada exitosamente!",
                    "error_when_cancelling_booking": "Error al cancelar la reserva. Por favor, contáctenos.",
                    "select_action": "Seleccionar Acción",
                    "select_service": "Seleccionar Servicio",
                    "choose_time": "Elegir Hora",
                    "enter_details": "Ingresar Detalles",
                    "pending_booking": "Reservando su cita...",
                    "pending_reschedule": "Reprogramando su cita...",
                    "pending_cancel": "Cancelando la reserva...",
                },
            },
            pt: {
                translation: {
                    "welcome_message": "Bem-vindo ao nosso sistema de reservas",
                    "new_booking": "Nova Reserva",
                    "reschedule_booking": "Reagendar Reserva",
                    "cancel_booking": "Cancelar Reserva",
                    "select_a_service": "Selecione um Serviço",
                    "available_slots": "Horários disponíveis para {{serviceName}}",
                    "previous_week": "Semana Anterior",
                    "next_week": "Próxima Semana",
                    "back": "Voltar",
                    "select_booking_to_reschedule": "Selecione a reserva que deseja reagendar",
                    "select_booking_to_cancel": "Selecione a reserva que deseja cancelar",
                    "enter_your_details": "Insira seus dados",
                    "confirm_reschedule": "Confirmar Reagendamento",
                    "full_name": "Nome Completo",
                    "email": "Email",
                    "mobile": "Celular",
                    "cancel": "Cancelar",
                    "book_appointment": "Agendar Consulta",
                    "confirm_reschedule": "Confirmar Reagendamento",
                    "reschedule_booking_title": "Reagendar Reserva",
                    "cancel_booking_title": "Cancelar Reserva",
                    "search": "Buscar",
                    "failed_to_fetch_services": "Falha ao obter serviços.",
                    "failed_to_fetch_booking_slots": "Falha ao obter horários de reserva.",
                    "error_fetching_services": "Erro ao obter serviços:",
                    "error_fetching_booking_slots": "Erro ao obter horários de reserva:",
                    "error_booking_slot": "Erro ao reservar horário:",
                    "error_rescheduling_slot": "Erro ao reagendar horário:",
                    "error_canceling_booking": "Erro ao cancelar reserva:",
                    "failed_to_book_appointment": "Falha ao agendar a consulta. Por favor, tente novamente.",
                    "booking_successful": "Reserva bem-sucedida!",
                    "failed_to_reschedule_appointment": "Falha ao reagendar a consulta. Por favor, tente novamente.",
                    "reschedule_successful": "Reagendamento bem-sucedido!",
                    "failed_to_cancel_booking": "Falha ao cancelar a reserva. Por favor, tente novamente.",
                    "booking_cancelled_successfully": "Reserva cancelada com sucesso!",
                    "error_when_cancelling_booking": "Erro ao cancelar a reserva. Por favor, entre em contato conosco.",
                    "select_action": "Selecionar Ação",
                    "select_service": "Selecionar Serviço",
                    "choose_time": "Escolher Horário",
                    "enter_details": "Inserir Detalhes",
                    "pending_booking": "Agendando sua consulta...",
                    "pending_reschedule": "Reagendando sua consulta...",
                    "pending_cancel": "Cancelando a reserva...",
                },
            },
        },
        lng: language,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });
}

const BookingComponent = ({accId, locId, themeBgColor}) => {
    const { t } = useTranslation();

    // Steps for the Stepper
    const steps = [t('select_action'), t('select_service'), t('choose_time'), t('enter_details')];

    // Fetch account and location IDs from the global config
    const accountId = window.embeddedBookingConfig?.accountId || accId;
    const locationId = window.embeddedBookingConfig?.locationId || locId;
    const themeBackgroundColor = window.embeddedBookingConfig?.themeBackgroundColor || themeBgColor;

    // Create a custom theme with the themeBackgroundColor as primary color
    const theme = createTheme({
        palette: {
            primary: {
                main: themeBackgroundColor,
            },
            // Optionally, you can customize other palette colors here
        },
        typography: {
            fontFamily: 'inherit',
        },
    });

    const [currentAction, setCurrentAction] = useState('initial');
    const [bookingSlots, setBookingSlots] = useState([]);
    const [loadingSlots, setLoadingSlots] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [actionType, setActionType] = useState(null);

    const [userDetails, setUserDetails] = useState({
        fullName: '',
        email: '',
        mobile: '',
    });

    const [openUserDetailsDialog, setOpenUserDetailsDialog] = useState(false);
    const [openCredentialsDialog, setOpenCredentialsDialog] = useState(false);

    const [emailInput, setEmailInput] = useState('');
    const [mobileInput, setMobileInput] = useState('');
    const [bookingData, setBookingData] = useState(null);

    const [loadingAction, setLoadingAction] = useState(false);

    const weekStartsOn = 0; // 0 for Sunday, 1 for Monday

    // Initialize weekStartDate to the start of the current week (Sunday)
    const [weekStartDate, setWeekStartDate] = useState(
        startOfWeek(new Date(), { weekStartsOn })
    );
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [loadingServices, setLoadingServices] = useState(false);

    const fetchServices = async () => {
        setLoadingServices(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/booking_services`, {
                params: {
                    location: locationId,
                    account: accountId,
                },
            });

            const fetchedServices = Array.isArray(response.data.message) ? response.data.message : [];

            const dropDownData = fetchedServices.map((service) => ({
                text: service.name,
                value: service.id,
            }));

            setServices(dropDownData);
        } catch (error) {
            console.error(t('error_fetching_services'), error);
        }
        setLoadingServices(false);
    };

    useEffect(() => {
        fetchServices();
    }, []);

    const handleNewBooking = () => {
        setCurrentAction('selectService');
    };

    const handleRescheduleBooking = () => {
        setActionType('rescheduleBooking');
        setOpenCredentialsDialog(true);
    };

    const handleCancelBooking = () => {
        setActionType('cancelBooking');
        setOpenCredentialsDialog(true);
    };

    const formatDate = (date) => {
        const d = new Date(date);
        d.setHours(0, 0, 0, 0);

        const timezoneOffsetMinutes = -d.getTimezoneOffset();
        const offsetHours = Math.floor(Math.abs(timezoneOffsetMinutes) / 60);
        const offsetMinutes = Math.abs(timezoneOffsetMinutes) % 60;
        const sign = timezoneOffsetMinutes >= 0 ? '+' : '-';
        const pad = (num) => num.toString().padStart(2, '0');

        const offsetString = `${sign}${pad(offsetHours)}:${pad(offsetMinutes)}`;
        const dateString = `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}T${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}${offsetString}`;

        return dateString;
    };

    const handleBookingSelection = (booking) => {
        setSelectedBooking(booking);
        if (actionType === 'rescheduleBooking') {
            setUserDetails({
                fullName: booking.metadata.customer_name,
                email: booking.metadata.customer_email,
                mobile: booking.metadata.customer_mobile,
            });
            const service = {
                text: booking.service.name,
                value: booking.service.id,
            };
            setSelectedService(service);
            fetchBookingSlots(service);
            setCurrentAction('rescheduleBooking');
        } else if (actionType === 'cancelBooking') {
            handleCancel(booking);
        }
    };

    const fetchBookingSlots = async (service) => {
        setLoadingSlots(true);
        try {
            const startDate = new Date();
            const endDate = new Date();
            endDate.setDate(endDate.getDate() + 30);

            const start = formatDate(startDate);
            const end = formatDate(endDate);

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/booking_slots`, {
                params: {
                    service_id: service?.value,
                    start: start,
                    end: end,
                    location: locationId,
                },
            });
            setBookingSlots(response.data.data.data);
        } catch (error) {
            console.error(t('error_fetching_booking_slots'), error);
            toast.error(t('failed_to_fetch_booking_slots'), { position: "top-center" });
        } finally {
            setLoadingSlots(false);
        }
    };

    const handleSlotSelection = (slot) => {
        setSelectedSlot(slot);
        setOpenUserDetailsDialog(true);
    };

    const handleUserDetailsChange = (field) => (event) => {
        setUserDetails((prev) => ({
            ...prev,
            [field]: event.target.value,
        }));
    };

    const handleBook = async () => {
        setLoadingAction(true);

        const bookingPromise = axios.post(`${process.env.REACT_APP_API_URL}/api/web_bookings`, {
            ...userDetails,
            slot: selectedSlot,
            service_id: selectedService.value,
            location: locationId,
        });

        toast.promise(
            bookingPromise,
            {
                pending: t('pending_booking'),
                success: t('booking_successful'),
                error: t('failed_to_book_appointment'),
            },
            { position: "top-center" }
        );

        try {
            await bookingPromise;
            resetState();
        } catch (error) {
            console.error(t('error_booking_slot'), error);
        } finally {
            setLoadingAction(false);
            setOpenUserDetailsDialog(false);
        }
    };

    const handleReschedule = async () => {
        setLoadingAction(true);

        const reschedulePromise = axios.patch(
            `${process.env.REACT_APP_API_URL}/api/web_bookings/${selectedBooking.id}`,
            {
                ...userDetails,
                starts_at: selectedSlot.starts_at,
                ends_at: selectedSlot.ends_at,
                service_id: selectedBooking.service.id,
            }
        );

        toast.promise(
            reschedulePromise,
            {
                pending: t('pending_reschedule'),
                success: t('reschedule_successful'),
                error: t('failed_to_reschedule_appointment'),
            },
            { position: "top-center" }
        );

        try {
            const response = await reschedulePromise;

            if (response.data.success) {
                resetState();
            } else {
                console.error(t('error_rescheduling_slot'), response.data);
            }
        } catch (error) {
            console.error(t('error_rescheduling_slot'), error);
        } finally {
            setLoadingAction(false);
            setOpenUserDetailsDialog(false);
        }
    };

    const handleCancel = async (booking) => {
        setLoadingAction(true);

        const cancelPromise = axios.delete(
            `${process.env.REACT_APP_API_URL}/api/web_bookings/${booking.id}`,
            {
                data: {
                    service_id: booking.service.id,
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        toast.promise(
            cancelPromise,
            {
                pending: t('pending_cancel'),
                success: t('booking_cancelled_successfully'),
                error: t('failed_to_cancel_booking'),
            },
            { position: "top-center" }
        );

        try {
            const response = await cancelPromise;

            if (response.data.success) {
                resetState();
            } else {
                toast.error(t('error_when_cancelling_booking'), { position: "top-center" });
                resetState();
            }
        } catch (error) {
            console.error(t('error_canceling_booking'), error);
        } finally {
            setLoadingAction(false);
            setCurrentAction('initial');
        }
    };

    const handleCredentialsSubmit = async () => {
        setLoadingAction(true);
        try {
            const startDate = new Date();
            const start = formatDate(startDate);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/web_bookings`, {
                params: {
                    acc: accountId,
                    from: start,
                    email: emailInput,
                    mobile: mobileInput,
                },
            });
            setBookingData(response.data);
            setOpenCredentialsDialog(false);
            setCurrentAction('selectBooking');
        } catch (error) {
            console.error(t('error_fetching_booking_slots'), error);
            toast.error(t('failed_to_fetch_booking_slots'), { position: "top-center" });
            setBookingData({ message: { data: [] } });
        } finally {
            setLoadingAction(false);
        }
    };

    const handleServiceSelection = (service) => {
        setSelectedService(service);
        setCurrentAction('newBooking');
        fetchBookingSlots(service);
    };

    const resetState = () => {
        setCurrentAction('initial');
        setSelectedService(null);
        setSelectedSlot(null);
        setSelectedBooking(null);
        setUserDetails({ fullName: '', email: '', mobile: '' });
        setEmailInput('');
        setMobileInput('');
        setBookingData(null);
    };

    const renderInitialButtons = () => (
        <Container maxWidth="sm">
            <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
                <Typography variant="h5" align="center" gutterBottom>
                    {t('welcome_message')}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleNewBooking}
                            startIcon={<EventAvailable />}
                        >
                            {t('new_booking')}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={handleRescheduleBooking}
                            startIcon={<EventAvailable />}
                        >
                            {t('reschedule_booking')}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            color="error"
                            fullWidth
                            onClick={handleCancelBooking}
                            startIcon={<EventAvailable />}
                        >
                            {t('cancel_booking')}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );

    const renderServiceSelection = () => (
        <Container maxWidth="sm">
            <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
                <Typography variant="h6" align="center" gutterBottom>
                    {t('select_a_service')}
                </Typography>
                {loadingServices ? (
                    <Box display="flex" justifyContent="center" mt={2}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Grid container spacing={2}>
                        {services.map((service) => (
                            <Grid item xs={12} key={service.value}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => handleServiceSelection(service)}
                                >
                                    {service.text}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                )}
                <Box mt={2}>
                    <Button
                        variant="text"
                        color="primary"
                        startIcon={<ArrowBack />}
                        onClick={() => setCurrentAction('initial')}
                    >
                        {t('back')}
                    </Button>
                </Box>
            </Paper>
        </Container>
    );

    const renderCalendarView = () => {
        if (!selectedService) {
            return null;
        }
        const today = startOfDay(new Date()); // Get today's date at midnight
        const weekEndDate = addDays(weekStartDate, 6); // Saturday of the current week

        let startDay = weekStartDate;

        // If the current week includes today, start from today
        if (weekStartDate <= today && today <= weekEndDate) {
            startDay = today;
        }

        // Calculate the number of days to display
        const numberOfDays = differenceInCalendarDays(weekEndDate, startDay) + 1;

        // Generate days from startDay to weekEndDate
        const daysOfWeek = Array.from({ length: numberOfDays }).map((_, i) =>
            addDays(startDay, i)
        );
        const slotsByDay = {};

        daysOfWeek.forEach((day) => {
            const dayStr = format(day, 'yyyy-MM-dd');
            slotsByDay[dayStr] = [];
        });
        bookingSlots.forEach((slot) => {
            const slotDate = parseISO(slot.starts_at);
            const dayStr = format(slotDate, 'yyyy-MM-dd');
            if (slotsByDay[dayStr]) {
                slotsByDay[dayStr].push(slot);
            }
        });

        Object.keys(slotsByDay).forEach((dayStr) => {
            slotsByDay[dayStr].sort(
                (a, b) => parseISO(a.starts_at) - parseISO(b.starts_at)
            );
        });

        const maxSlotsPerDay = Math.max(
            ...Object.values(slotsByDay).map((slots) => slots.length)
        );
        const isPreviousWeekDisabled =
            weekStartDate <= startOfWeek(today, { weekStartsOn }); return (
                <Container maxWidth="md">
                    <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
                        <Typography variant="h6" align="center" gutterBottom>
                            {t('available_slots', { serviceName: selectedService.text })}
                        </Typography>
                        <Box display="flex" justifyContent="space-between" my={2}>
                            <Button
                                variant="text"
                                color="primary"
                                startIcon={<ArrowBack />}
                                onClick={() => {
                                    if (currentAction === 'newBooking') {
                                        setCurrentAction('selectService');
                                    } else if (currentAction === 'rescheduleBooking') {
                                        setCurrentAction('selectBooking');
                                    }
                                }}
                            >
                                {t('back')}
                            </Button>
                            <Box>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => setWeekStartDate(addDays(weekStartDate, -7))}
                                    disabled={isPreviousWeekDisabled} // Disable if at current week
                                >
                                    {t('previous_week')}
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => setWeekStartDate(addDays(weekStartDate, 7))}
                                    sx={{ ml: 2 }}
                                >
                                    {t('next_week')}
                                </Button>
                            </Box>
                        </Box>
                        {loadingSlots ? (
                            <Box display="flex" justifyContent="center" mt={2}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <TableContainer component={Paper} sx={{ mt: 2 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {daysOfWeek.map((day) => (
                                                <TableCell key={day.toISOString()} align="center">
                                                    {format(day, 'EEE, MMM d')}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.from({ length: maxSlotsPerDay }).map((_, rowIndex) => (
                                            <TableRow key={rowIndex}>
                                                {daysOfWeek.map((day) => {
                                                    const dayStr = format(day, 'yyyy-MM-dd');
                                                    const slots = slotsByDay[dayStr];
                                                    const slot = slots[rowIndex];
                                                    return (
                                                        <TableCell sx={{ padding: '8px' }} key={day.toISOString()} align="center">
                                                            {slot ? (
                                                                <Button
                                                                    variant="contained"
                                                                    size="small"
                                                                    color="primary"
                                                                    onClick={() => handleSlotSelection(slot)}
                                                                >
                                                                    {format(parseISO(slot.starts_at), 'hh:mm a')}
                                                                </Button>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Paper>
                </Container>
            );
    };

    const renderBookingSelection = () => (
        <Container maxWidth="sm">
            <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
                <Typography variant="h6" gutterBottom align="center">
                    {actionType === "rescheduleBooking"
                        ? t('select_booking_to_reschedule')
                        : t('select_booking_to_cancel')}
                </Typography>
                <List>
                    {bookingData.message.data.map((booking) => (
                        <ListItem
                            key={booking.id}
                            button
                            onClick={() => handleBookingSelection(booking)}
                        >
                            <ListItemText
                                primary={`${booking.service.name} on ${format(
                                    parseISO(booking.starts_at),
                                    'EEE, MMM d, hh:mm a'
                                )}`}
                                secondary={`At ${booking.location.name}`}
                            />
                        </ListItem>
                    ))}
                </List>
                <Box mt={2}>
                    <Button
                        variant="text"
                        color="primary"
                        startIcon={<ArrowBack />}
                        onClick={() => {
                            setBookingData(null);
                            setCurrentAction('initial');
                        }}
                    >
                        {t('back')}
                    </Button>
                </Box>
            </Paper>
        </Container>
    );

    const renderStepper = () => {
        let activeStep = 0;
        switch (currentAction) {
            case 'selectService':
                activeStep = 1;
                break;
            case 'newBooking':
            case 'rescheduleBooking':
                activeStep = 2;
                break;
            case 'enterDetails':
                activeStep = 3;
                break;
            default:
                activeStep = 0;
        }
        return (
            <Container maxWidth="md" sx={{ mt: 4 }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Container>
        );
    };

    return (
        <ThemeProvider theme={theme}> {/* Wrapped with ThemeProvider */}
            <Box>
                <ToastContainer />

                {renderStepper()}
                {currentAction === 'initial' && renderInitialButtons()}
                {currentAction === 'selectService' && renderServiceSelection()}
                {currentAction === 'newBooking' && selectedService && renderCalendarView()}
                {currentAction === 'selectBooking' && bookingData && renderBookingSelection()}
                {currentAction === 'rescheduleBooking' && selectedService && renderCalendarView()}

                {/* User Details Dialog */}
                <Dialog
                    open={openUserDetailsDialog}
                    onClose={() => setOpenUserDetailsDialog(false)}
                >
                    <DialogTitle>
                        {currentAction === 'newBooking' ? t('enter_your_details') : t('confirm_reschedule')}
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            label={t('full_name')}
                            value={userDetails.fullName}
                            onChange={handleUserDetailsChange('fullName')}
                            fullWidth
                            margin="dense"
                            required
                            InputProps={{
                                startAdornment: <Person sx={{ mr: 1 }} />,
                            }}
                        />
                        <TextField
                            label={t('email')}
                            value={userDetails.email}
                            onChange={handleUserDetailsChange('email')}
                            fullWidth
                            margin="dense"
                            required
                            InputProps={{
                                startAdornment: <Email sx={{ mr: 1 }} />,
                            }}
                        />
                        <TextField
                            label={t('mobile')}
                            value={userDetails.mobile}
                            onChange={handleUserDetailsChange('mobile')}
                            fullWidth
                            margin="dense"
                            required
                            InputProps={{
                                startAdornment: <Phone sx={{ mr: 1 }} />,
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setOpenUserDetailsDialog(false);
                            }}
                            color="primary"
                        >
                            {t('cancel')}
                        </Button>

                        <Button
                            onClick={
                                currentAction === 'newBooking' ? handleBook : handleReschedule
                            }
                            color="primary"
                            variant="contained"
                            disabled={loadingAction}
                        >
                            {loadingAction ? (
                                <CircularProgress size={24} />
                            ) : currentAction === 'newBooking' ? (
                                t('book_appointment')
                            ) : (
                                t('confirm_reschedule')
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Credentials Dialog */}
                <Dialog
                    open={openCredentialsDialog}
                    onClose={() => {
                        setOpenCredentialsDialog(false);
                        setCurrentAction('initial');
                        setBookingData(null);
                        setEmailInput('');
                        setMobileInput('');
                    }}
                >
                    <DialogTitle>
                        {actionType === 'rescheduleBooking'
                            ? t('reschedule_booking_title')
                            : t('cancel_booking_title')}
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            label={t('email')}
                            value={emailInput}
                            onChange={(e) => setEmailInput(e.target.value)}
                            fullWidth
                            margin="dense"
                            required
                        />
                        <TextField
                            label={t('mobile')}
                            value={mobileInput}
                            onChange={(e) => setMobileInput(e.target.value)}
                            fullWidth
                            margin="dense"
                            required
                        />

                        <Button
                            onClick={handleCredentialsSubmit}
                            color="primary"
                            variant="contained"
                            disabled={loadingAction}
                            fullWidth
                            sx={{ marginTop: 2 }}
                        >
                            {loadingAction ? <CircularProgress size={24} /> : t('search')}
                        </Button>


                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setOpenCredentialsDialog(false);
                                setCurrentAction('initial');
                                setBookingData(null);
                                setEmailInput('');
                                setMobileInput('');
                            }}
                            color="primary"
                        >
                            {t('back')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Box
                    component="footer"
                    sx={{
                        padding: '1rem',
                        textAlign: 'center',
                        
                    }}
                >
                    <Typography variant="body2" color="textSecondary">
                        Powered by{' '}
                        <a
                            href="https://www.aissie.com.au"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#007BFF', textDecoration: 'none' }}
                        >
                            AIssie
                        </a>
                    </Typography>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default BookingComponent;
