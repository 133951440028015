import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Tooltip, IconButton } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Spinner from './Spinner'; // Assuming Spinner component is in the same directory
import { toast } from 'react-toastify';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Tooltip as RechartsTooltip } from 'recharts';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import InfoIcon from '@mui/icons-material/HelpOutline';
import { useParams } from 'react-router-dom';


const GeneralTab = ({ details }) => {
  const { id } = useParams();
  const [chatbotName, setChatbotName] = useState(details.name);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (details) {
      setChatbotName(details.name || '');
    }
  }, [details]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const token = localStorage.getItem('access_token'); // Assuming the token is stored in localStorage
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard`, {
          headers: {
            Authorization: token,
          },
        });
        setDashboardData(response.data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        toast.error('Failed to fetch dashboard data.', {
          position: "top-center"
      });      }
    };

    fetchDashboardData();
  }, []);

  const handleNameChange = (event) => {
    setChatbotName(event.target.value);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    try {
      const token = localStorage.getItem('access_token'); // Assuming the token is stored in localStorage
      await axios.patch(`${process.env.REACT_APP_API_URL}/api/chatbots/${id}`, { name: chatbotName }, {
        headers: {
          Authorization: token,
        },
      });
      toast.success("AI assistant name updated", {
        position: "top-center"
      });

      setChatbotName(chatbotName)

    } catch (error) {
      console.error('Error saving AI assistant name:', error);
      toast.error('Failed to save AI assistant name.', {
        position: "top-center"
      });
    }
  };

  const handleDeleteClick = () => {
    setShowConfirmDialog(true);
  };

  const confirmDelete = async () => {
    setShowConfirmDialog(false);
    setIsDeleting(true);
    try {
      const token = localStorage.getItem('access_token'); // Assuming the token is stored in localStorage
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/chatbots/${id}`, {
        headers: {
          Authorization: token,
        },
      });
      toast.success('AI assistant deleted successfully!', {
        position: "top-center"
      });
      navigate('/chatbots');
    } catch (error) {
      console.error('Error deleting AI assistant:', error);
      toast.error('Failed to delete AI assistant.', {
        position: "top-center"
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCloseDialog = () => {
    setShowConfirmDialog(false);
  };
  const messagesByRoleData = dashboardData ? dashboardData.messages.map(message => {
    const [year, month, day] = message.date.split('-');
    return {
      day: `${day}/${month}`,
      assistant: message.assistantMessageCount,
      user: message.userMessageCount
    };
  }) : [];
  
  const messagesBySourceData = dashboardData ? dashboardData.messages.map(message => {
    const [year, month, day] = message.date.split('-');
    return {
      day: `${day}/${month}`,
      facebook: message.facebookMessageCount,
      web: message.webMessageCount,
      instagram: message.instagramMessageCount
    };
  }) : [];
  
  

  return (
    <Box>
      {isDeleting && <Spinner />}
      <Box mb={2}>
        <Typography variant="h4" fontWeight="700" padding="1rem 0">Dashboard</Typography>
      </Box>
      <Paper square={false} elevation={4} style={{ padding: "0.5rem", height: '100%', width: '95%', marginBottom: "2rem" }}>
        <Box mb={2}>
          <Typography variant="h6">AI assistant Name:</Typography>
          {isEditing ? (
            <Box display="flex" alignItems="center">
              <TextField
                value={chatbotName}
                onChange={handleNameChange}
                variant="outlined"
                size="small"
                sx={{ mr: 1 }}
              />
              <Button onClick={handleSaveClick} variant="contained" color="primary">
                Save
              </Button>
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              <Typography variant="body1" mr={1}>
                {chatbotName}
              </Typography>
              <Button onClick={handleEditClick} variant="outlined">
                Edit
              </Button>
            </Box>
          )}
        </Box>
      </Paper>

      {dashboardData ? (
        <Grid2 container spacing={2}>
          <Paper square={false} elevation={4} style={{ padding: "0.5rem", height: '100%', width: '95%' }}>
            <Grid2 container sx={{ display: "flex", justifyContent: "space-evenly", width: "100%" }}>

              <Typography sx={{ width: "100%", display: "flex", flexWrap: "wrap" }} variant="h6">Subscription Details</Typography>

              <Paper square={false} elevation={1} style={{ padding: '0.5rem', height: '8rem', width: '18rem' }}>
                <Grid2 container alignItems="center">
                  <Tooltip title="Number of AI assistant responses. When limit is reached the AI assistant will stop responding" placement="top">
                    <Grid2 item sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                      <Grid2 item>
                        <QuestionAnswerIcon style={{ marginRight: '0.5rem', color: 'rgba(0, 0, 0, 0.88)' }} />
                      </Grid2>
                      <Grid2 item xs>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600', color: 'rgba(0, 0, 0, 0.88)' }}>
                          AI Response Usage
                        </Typography>
                      </Grid2>

                      <Grid2 item>
                        <IconButton size="small">
                          <InfoIcon style={{ color: 'rgba(0, 0, 0, 0.88)' }} />
                        </IconButton>
                      </Grid2>
                    </Grid2>
                  </Tooltip>

                  <Grid2 item xs={12} sx={{
                    width: "100%", display: "flex", flexDirection: "column", alignItems: "center"
                  }}>
                    <Typography
                      sx={{ fontWeight: '600', fontSize: '24px', color: 'rgba(0, 0, 0, 0.88)', alignSelf: "center", marginTop: "1.5rem" }}
                      variant="body1"
                    >
                      {dashboardData.assistant_messages} / {dashboardData.messages_limit}
                    </Typography>
                  </Grid2>
                </Grid2>
              </Paper>

              <Paper square={false} elevation={1} style={{ padding: '0.5rem', height: '8rem', width: '18rem' }}>
                <Grid2 container alignItems="center">
                  <Tooltip title="Number of AI assistants created" placement="top">
                    <Grid2 item sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                      <Grid2 item>
                        <QuestionAnswerIcon style={{ marginRight: '0.5rem', color: 'rgba(0, 0, 0, 0.88)' }} />
                      </Grid2>
                      <Grid2 item xs>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600', color: 'rgba(0, 0, 0, 0.88)' }}>
                          AI assistant Usage
                        </Typography>
                      </Grid2>

                      <Grid2 item>
                        <IconButton size="small">
                          <InfoIcon style={{ color: 'rgba(0, 0, 0, 0.88)' }} />
                        </IconButton>
                      </Grid2>
                    </Grid2>
                  </Tooltip>

                  <Grid2 item xs={12} sx={{
                    width: "100%", display: "flex", flexDirection: "column", alignItems: "center"
                  }}>
                    <Typography
                      sx={{ fontWeight: '600', fontSize: '24px', color: 'rgba(0, 0, 0, 0.88)', alignSelf: "center", marginTop: "1.5rem" }}
                      variant="body1"
                    >
                      {dashboardData.chatbots} / {dashboardData.chatbots_limit}
                    </Typography>
                  </Grid2>
                </Grid2>
              </Paper>

              <Paper square={false} elevation={1} style={{ padding: '0.5rem', height: '8rem', width: '18rem' }}>
                <Grid2 container alignItems="center">
                  <Tooltip title="Numbers of characters used in the Knowledge Base. Each file, FAQ and/or website entered in the Knowledge Base will consume your characters credits" placement="top">
                    <Grid2 item sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                      <Grid2 item>
                        <QuestionAnswerIcon style={{ marginRight: '0.5rem', color: 'rgba(0, 0, 0, 0.88)' }} />
                      </Grid2>
                      <Grid2 item xs>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600', color: 'rgba(0, 0, 0, 0.88)' }}>
                          Characters Usage
                        </Typography>
                      </Grid2>

                      <Grid2 item>
                        <IconButton size="small">
                          <InfoIcon style={{ color: 'rgba(0, 0, 0, 0.88)' }} />
                        </IconButton>
                      </Grid2>
                    </Grid2>
                  </Tooltip>

                  <Grid2 item xs={12} sx={{
                    width: "100%", display: "flex", flexDirection: "column", alignItems: "center"
                  }}>
                    <Typography
                      sx={{ fontWeight: '600', fontSize: '24px', color: 'rgba(0, 0, 0, 0.88)', alignSelf: "center", marginTop: "1.5rem" }}
                      variant="body1"
                    >
                      {dashboardData.characters.toLocaleString('en-AU')} / {dashboardData.characters_limit.toLocaleString('en-AU')}
                    </Typography>
                  </Grid2>
                </Grid2>
              </Paper>
            </Grid2>
          </Paper>
          <Paper square={false} elevation={4} style={{ padding: "0.5rem", height: '100%', width: '95%' }}>
            <Typography sx={{ width: "100%" }} variant="h6">Analytics</Typography>
            <Paper square={false} elevation={1} style={{ padding: "0.5rem", height: '100%' }}>
              <Grid2 item xs={12} md={6}>
                <Typography variant="h6">Conversations</Typography>
                <Typography variant="body1">{dashboardData.conversations}</Typography>
              </Grid2>
            </Paper>
            <Paper square={false} elevation={1} style={{ padding: "0.5rem", height: '100%', width: "95%" }}>
              <Grid2 item xs={12}>
                <Typography variant="h6">User Messages Per Day (By Role)</Typography>
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart data={messagesByRoleData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="day" />
                    <YAxis />
                    <RechartsTooltip />
                    <Legend />
                    <Bar dataKey="assistant" fill="#06525B" name="Assistant" />
                    <Bar dataKey="user" fill="#82ca9d" name="User" />
                  </BarChart>
                </ResponsiveContainer>
              </Grid2>
            </Paper>
            <Paper square={false} elevation={1} style={{ padding: "0.5rem", height: '100%', width: "95%" }}>
              <Grid2 item xs={12}>
                <Typography variant="h6">Messages Per Day (By Source)</Typography>
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart data={messagesBySourceData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="day" />
                    <YAxis />
                    <RechartsTooltip />
                    <Legend />
                    <Bar dataKey="facebook" fill="#06525B" name="Facebook" />
                    <Bar dataKey="web" fill="#8884d8" name="Web" />
                    <Bar dataKey="instagram" fill="#82ca9d" name="Instagram" />
                  </BarChart>
                </ResponsiveContainer>
              </Grid2>
            </Paper>
          </Paper>
        </Grid2>
      ) : (
        <Spinner />
      )}
      <Paper square={false} elevation={4} style={{ padding: "0.5rem", height: '100%', width: '95%' }}>
        <Box width={"100%"} mt={4} sx={{ width: "100%" }}>
          <Typography variant="h6" color="error">
            Danger Area
          </Typography>
          <Typography>
            Deleting your AI assistant is a permanent action. Ensure you are certain before continuing. All data you have uploaded will be <b>permanently removed. This cannot be undone.</b>
          </Typography>
          <Button onClick={handleDeleteClick} variant="contained" color="error">
            Delete AI assistant
          </Button>
        </Box>
      </Paper>
      <Dialog
        open={showConfirmDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this AI assistant? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box >
  );
};

export default GeneralTab;
