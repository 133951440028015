import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import Spinner from './Spinner'; // Assuming Spinner component is in the same directory
import { toast } from 'react-toastify';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
const PhoneTextField = React.forwardRef(({ value, onChange, ...rest }, ref) => {
  return (
    <TextField
      {...rest}
      value={value || ''}
      onChange={(event) => onChange(event.target.value || '')}
      fullWidth
      margin="normal"
      inputRef={ref}
    />
  );
});

const Profile = ({ profile }) => {
  const [id] = useState(profile.id);

  const [firstName, setFirstName] = useState(profile.first_name);
  const [lastName, setLastName] = useState(profile.last_name);
  const [email, setEmail] = useState(profile.email);
  const [mobile, setMobile] = useState(profile.mobile)

  // State variables for password change dialog
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');



  // Handle password change
  const handleChangePassword = async () => {
    setPasswordError('');

    // Validate new password and confirm password match
    if (newPassword !== confirmNewPassword) {
      setPasswordError('New password and confirm password do not match');
      return;
    }

    // Validate new password meets best practices
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      setPasswordError(
        'Password must be at least 8 characters long and include uppercase, lowercase, number, and special character'
      );
      return;
    }

    try {
      const token = localStorage.getItem('access_token');
      const changePassword = async () => {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/change_password`,
          {
            current_password: currentPassword,
            new_password: newPassword,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
      };
      toast.promise(
        changePassword(),
        {
          pending: 'Changing Password...',
          success: 'Password changed successfully',
          error: 'Error changing password',
        },
        {
          position: 'top-center',
        }
      );
      // Close the dialog and clear password fields after successful change
      setChangePasswordOpen(false);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (error) {
      console.error('Error changing password:', error);
      setPasswordError('Error changing password');
    }
  };

// Handle save button click
const handleSave = async () => {
  try {
    const token = localStorage.getItem('access_token');
    const saveUser = async () => {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/users/${id}`,
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          mobile: mobile,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      
      // Check if the response was successful
      if (response.data.success) {
        return Promise.resolve(); // resolves promise if success is true
      } else {
        return Promise.reject(new Error(response.data.message)); // rejects promise with the message if success is false
      }
    };

    toast.promise(
      saveUser(),
      {
        pending: 'Updating User...',
        success: 'User updated successfully',
        error: {
          render({ data }) {
            // Displays the error message from response.data.message
            return data.message || 'Error updating User';
          },
        },
      },
      {
        position: 'top-center',
      }
    );
  } catch (error) {
    console.error('Error updating profile:', error);
  }
};

  return (
    <div>
      <Typography variant="h4" fontWeight="700" padding="1rem 0">
        Profile
      </Typography>
      <Typography fontWeight="400" marginBottom={'1rem'}>
        Update your profile data
      </Typography>
      {profile ? (
        <Paper
          square={false}
          elevation={4}
          style={{ padding: '0.5rem', width: '95%', marginBottom: '1rem' }}
        >
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
              display: 'flex',
              flexDirection: 'column',
            }}
            noValidate
            autoComplete="off"
            mt={2}
            fullWidth
          >
            <TextField
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <PhoneInput
              placeholder="Enter phone number"
              defaultCountry="AU"
              value={mobile}
              onChange={setMobile}
              inputComponent={PhoneTextField}
              error={Boolean(mobile && !isPossiblePhoneNumber(mobile))}
              helperText={
                mobile
                  ? isPossiblePhoneNumber(mobile)
                    ? undefined
                    : 'Invalid phone number'
                  : 'Phone number required'
              }
            />

          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ m: 1 }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setChangePasswordOpen(true)}
            sx={{ m: 1 }}
          >
            Change Password
          </Button>
        </Paper>
      ) : (
        <Spinner />
      )}
      {/* Change Password Dialog */}
      <Dialog
        open={changePasswordOpen}
        onClose={() => setChangePasswordOpen(false)}
        fullWidth
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            label="Current Password"
            type={showCurrentPassword ? 'text' : 'password'}
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                  edge="end"
                >
                  {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <TextField
            label="New Password"
            type={showNewPassword ? 'text' : 'password'}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  edge="end"
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <TextField
            label="Confirm New Password"
            type={showConfirmNewPassword ? 'text' : 'password'}
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() =>
                    setShowConfirmNewPassword(!showConfirmNewPassword)
                  }
                  edge="end"
                >
                  {showConfirmNewPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              ),
            }}
          />
          {passwordError && (
            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
              {passwordError}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setChangePasswordOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button onClick={handleChangePassword} color="primary">
            Change Password
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Profile;
