import React, {  useEffect, useRef,useContext } from 'react';
import { Link } from 'react-router-dom';
import './Navigation.css';
import Logo from './images/logolight6.webp';
import { AuthContext } from './components/AuthContext';

const Navigation = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext); // Use AuthContext
  const navRef = useRef(); // Ref for the nav

  // Check login status on component mount
  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    setIsLoggedIn(!!accessToken); // Set login status based on access token
  }, [setIsLoggedIn]);

  return (
    <nav className="navbar" ref={navRef}>
      <div className="nav-brand-logo">
        <Link to="https://www.aissie.com.au" >
          <img src={Logo} alt="AI Assistant" className="nav-image" />
        </Link>
      </div>


    </nav>
  );
};

export default Navigation;
