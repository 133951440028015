import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ResetPassword.css';
import Navigation from '../Navigation';
import {
  TextField,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
} from '@mui/material'; // Import Material-UI components
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

// Define password requirements
const passwordRequirements = [
  {
    label: 'At least 8 characters',
    test: (password) => password.length >= 8,
  },
  {
    label: 'At least one uppercase letter',
    test: (password) => /[A-Z]/.test(password),
  },
  {
    label: 'At least one lowercase letter',
    test: (password) => /[a-z]/.test(password),
  },
  {
    label: 'At least one number',
    test: (password) => /[0-9]/.test(password),
  },
  {
    label: 'At least one special character',
    test: (password) => /[\W_]/.test(password),
  },
];

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const isPasswordValid = passwordRequirements.every((req) => req.test(password));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    // Check if password meets all requirements
    if (!isPasswordValid) {
      setError('Please meet all password requirements before submitting.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/reset_password/${token}`,
        { password }
      );
      setMessage({ text: response.data.message, type: 'success' });
      if (response.data.success) {
        if (response.data.access_token) {
          localStorage.setItem('access_token', response.data.access_token);
          navigate('/chatbots');
        } else {
          setError('Access token not received from the server');
        }
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setMessage({
        text: 'Unable to update password as the password reset request has expired. Please initiate a new password reset request.',
        type: 'error',
      });
    }
  };

  return (
    <div className="reset-password-container">
      <Navigation />
      <h2 className="reset-password-heading">Enter New Password</h2>
      <form onSubmit={handleSubmit} className="reset-password-form">
        {/* New Password Field */}
        <div className="reset-password-input-group password-group">
          <TextField
            InputLabelProps={{ shrink: true }}
            type={showPassword ? 'text' : 'password'}
            label="New Password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            error={password.length > 0 && !isPasswordValid}
            helperText={
              password.length > 0 && !isPasswordValid
                ? 'Please meet all password requirements.'
                : ''
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* Password Requirements List */}
          <List dense>
            {passwordRequirements.map((req, index) => {
              const isValid = req.test(password);
              return (
                <ListItem key={index}>
                  <ListItemIcon>
                    {isValid ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <CancelIcon color="error" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={req.label}
                    primaryTypographyProps={{
                      style: { color: isValid ? 'green' : 'red' },
                    }}
                  />
                </ListItem>
              );
            })}
          </List>
        </div>

        {/* Confirm Password Field */}
        <div className="reset-password-input-group password-group">
          <TextField
            InputLabelProps={{ shrink: true }}
            type={showConfirmPassword ? 'text' : 'password'}
            label="Confirm Password"
            variant="outlined"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            error={confirmPassword.length > 0 && confirmPassword !== password}
            helperText={
              confirmPassword.length > 0 && confirmPassword !== password
                ? 'Passwords do not match'
                : ''
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* Confirm Password Matching Indicator */}
          {confirmPassword.length > 0 && (
            <List dense>
              <ListItem>
                <ListItemIcon>
                  {confirmPassword === password ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <CancelIcon color="error" />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Passwords match"
                  primaryTypographyProps={{
                    style: {
                      color: confirmPassword === password ? 'green' : 'red',
                    },
                  }}
                />
              </ListItem>
            </List>
          )}
        </div>

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="reset-password-button"
          fullWidth
        >
          Reset Password
        </Button>
      </form>

      {/* Success or Error Messages */}
      {message && (
        <Typography
          variant="body2"
          className={`reset-password-message ${message.type}`}
          sx={{ marginTop: '10px' }}
        >
          {message.text}
        </Typography>
      )}
      {error && (
        <Typography
          variant="body2"
          color="error"
          className="signup-error"
          sx={{ marginTop: '10px' }}
        >
          {error}
        </Typography>
      )}
    </div>
  );
};

export default ResetPassword;
