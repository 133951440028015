import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import ConversationList from './ConversationList';
import ConversationDetail from './ConversationDetail';
import FilterOptions from './FilterOptions';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const ChatMessagesTab = ({ details }) => {
  const [chatbotId, setChatbotId] = useState('');
  const [messages, setMessages] = useState([]);
  const [groupedMessages, setGroupedMessages] = useState({});
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [filters, setFilters] = useState({
    from: dayjs().subtract(7, 'day').format('YYYY-MM-DDTHH:mm'),
    to: dayjs().format('YYYY-MM-DDTHH:mm'),
    source: ''
  });
  const [sources, setSources] = useState([]);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (details && details.id) {
      setChatbotId(details.id);
    }
  }, [details]);

  useEffect(() => {
    const fetchMessages = async () => {
      if (!chatbotId) {
        console.error('AI assistant ID is undefined or null.');
        return;
      }
      try {
        const token = localStorage.getItem('access_token'); // Assuming the token is stored in localStorage
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/chatbots/${chatbotId}/messages`, {
          headers: {
            Authorization: token,
          },
          params: {
            from: dayjs(filters.from).tz('UTC').format('YYYY-MM-DDTHH:mm'),
            to: dayjs(filters.to).tz('UTC').format('YYYY-MM-DDTHH:mm'),
            source: filters.source,
          },
        });
        const messages = response.data.messages.map(message => ({
          ...message,
          created: dayjs(message.created).tz(userTimezone).format('YYYY-MM-DDTHH:mm')
        }));
        console.log('Fetched messages:', messages); // Log fetched messages
        setMessages(messages);
        const uniqueSources = [...new Set(messages.map(message => message.source))];
        setSources(uniqueSources);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    if (chatbotId) {
      fetchMessages();
    }
  }, [chatbotId, filters, userTimezone]);

  useEffect(() => {
    const groupMessagesByUser = () => {
      console.log('Applying filters:', filters); // Log filters
      const filteredMessages = messages.filter(message => {
        const messageDate = dayjs(message.created).tz(userTimezone);
        const matchDate = messageDate.isAfter(dayjs(filters.from).tz(userTimezone)) && messageDate.isBefore(dayjs(filters.to).tz(userTimezone));
        const matchSource = filters.source ? message.source === filters.source : true;
        return matchDate && matchSource;
      });
      console.log('Filtered messages:', filteredMessages); // Log filtered messages

      const grouped = filteredMessages.reduce((acc, message) => {
        const { end_user_id, source } = message;
        if (!acc[end_user_id]) {
          acc[end_user_id] = { messages: [], channelName: source };
        }
        acc[end_user_id].messages.push(message);
        return acc;
      }, {});
      console.log('Grouped messages:', grouped); // Log grouped messages
      setGroupedMessages(grouped);

      // Automatically select the first conversation if available
      const userIds = Object.keys(grouped);
      if (userIds.length > 0) {
        setSelectedConversation(userIds[0]);
      } else {
        setSelectedConversation(null); // Reset the selected conversation when filters change
      }
    };

    groupMessagesByUser();
  }, [messages, filters, userTimezone]);

  const handleConversationClick = (userId) => {
    setSelectedConversation(userId);
  };

  const handleFilterChange = (newFilters) => {
    console.log('Filters updated:', newFilters);
    setFilters(newFilters);
  };

  return (
    <Box display="flex">
      <Paper square={false} elevation={4} style={{ height: '100%', width: '40%', marginRight: "2rem" }}>
        <Box overflow="auto">
          <FilterOptions filters={filters} onFilterChange={handleFilterChange} sources={sources} />
          <ConversationList groupedMessages={groupedMessages} onConversationClick={handleConversationClick} selectedConversation={selectedConversation} />
        </Box>
      </Paper>
      <Paper square={false} elevation={4} style={{ height: '100%', width: '60%' }}>
        <Box>
          {selectedConversation && groupedMessages[selectedConversation] ? (
            <ConversationDetail messages={groupedMessages[selectedConversation].messages} />
          ) : (
            <Typography variant="h6" align="center" mt={4}>
              {Object.keys(groupedMessages).length === 0 ? 'No messages found' : 'Select a conversation to view details'}
            </Typography>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default ChatMessagesTab;
