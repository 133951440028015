// src/components/ModelTab.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Slider,
  Paper
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';


const ModelTab = ({ }) => {
  const { id } = useParams();

  const details = localStorage.getItem('aissie-chatbot-details-' + id)
    ? JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id))
    : [];
  const [chatbotId, setChatbotId] = useState(details.id);
  const [isSaving, setIsSaving] = useState(false);
  const [model, setModel] = useState(details.model);
  const [prompt, setPrompts] = useState(details.prompt);
  const [temperature, setTemperature] = useState(details.temperature);
  const [isModified, setIsModified] = useState(false); // New flag for tracking modifications

  const marks = [
    {
      value: 0,
      label: 'Straightforward and Accurate Responses.',
    },
    {
      value: 1,
      label: 'Creative Responses. May provide incorrect outputs',
    }
  ];

  useEffect(() => {
    const localStorageDetails = JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id));

    if ((JSON.stringify(details) !== JSON.stringify(localStorageDetails)) && !isModified) {
      setChatbotId(details.id || '');
      setModel(details.model || '');
      setPrompts(details.prompt || '');
      setTemperature(details.temperature !== undefined && details.temperature !== null ? details.temperature : 0);
    }
  }, [details]);

  
  useEffect(() => {
    const localStorageDetails = JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id));
      setChatbotId(details.id || '');
      setModel(details.model || '');
      setPrompts(details.prompt || '');
      setTemperature(details.temperature !== undefined && details.temperature !== null ? details.temperature : 0);
    
  }, [id]);

  useEffect(() => {
    if (isModified) {
      saveStateToLocalStorage();
    }
  }, [
    isSaving
  ]);

  const handleModelChange = (event) => {
    setModel(event.target.value);
  };

  const handlePromptsChange = (event) => {
    setPrompts(event.target.value);
  };

  const handleTemperatureChange = (event, newValue) => {
    setTemperature(newValue);
  };

  // Function to save state to local storage
  const saveStateToLocalStorage = () => {
    console.log('save state function called');

    // Retrieve the existing state from local storage
    const existingState = JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id)) || {};

    // Define the new state
    const newState = {
      id: chatbotId,
      model: model,
      prompt: prompt,
      temperature: temperature

    };

    // Update only the keys that exist in the new state
    const updatedState = {
      ...existingState,
      ...newState,
    };

    // Save the updated state to local storage
    localStorage.setItem('aissie-chatbot-details-' + id, JSON.stringify(updatedState));
  };

  const handleSaveClick = async () => {
    setIsSaving(true);

    const savePromise = axios.patch(`${process.env.REACT_APP_API_URL}/api/chatbots/${chatbotId}`, { model: model, temperature: temperature, prompt: prompt }, {
      headers: {
        Authorization: localStorage.getItem('access_token'),
      },
    });

    toast.promise(
      savePromise,
      {
        pending: 'Saving advanced settings...',
        success: 'Advanced settings saved successfully!',
        error: 'Failed to save advanced settings.',
      }, {
        position: "top-center"
    }
    );

    try {
      await savePromise;
    } catch (error) {
      console.error('Error saving AI assistant name:', error);
    }
    finally {
      setIsSaving(false);
      setIsModified(true);


    }
  };


  return (
    <Box>
      <Typography variant="h4" fontWeight="700" padding="1rem 0">Advanced Settings</Typography>
      <Typography fontWeight="400" marginBottom={"1rem"}>You can change the model, prompt and its temperature. Warning: Only modify this setting if you are confident in what you're doing. Incorrect configurations may cause the AIssie virtual assistant to respond inaccurately.  </Typography>

      <Paper square={false} elevation={4} style={{width: '95%', padding: "1rem" }}>

        <Box mb={2}>

          <Typography variant="h6">Model:</Typography>
          <Typography fontWeight="400" marginBottom={"1rem"}>More information on models can be found <a style={{color: '#1976d2',textDecoration: 'underline',cursor: 'pointer',marginBottom: '10px',}} href='https://platform.openai.com/docs/models' target="blank"> here</a></Typography>

          <TextField
            sx={{
              backgroundColor: 'white', width: "20rem;"
            }}
            select
            value={model}
            onChange={handleModelChange}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="gpt-4o-mini">gpt-4o-mini</MenuItem>
            <MenuItem value="gpt-4o">gpt-4o</MenuItem>
          </TextField>
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Instructions:</Typography>
          <Typography fontWeight="400" marginBottom={"1rem"}>More information on providing instructions to the virtual assistant can be found <a style={{color: '#1976d2',textDecoration: 'underline',cursor: 'pointer',marginBottom: '10px',}} href='https://help.openai.com/en/articles/6654000-best-practices-for-prompt-engineering-with-the-openai-api' target="blank"> here</a></Typography>

          <TextField
            sx={{
              backgroundColor: 'white'
            }}
            value={prompt}
            onChange={handlePromptsChange}
            variant="outlined"
            fullWidth
            multiline
            rows={15}
            helperText="The instructions allows you to customise your AI assistant's personality and style. Please make sure to experiment with the instructions by making it very specific to your data and use case."
          />
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Temperature:</Typography>
          <Slider
            value={temperature}
            onChange={handleTemperatureChange}
            step={0.1}
            min={0.0}
            max={1.0}
            marks={marks}
            valueLabelDisplay="auto"
            sx={{
              width: "50%", display: 'flex', left: "25%", color: '#06525B',
              '& .MuiSlider-markLabel': {
                whiteSpace: 'normal',       // Allow text to wrap
                wordBreak: 'break-word',    // Break long words
                textAlign: 'center',        // Center the text
                width: '127px',           // Set a max width for wrapping
                fontSize: '0.75rem',        // Adjust font size if necessary
              },

            }}

          />
        </Box>
        <Button onClick={handleSaveClick} variant="contained" color="primary" sx={{marginTop: '15px'}}>
          Save
        </Button>
      </Paper>
    </Box>
  );
};

export default ModelTab;
