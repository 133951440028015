import React from 'react';
import { Typography, Paper } from '@mui/material';


const Support = ({ setShowJoyride }) => {






    return (
        <div>
            <Typography variant="h4" fontWeight="700" padding="1rem 0">Support</Typography>
            <Paper square={false} elevation={4} style={{ padding: "0.5rem", width: '100%', marginBottom: "1rem" }}>
                <Typography fontWeight="400" marginBottom={"1rem"}>
                    If you have any questions, feedback, or suggestions, we'd be delighted to hear from you!
                    Feel free to email us at <a href="mailto:contactus@aissie.com.au" style={{
                        color: '#1976d2', // Replace 'primary.main' with the specific colour value
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        marginBottom: '10px'
                    }}> contactus@aissie.com.au</a>.
                </Typography>
                <Typography fontWeight="400" marginBottom={"1rem"}>
                    We also provide a website tour to show you how to use it. Click{' '}
                    <a
                        href="#"
                        style={{
                            color: '#1976d2', // Replace 'primary.main' with the specific colour value
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            marginBottom: '10px'
                        }}
                        onClick={() => {
                            setShowJoyride(false); // Reset Joyride state
                            setTimeout(() => setShowJoyride(true), 0); // Re-enable Joyride
                        }}
                    >
                        here
                    </a>{' '}
                    to start the tour.
                </Typography>





            </Paper>


        </div >
    );
};

export default Support;
