import React, { useState, useEffect } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Spinner from '../components/Spinner';
import axios from 'axios';
import {
  Box,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Switch,
  TextField,
  Autocomplete,
  Chip,
  createFilterOptions,
  IconButton,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'; // Importing Drag Handle Icon

import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { isMobile } from '@syncfusion/ej2-react-schedule';

// SortableListItem Component for drag-and-drop
const SortableListItem = ({ field, onDelete, onToggleMandatory }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: field.name });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    cursor: 'grab',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '8px 16px',
    borderRadius: '4px',
    marginBottom: '4px',
  };

  return (
    <ListItem ref={setNodeRef} style={style} {...attributes} {...listeners} disableGutters>
      <IconButton
        {...listeners}
        style={{ marginRight: '8px', cursor: 'grab' }}
        size="small"
      >
        <DragIndicatorIcon />
      </IconButton>
      <ListItemText
        primary={
          <Typography
            variant="body1"
            sx={{
              maxWidth: '200px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {field.name}
          </Typography>
        }
        sx={{
          flex: '0 0 200px', // Prevent flex-grow and set fixed width
          marginRight: '16px', // Space between field name and checkbox
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={field.mandatory}
            onChange={() => onToggleMandatory(field.name)}
            color="primary"
          />
        }
        label="Mandatory"
      />
      <IconButton edge="end" color="error" onClick={() => onDelete(field.name)}>
        <DeleteIcon />
      </IconButton>
    </ListItem>
  );
};

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} sx={{ elevation: 1, variant: "outlined", square: false, height: '100%' }} {...props} ref={ref} className="table-container" />
  )),
  Table: (props) => (
    <Table {...props} className="table" sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
  TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

const Leads = () => {
  const { id } = useParams();
  const details = localStorage.getItem('aissie-chatbot-details-' + id)
    ? JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id))
    : [];

  // State for leads table
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Confirm dialog for deletion
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => () => { });

  // State for lead configuration
  const [active, setActive] = useState(details.lead_configuration.active || false);
  const [required, setRequired] = useState(details.lead_configuration.required || false) ;
  const [fields, setFields] = useState(details.lead_configuration.fields);
  const [leadMessage, setLeadMessage] = useState(details.lead_configuration.message);
  const [numMessages, setNumMessages] = useState(details.lead_configuration.num_messages);
  const [predefinedFields, setPredefinedFields] = useState(details.lead_configuration.fields);
  const filter = createFilterOptions();

  // State for Hubspot dialog
  const [hubspotDialogOpen, setHubspotDialogOpen] = useState(false);
  const [hubspotActive, setHubspotActive] = useState(details.hubspot_active || false);
  const [hubspotToken] = useState(details.hubspot || '');

  // HubSpot properties (fetched from endpoint)
  const [hubspotProperties, setHubspotProperties] = useState([]);

  // hubspotMappings will store objects like { label: '...', name: '...' }
  const [hubspotMappings, setHubspotMappings] = useState({});

  // Additional HubSpot fields not collected by AIssie
  const [hubspotAdditionalFields, setHubspotAdditionalFields] = useState([]);

  // Test HubSpot Integration dialog state
  const [testHubspotDialogOpen, setTestHubspotDialogOpen] = useState(false);
  const [testHubspotLeadDetails, setTestHubspotLeadDetails] = useState({});
  const [testHubspotResult, setTestHubspotResult] = useState(null);
  const [testHubspotLoading, setTestHubspotLoading] = useState(false);

  // Initialize sensors for drag-and-drop
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setFields((items) => {
        const oldIndex = items.findIndex((field) => field.name === active.id);
        const newIndex = items.findIndex((field) => field.name === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  // Fetch leads on component mount
  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/leads?chatbot_id=${id}`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setLeads(response.data.leads);
      } catch (err) {
        console.error('Error fetching leads:', err);
        setError('Failed to load leads');
        toast.error('Failed to load leads');
      }
    };

    fetchLeads()
      .then(() => setLoading(false))
      .catch((error) => {
        console.error('Error in fetching data:', error);
        setLoading(false);
      });
  }, [id]);

  const fetchHubspotProperties = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('access_token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/hubspot-properties`, {}, {
        headers: {
          Authorization: token,
        },
      });
      if (response.data && response.data.results) {
        setLoading(false);
        // Sort properties by label alphabetically
        response.data.results.sort((a, b) => a.label.localeCompare(b.label));
        setHubspotProperties(response.data.results);
        // Initialise mappings now that we have the properties
        initialiseHubspotMappings(response.data.results);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching HubSpot properties:", error);
    }
  };

  const initialiseHubspotMappings = (properties) => {
    const initialMappings = {};
    if (details.hubspot_mappings) {
      fields.forEach((f) => {
        const val = details.hubspot_mappings[f.name];
        if (val && typeof val === 'object' && val.name && val.label) {
          const prop = properties.find(p => p.name === val.name);
          if (prop) {
            initialMappings[f.name] = { label: prop.label, name: prop.name };
          } else {
            initialMappings[f.name] = { label: val.label, name: val.name };
          }
        } else if (val && typeof val === 'string') {
          const prop = properties.find(p => p.name === val);
          if (prop) {
            initialMappings[f.name] = { label: prop.label, name: prop.name };
          } else {
            initialMappings[f.name] = { label: '', name: '' };
          }
        } else {
          initialMappings[f.name] = { label: '', name: '' };
        }
      });

      if (details.hubspot_mappings._additional) {
        const additional = details.hubspot_mappings._additional.map(item => {
          let propObj = { label: '', name: '' };
          if (item.hubspotProperty && typeof item.hubspotProperty === 'object' && item.hubspotProperty.name) {
            const prop = properties.find(p => p.name === item.hubspotProperty.name);
            if (prop) {
              propObj = { label: prop.label, name: prop.name };
            }
          } else if (item.hubspotProperty && typeof item.hubspotProperty === 'string') {
            const prop = properties.find(p => p.name === item.hubspotProperty);
            if (prop) {
              propObj = { label: prop.label, name: prop.name };
            }
          }

          return { hubspotProperty: propObj, defaultValue: item.defaultValue };
        });
        initialMappings._additional = additional;
      }
    } else {
      fields.forEach((f) => {
        initialMappings[f] = { label: '', name: '' };
      });
      initialMappings._additional = [];
    }

    setHubspotMappings(initialMappings);
    if (initialMappings._additional) {
      setHubspotAdditionalFields(initialMappings._additional);
    }
  };

  // Sync local storage details when 'details' changes
  useEffect(() => {
    const localStorageDetails = JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id));

    if (JSON.stringify(details) !== JSON.stringify(localStorageDetails)) {
      const detailsLead_configuration = details.lead_configuration ? details.lead_configuration : {};
      setActive(detailsLead_configuration.required);
      setRequired(detailsLead_configuration.mandatory);
      setFields(detailsLead_configuration.fields);
      setLeadMessage(detailsLead_configuration.message);
      setNumMessages(detailsLead_configuration.num_messages);
    }
  }, [details, id]);

  const handleSaveLeadsConfig = async () => {
    const localStorageKey = `aissie-chatbot-details-${id}`;
    let chatbotDetails = JSON.parse(localStorage.getItem(localStorageKey));

    if (chatbotDetails) {
      chatbotDetails.lead_configuration = {
        active,
        required,
        fields,
        message: leadMessage,
        num_messages: numMessages,
      };

      localStorage.setItem(localStorageKey, JSON.stringify(chatbotDetails));
    }

    const token = localStorage.getItem('access_token');
    const saveChatbot = async () => {
      await axios.patch(`${process.env.REACT_APP_API_URL}/api/chatbots/${details.id}`, {
        lead_configuration: {
          active,
          required,
          fields,
          message: leadMessage,
          num_messages: numMessages
        }
      }, {
        headers: {
          Authorization: token,
        },
      });
    }

    toast.promise(
      saveChatbot(),
      {
        pending: 'Saving AI assistant...',
        success: 'AI assistant saved successfully!',
        error: 'Failed to save AI assistant.'
      }, {
      position: "top-center"
    }).catch(error => {
      console.error('Error saving AI assistant:', error);
    })
  }

  const openConfirmDialog = (message, onConfirmAction) => {
    setDialogMessage(message);
    setOnConfirm(() => onConfirmAction);
    setConfirmDialogOpen(true);
  };

  const handleDelete = async (lead_id) => {
    const deleteLead = async () => {
      const token = localStorage.getItem('access_token');
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/leads/${lead_id}`, {
        headers: {
          Authorization: token,
        },
      });

      const updatedDetails = leads.filter(lead => lead.id !== lead_id);
      setLeads(updatedDetails);
    };

    toast.promise(
      deleteLead(),
      {
        pending: 'Deleting...',
        success: 'Deleted successfully',
        error: 'Error deleting'
      },
      {
        position: "top-center"
      }
    ).catch(error => {
      console.error('Error deleting source:', error);
    });
  };

  const handleOpenHubspotDialog = async () => {
    setHubspotDialogOpen(true);
    if (hubspotToken) {
      await fetchHubspotProperties();
    }
  }

  const handleCloseHubspotDialog = () => {
    setHubspotDialogOpen(false);
  }

  const handleSaveHubspotSettings = async () => {
    const token = localStorage.getItem('access_token');

    const combinedMappings = {
      ...Object.fromEntries(Object.entries(hubspotMappings).filter(([k]) => k !== '_additional')),
      _additional: hubspotAdditionalFields.map((f) => ({
        hubspotProperty: f.hubspotProperty,
        defaultValue: f.defaultValue
      }))
    };

    const saveHubspotSettings = async () => {
      // Patch the accounts endpoint with the token and active state
      await axios.patch(`${process.env.REACT_APP_API_URL}/api/accounts`, {
        hubspot_active: hubspotActive
      }, {
        headers: {
          Authorization: token,
        },
      });

      // Patch the leads endpoint with the hubspot mappings
      await axios.patch(`${process.env.REACT_APP_API_URL}/api/chatbots/${details.id}`, {
        hubspot_mappings: combinedMappings
      }, {
        headers: {
          Authorization: token,
        },
      });
    };

    try {
      await toast.promise(
        saveHubspotSettings(),
        {
          pending: 'Saving HubSpot Settings...',
          success: 'HubSpot settings saved successfully!',
          error: 'Failed to save HubSpot settings.',
        },
        {
          position: "top-center"
        }
      );
      setHubspotDialogOpen(false);
    } catch (error) {
      console.error('Error saving HubSpot settings:', error);
    }
  };

  const handleAddAdditionalField = () => {
    setHubspotAdditionalFields([...hubspotAdditionalFields, { hubspotProperty: { label: '', name: '' }, defaultValue: '' }]);
  };

  const handleRemoveAdditionalField = (index) => {
    const newFields = [...hubspotAdditionalFields];
    newFields.splice(index, 1);
    setHubspotAdditionalFields(newFields);
  };

  const handleAdditionalFieldChange = (index, field, value) => {
    const newFields = [...hubspotAdditionalFields];
    newFields[index][field] = value;
    setHubspotAdditionalFields(newFields);
  };

  const handleOpenTestHubspotDialog = async () => {
    // Reset any previous test results and details
    if (hubspotToken) {
      await fetchHubspotProperties();
    }
    setTestHubspotLeadDetails({});
    setTestHubspotResult(null);
    // Pre-populate fields and additional fields as empty
    const initialDetails = {};
    fields.forEach(f => {
      initialDetails[f.name] = '';
    });
    hubspotAdditionalFields.forEach((af) => {
      initialDetails[af.hubspotProperty?.name || ''] = af.defaultValue || '';
    });
    setTestHubspotLeadDetails(initialDetails);
    setTestHubspotDialogOpen(true);
  };

  const handleCloseTestHubspotDialog = () => {
    setTestHubspotDialogOpen(false);
  };

  const handleTestHubspotFieldChange = (field, value) => {
    setTestHubspotLeadDetails((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  // Handle toggling the mandatory status
  const handleToggleMandatory = (fieldName) => {
    const updatedFields = fields.map((field) =>
      field.name === fieldName ? { ...field, mandatory: !field.mandatory } : field
    );
    setFields(updatedFields);
  };

  const handleCreateContactInHubspot = async () => {
    setTestHubspotLoading(true);
    setTestHubspotResult(null);

    const token = localStorage.getItem('access_token');
    const payload = {
      chatbot_id: details.id
    };
    console.log('testHubspotLeadDetails', testHubspotLeadDetails)
    // Map testHubspotLeadDetails to payload
    const customFields = {};

    Object.keys(testHubspotLeadDetails).forEach((key) => {
      // Normalise the key to lowercase for comparison
      const lowerKey = key.toLowerCase();

      // If it's a standard field, map it to payload directly
      if (['name', 'email', 'phone'].includes(lowerKey)) {
        payload[lowerKey] = testHubspotLeadDetails[key] || null;
      } else {
        // Otherwise, treat it as a custom field
        customFields[key] = testHubspotLeadDetails[key] || null;
      }
    });

    if (Object.keys(customFields).length > 0) {
      payload.custom = customFields;
    }

    toast.promise(
      axios.post(`${process.env.REACT_APP_API_URL}/api/leads`, payload, {
        headers: {
          Authorization: token,
        },
      }),
      {
        pending: 'Creating contact in HubSpot...',
        success: 'Contact created successfully!',
        error: {
          render({ data }) {
            return data?.response?.data?.message || 'Failed to create contact in HubSpot.';
          }
        },
      },
      { position: 'top-center' }
    ).then(response => {
      setTestHubspotResult(JSON.stringify(response.data, null, 2));
    }).catch(error => {
      console.error('Error creating lead in HubSpot:', error);
      setTestHubspotResult(`Error: ${error.response ? JSON.stringify(error.response.data) : error}`);
    }).finally(() => {
      setTestHubspotLoading(false);
    });
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // For preventing duplicate mapping in standard fields:
  const getFilteredOptionsForField = (field) => {
    const usedPropertyNames = Object.entries(hubspotMappings)
      .filter(([otherField]) => otherField !== '_additional' && otherField !== field)
      .map(([_, val]) => val.name)
      .filter(name => !!name);

    return hubspotProperties.filter((prop) => !usedPropertyNames.includes(prop.name) || (hubspotMappings[field]?.name === prop.name));
  };

  // Returns true if this property has enumerations (options)
  const propertyHasOptions = (prop) => {
    return prop && prop.type === 'enumeration' && Array.isArray(prop.options);
  };

  // Get property by name
  const getPropertyByName = (propName) => {
    return hubspotProperties.find(p => p.name === propName);
  };

  // Updated handleFieldsChange to allow only adding new fields without suggestions
  const handleFieldsChange = (event, newValue, reason) => {
    if (reason === 'remove-option') {
      setFields(newValue.map(field => ({ name: field.name || field, mandatory: field.mandatory || false })));
    } else if (typeof newValue === 'string') {
      // Prevent adding fields that already exist
      if (!fields.find(f => f.name === newValue)) {
        setFields([...fields, { name: newValue, mandatory: false }]);
      }
    } else if (newValue && newValue.inputValue) {
      // Prevent adding fields that already exist
      if (!fields.find(f => f.name === newValue.inputValue)) {
        setFields([...fields, { name: newValue.inputValue, mandatory: false }]);
      }
    } else {
      // Handle adding fields from free solo input
      const addedFields = newValue.filter(option => typeof option === 'string' && !fields.find(f => f.name === option));
      const formattedFields = addedFields.map(fieldName => ({ name: fieldName, mandatory: false }));
      setFields([...fields, ...formattedFields]);
    }
  };

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h4" fontWeight="700" padding="1rem 0">Leads</Typography>
        <Typography fontWeight="400" marginBottom={"1rem"}>You can activate the leads capture form below. This will help you capturing your customers’ details!</Typography>
      </Box>
      <Paper elevation={4} square={false} style={{ width: '95%', padding: "0.5rem" }}>

        {/* Lead Configuration Section */}
        <Box mt={4} >
          <Typography variant="h5" fontWeight="600">Lead Configuration</Typography>
          <Box mt={2} display={"flex"}>
            <Typography variant="subtitle1">Active</Typography>
            <Switch checked={active} onChange={(e) => setActive(e.target.checked)} />
            <Typography variant="subtitle1">Mandatory</Typography>
            <Switch checked={required} onChange={(e) => setRequired(e.target.checked)} />
          </Box>

          <Box mt={2}>
            <Typography variant="subtitle1" mt={2}>Lead form message.</Typography>
            <TextField
              value={leadMessage}
              onChange={(e) => setLeadMessage(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{
                backgroundColor: 'white'
              }}
            />
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle1" mt={2}>Number of AI assistant responses before showing the lead form. The form will be shown only once per conversation, whether or not the user fills it out.</Typography>
            <TextField
              value={numMessages}
              onChange={(e) => setNumMessages(parseInt(e.target.value, 10) || 1)}
              variant="outlined"
              type="number"
              inputProps={{ min: 1 }}
              sx={{
                backgroundColor: 'white',
                width: '100px'
              }}
            />
          </Box>
          <Box fullWidth mt={2}>
            <Typography variant="subtitle1">Fields to capture. You may add custom fields to suit your needs.</Typography>
            {/* Update DndContext to wrap the List for drag-and-drop */}
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={fields.map(field => field.name)}
                strategy={verticalListSortingStrategy}
              >
                {/* Autocomplete Component Updated to Allow Only Adding New Fields */}
                <Autocomplete
                  multiple
                  fullWidth
                  freeSolo
                  options={[]} // Removed predefined options to allow only adding new fields
                  getOptionLabel={(option) => option.name || option}
                  value={fields}
                  onChange={handleFieldsChange}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = fields.some((field) => inputValue === field.name);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push(inputValue);
                    }
                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  renderOption={(props, option) => (
                    <li {...props}>
                      {typeof option === 'string' ? option : option.name}
                    </li>
                  )}
                  renderTags={() => null}

                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Add new fields" />
                  )}
                />

                {/* List of Fields with Drag-and-Drop, Mandatory Checkbox, and Delete Icon */}
                <List>
                  {fields.map((field) => (
                    <SortableListItem
                      key={field.name}
                      field={field}
                      onDelete={(name) => {
                        setFields(fields.filter(f => f.name !== name));
                      }}
                      onToggleMandatory={handleToggleMandatory}
                    />
                  ))}
                </List>
              </SortableContext>
            </DndContext>
          </Box>
          <Box mt={4}>
            <Button variant="contained" color="primary" onClick={handleSaveLeadsConfig}>Save</Button>
            {hubspotToken ? (
              <>
                <Button onClick={handleOpenHubspotDialog} style={{ marginLeft: '1rem' }}>
                  HubSpot Settings
                </Button>
                <Button onClick={handleOpenTestHubspotDialog} style={{ marginLeft: '1rem' }}>
                  Test HubSpot Integration
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => {
                    window.open(
                      "https://app.hubspot.com/oauth/authorize?client_id=7cf7ff69-e1c3-4a17-9868-4392b2418472&redirect_uri=https://app.aissie.com.au/hubspot&scope=crm.objects.contacts.write%20oauth%20crm.schemas.contacts.read",
                      "_blank"
                    );
                  }}
                  style={{ marginLeft: '1rem' }}
                >
                  Connect to HubSpot
                </Button>

              </>
            )}
          </Box>
        </Box>
      </Paper>

      <Paper elevation={4} square={false} style={{ marginTop: '2em', width: '95%', padding: "0.5rem" }}>
        <Typography variant="h5" fontWeight="600" marginBottom={'0.5rem'}>Leads</Typography>

        <TableVirtuoso
          allowFullScreen={true}
          selected={true}
          useWindowScroll
          style={{ height: "100%", overflowX: "initial" }}
          data={leads}
          components={VirtuosoTableComponents}
          fixedHeaderContent={() => (
            <TableRow>
              <TableCell style={{ width: '15%', whiteSpace: 'normal', wordBreak: 'break-word' }}>Name</TableCell>
              <TableCell style={{ width: '25%', whiteSpace: 'normal', wordBreak: 'break-word' }}>Email</TableCell>
              <TableCell style={{ width: '10%', whiteSpace: 'normal', wordBreak: 'break-word' }}>Phone</TableCell>
              <TableCell style={{ width: '15%', whiteSpace: 'normal', wordBreak: 'break-word' }}>Custom</TableCell>
              <TableCell style={{ width: '15%', whiteSpace: 'normal', wordBreak: 'break-word' }}>Creation Date</TableCell>
              <TableCell style={{ width: '15%', whiteSpace: 'normal', wordBreak: 'break-word' }}>Action</TableCell>
            </TableRow>
          )}
          itemContent={(index, lead) => (
            <>
              <TableCell style={{ width: '15%', whiteSpace: 'normal', wordBreak: 'break-word' }}>{lead.name}</TableCell>
              <TableCell style={{ width: '25%', whiteSpace: 'normal', wordBreak: 'break-word' }}>{lead.email}</TableCell>
              <TableCell style={{ width: '10%', whiteSpace: 'normal', wordBreak: 'break-word' }}>{lead.phone}</TableCell>
              <TableCell style={{ width: '15%', whiteSpace: 'normal', wordBreak: 'break-word' }}>{lead.custom}</TableCell>
              <TableCell style={{ width: '15%', whiteSpace: 'normal', wordBreak: 'break-word' }}>
                {new Date(lead.created).toLocaleString('en-AU')}
              </TableCell>
              <TableCell style={{ width: '15%', whiteSpace: 'normal', wordBreak: 'break-word' }}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() =>
                    openConfirmDialog(
                      'Are you sure you want to delete this item? This action cannot be undone.',
                      () => handleDelete(lead.id)
                    )
                  }
                  startIcon={<DeleteIcon />}
                  size={isMobile ? ("small") : ("medium")}
                >
                  Delete
                </Button>
              </TableCell>
            </>
          )}
        />
      </Paper>

      {/* Confirm Deletion Dialog */}
      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
          <Button onClick={() => { onConfirm(); setConfirmDialogOpen(false); }} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* HubSpot Dialog */}
      <Dialog open={hubspotDialogOpen} onClose={handleCloseHubspotDialog} fullWidth maxWidth="sm">
        <DialogTitle>HubSpot Settings</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Typography variant="subtitle1">Activate HubSpot Integration</Typography>
            <Switch checked={hubspotActive} onChange={(e) => setHubspotActive(e.target.checked)} />
          </Box>
          {hubspotToken && hubspotProperties.length > 0 && (
            <>
              <Box mb={2}>
                <Typography variant="subtitle1">
                  Map your AIssie fields to HubSpot properties (Left: AIssie Fields, Right: HubSpot Properties)
                </Typography>
                {fields.map((field) => (
                  <Box key={field.name} display="flex" alignItems="center" mt={1} gap={2}>
                    <Typography style={{ width: '30%' }}>{field.name}</Typography>
                    <Autocomplete
                      options={getFilteredOptionsForField(field.name)}
                      value={
                        hubspotMappings[field.name] && hubspotMappings[field.name].name
                          ? hubspotProperties.find((prop) => prop.name === hubspotMappings[field.name].name) || null
                          : null
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setHubspotMappings({
                            ...hubspotMappings,
                            [field.name]: { label: newValue.label, name: newValue.name }
                          });
                        } else {
                          setHubspotMappings({
                            ...hubspotMappings,
                            [field.name]: { label: '', name: '' }
                          });
                        }
                      }}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          sx={{
                            backgroundColor: 'white'
                          }}
                          placeholder={`HubSpot property for ${field.name}`}
                        />
                      )}
                      fullWidth
                    />
                  </Box>
                ))}
              </Box>
              <Box mb={2}>
                <Typography variant="subtitle1">
                  Add additional HubSpot properties you want to set by default (not collected by AIssie):
                </Typography>
                {hubspotAdditionalFields.map((item, index) => {
                  const prop = getPropertyByName(item.hubspotProperty?.name);
                  const hasOptions = propertyHasOptions(prop);

                  return (
                    <Box key={index} display="flex" alignItems="center" mt={1} gap={1}>
                      <Autocomplete
                        options={hubspotProperties}
                        value={
                          item.hubspotProperty && item.hubspotProperty.name
                            ? hubspotProperties.find((prop) => prop.name === item.hubspotProperty.name) || null
                            : null
                        }
                        onChange={(event, newValue) => {
                          const updatedValue = newValue
                            ? { label: newValue.label, name: newValue.name }
                            : { label: '', name: '' };
                          handleAdditionalFieldChange(index, 'hubspotProperty', updatedValue);
                          // If changing property, reset defaultValue
                          handleAdditionalFieldChange(index, 'defaultValue', '');
                        }}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            sx={{
                              backgroundColor: 'white',
                              flex: 1
                            }}
                            placeholder="HubSpot property name"
                          />
                        )}
                        sx={{ flex: 1 }}
                      />
                      {hasOptions ? (
                        <Autocomplete
                          options={prop.options || []}
                          value={
                            prop.options?.find(o => o.value === item.defaultValue) || null
                          }
                          onChange={(e, newVal) => {
                            handleAdditionalFieldChange(index, 'defaultValue', newVal ? newVal.value : '');
                          }}
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              sx={{
                                backgroundColor: 'white',
                                flex: 1
                              }}
                              placeholder="Default value"
                            />
                          )}
                          sx={{ flex: 1 }}
                        />
                      ) : (
                        <TextField
                          value={item.defaultValue}
                          onChange={(e) => handleAdditionalFieldChange(index, 'defaultValue', e.target.value)}
                          variant="outlined"
                          sx={{
                            backgroundColor: 'white',
                            flex: 1
                          }}
                          placeholder="Default value"
                        />
                      )}
                      <IconButton color="error" onClick={() => handleRemoveAdditionalField(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  );
                })}
                <Box mt={2}>
                  <Button startIcon={<AddIcon />} onClick={handleAddAdditionalField}>
                    Add Additional Field
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHubspotDialog}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSaveHubspotSettings}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Test HubSpot Integration Dialog */}
      <Dialog open={testHubspotDialogOpen} onClose={handleCloseTestHubspotDialog} fullWidth maxWidth="sm">
        <DialogTitle>Test HubSpot Integration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Insert values for all mapped and additional HubSpot properties and create a test contact in HubSpot.
          </DialogContentText>
          {fields.map((field) => (
            <Box key={field.name.toLowerCase()} display="flex" alignItems="center" mt={2}>
              <Typography style={{ marginRight: '1rem', width: '30%' }}>{field.name}</Typography>
              <TextField
                value={testHubspotLeadDetails[field.name] || ''}
                onChange={(e) => handleTestHubspotFieldChange(field.name, e.target.value)}
                variant="outlined"
                fullWidth
                sx={{
                  backgroundColor: 'white'
                }}
                placeholder={`Value for ${field.name}`}
                disabled={testHubspotLoading}
              />
            </Box>
          ))}
          {/*  {hubspotAdditionalFields.map((item, index) => {
            const prop = getPropertyByName(item.hubspotProperty?.name);
            const hasOptions = propertyHasOptions(prop);
            const propName = item.hubspotProperty?.name || '';
            return (
              <Box key={index} display="flex" alignItems="center" mt={2} fullWidth>
                <Typography style={{ marginRight: '1rem', width: '30%' }}>{item.hubspotProperty?.label}</Typography>
                {hasOptions ? (
                  <Autocomplete
                  fullWidth
                    options={prop.options || []}
                    value={prop.options?.find(o => o.value === testHubspotLeadDetails[propName]) || null}
                    onChange={(e, newVal) => {
                      handleTestHubspotFieldChange(propName, newVal ? newVal.value : '');
                    }}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        sx={{ backgroundColor: 'white' }}
                        placeholder={`Value for ${item.hubspotProperty?.label}`}
                        disabled={testHubspotLoading}
                      />
                    )}
                  />
                ) : (
                  <TextField
                    value={testHubspotLeadDetails[propName] || ''}
                    onChange={(e) => handleTestHubspotFieldChange(propName, e.target.value)}
                    variant="outlined"
                    fullWidth
                    sx={{
                      backgroundColor: 'white'
                    }}
                    placeholder={`Value for ${item.hubspotProperty?.label}`}
                    disabled={testHubspotLoading}
                  />
                )}
              </Box>
            );
          })} */}

          {testHubspotResult && (
            <Box mt={4}>
              <Typography variant="subtitle1">Response:</Typography>
              <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', background: '#f1f1f1', padding: '10px' }}>
                {testHubspotResult}
              </pre>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTestHubspotDialog} disabled={testHubspotLoading}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateContactInHubspot}
            disabled={testHubspotLoading}
          >
            Create Contact in HubSpot
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Leads;
