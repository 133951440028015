import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography } from '@mui/material';
import { AuthContext } from '../components/AuthContext';
import './Login.css';
import { ToastContainer, toast } from 'react-toastify';
import Navigation from '../Navigation'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const navigate = useNavigate();
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      setIsLoggedIn(true);
      navigate('/chatbots');
    }
  }, [navigate, setIsLoggedIn]);

  const handleLogin = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        setError(`HTTP error: ${response.status}`);
        return;
      }

      const result = await response.json();

      if (result.success) {
        if (result.access_token) {
          localStorage.setItem('access_token', result.access_token);
          setIsLoggedIn(true);
          navigate('/chatbots');
        } else {
          setError("Access token not received from the server");
        }
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError(`Error: ${err.message}`);
    }
  };

  const handleForgotPasswordOpen = () => {
    setForgotPasswordOpen(true);
  };

  const handleForgotPasswordClose = () => {
    setForgotPasswordOpen(false);
  };

  return (
    <div className="login-container">
      <Navigation />

      <ToastContainer />
      <h1 className="login-heading">Login</h1>
      <div className="login-form">
        <div className="login-input-group">
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Email"
            variant="outlined"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="login-input-group password-group">
          <TextField
            InputLabelProps={{ shrink: true }}
            type={showPassword ? 'text' : 'password'}
            label="Password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span
            className={`password-toggle ${showPassword ? 'show' : ''}`}
            onClick={() => setShowPassword(!showPassword)}
          >
            <>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </>
          </span>
        </div>
        <Typography
          variant="body2"
          className="forgot-password-link"
          onClick={handleForgotPasswordOpen}
          sx={{
            color: 'primary.main',
            textDecoration: 'underline',
            cursor: 'pointer',
            marginBottom: '10px'
          }}
        >
          Forgot Password?
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
        >
          Login
        </Button>
        <Typography variant="body2" className="forgot-password-link" sx={{ marginTop: '15px' }}>
          Don&apos;t have an account?   <a
            href="/signup?subscription=trial"
            style={{
              color: '#1976d2', // Replace 'primary.main' with the specific colour value
              textDecoration: 'underline',
              cursor: 'pointer',
              marginBottom: '10px'
            }}
          >
            Sign up
          </a>
        </Typography>
      </div>
      {error && (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      )}

      {/* Forgot Password Dialog */}
      <ForgotPasswordDialog
        open={forgotPasswordOpen}
        onClose={handleForgotPasswordClose}
      />
    </div>
  );
};

const ForgotPasswordDialog = ({ open, onClose }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);

  const handleForgotPassword = async () => {
    const sendResetRequest = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/request_reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      onClose();
    };

    toast.promise(
      sendResetRequest(),
      {
        pending: 'Requesting password reset...',
        success: 'Password reset link sent successfully!',
        error: 'Failed to send password reset link'
      },
      {
        position: "top-center"
      }
    ).catch(err => {
      setError(`Error: ${err.message}`);
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth
    >
      <DialogTitle>Forgot Password</DialogTitle>
      <DialogContent>
        <TextField
          label="Enter your email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ marginTop: '10px' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleForgotPassword} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginPage;
