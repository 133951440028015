// Connect.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
    Box,
    Button,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InstagramIcon from '@mui/icons-material/Instagram';
import axios from 'axios';
import loaderGif from '../images/loader.gif'; // Add the path to your loader gif
import './Connect.css';
import { toast } from 'react-toastify';

const Connect = ({ details = [] }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [channels, setChannels] = useState([]);
    const { id } = useParams(); // Extract chatbotId from URL parameters

    useEffect(() => {
        if (Array.isArray(details)) {
            const channels = details
                .filter(detail => detail.type == 'instagram')
                .map(detail => ({
                    chatbot_id: detail.chatbot_id || '',
                    channel_id: detail.channel_id,
                    instagram_account_name: detail.instagram_account_name || '',
                    instagram_status: detail.instagram_status || '',
                    instagram_account_id: detail.instagram_account_id || ''
                }));

            setChannels(channels);
        }
    }, [details]);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get('code');
        if (code) {
            handleInstagramOAuthCode(code);
            // Remove code parameter from URL after handling it
            urlParams.delete('code');
            navigate({ search: urlParams.toString() }, { replace: true });
        }
    }, [location, navigate]);


const handleInstagramOAuthCode = (igCode) => {
    setIsLoading(true);
  
    const token = localStorage.getItem('access_token');
    fetch(`${process.env.REACT_APP_API_URL}/api/updateIGToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ accessToken: igCode, chatbot_id: id }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          const facebookDetailsArray = data.facebook_details;
          const channels = facebookDetailsArray.map(facebookDetails => ({
            chatbot_id: id,
            channel_id: facebookDetails.channel_id,
            instagram_account_name: facebookDetails.instagram_account_name,
            instagram_status: facebookDetails.instagram_status,
            instagram_account_id: facebookDetails.instagram_account_id
          }));
          setChannels(channels);
        } else {
          throw new Error('Failed to update Instagram token');
        }
      })
      .catch(error => {
        console.error(`Error updating Instagram token: ${error.message}`);
        toast.error(`Error updating Instagram token: ${error.message}`, { position: "top-center" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


    const handleDelete = async (channel_id) => {
        const token = localStorage.getItem('access_token');
        const deleteChannel = async () => {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/channels/${channel_id}`, {
                headers: {
                    Authorization: token,
                },
            });

            setChannels(channels.filter(channel => channel.channel_id !== channel_id));
        };

        toast.promise(
            deleteChannel(),
            {
                pending: 'Deleting channel...',
                success: 'Channel deleted successfully!',
                error: {
                    render({ data }) {
                        return data.message || 'Error deleting channel';
                    }
                }
            },
            { position: "top-center" }

        ).catch(error => {
            console.error('Error deleting channel:', error);
        });
    };


    // Ensure you have included this line in your app's entry point (e.g., App.js or index.js) to use ReactToastify

    const handleConnectDisconnect = async (instagram_account_id, action) => {
        const token = localStorage.getItem('access_token');
        const updateChannelStatus = async () => {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/${action}IG`,
                {
                    page_id: instagram_account_id,
                    chatbot_id: id
                },
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );

            // Update the channel status locally
            setChannels(channels.map(channel =>
                channel.instagram_account_id === instagram_account_id
                    ? { ...channel, instagram_status: action === 'connect' ? 'Connected' : 'Disconnected' }
                    : channel
            ));
        };

        toast.promise(
            updateChannelStatus,
            {
                pending: `Processing ${action}...`,
                success: `${action.charAt(0).toUpperCase() + action.slice(1)}ed successfully!`,
                error: `Error ${action}ing source`
            },
            { position: "top-center" }

        ).catch(error => {
            console.error(`Error ${action}ing source:`, error);
        });
    };


    const renderChannels = () => {
        return channels.map((channel, index) => (
            <ListItem key={index} style={{
                display: 'flex', alignItems: 'center', padding: '0'
            }}>
                <Box width={'40%'} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '1rem' }} >
                    {channel.instagram_account_name && (
                        <>
                            <InstagramIcon />
                            <ListItemText primary={`${channel.instagram_account_name} `} sx={{ padding: '0' }} /> {/* - Status: ${channel.facebookStatus}`} /> */}
                        </>
                    )}
                </Box>
                <Box width={'60%'}>
                    {channel.instagram_status === 'Connected' ? (
                        <Button variant="contained" color="warning" onClick={() => handleConnectDisconnect(channel.instagram_account_id, 'disconnect')} style={{ marginLeft: '10px', width: '8rem' }}>Disconnect</Button>
                    ) : (
                        <Button variant="contained" onClick={() => handleConnectDisconnect(channel.instagram_account_id, 'connect')} style={{ marginLeft: '10px', width: '8rem' }}>Connect</Button>
                    )}

                    <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => handleDelete(channel.channel_id)} style={{ marginLeft: '10px' ,  width: '8rem'}}>Delete</Button>
                </Box>
            </ListItem>
        ));
    };

    return (

        <>
            <List sx={{ paddingTop: '0', paddingBottom: '0' }}>
                {renderChannels()}
            </List>
            {channels.length === 0 && (
                <>
                    <Button
                        variant="contained"
                        onClick={() => {
                            window.open(
                                "https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=0&client_id=1717318189025161&redirect_uri=https://app.aissie.com.au/chatbots&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages",
                                "_blank"
                            );
                        }}
                    >
                        Log in to Instagram
                    </Button>
                    {isLoading && (
                        <img src={loaderGif} alt="Loading" className="loading-gif" />
                    )}
                </>
            )}
        </>

    );

};

export default Connect;
