import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

const TestChatbotTab = () => {
  const { id } = useParams();

  useEffect(() => {
    window.embeddedChatbotConfig = {
      chatbotId: id
    };

    // Function to dynamically load the script
    const loadScript = (src, onLoad) => {
      const script = document.createElement('script');
      script.src = src;
      script.defer = true;
      script.onload = onLoad;
      document.body.appendChild(script);
    };

    // Ensure the script runs after the DOM is ready
    const checkAndLoadScript = () => {
      const container = document.getElementById('aissie-chat');
      if (container) {
        // Load the chat widget bundle
        loadScript('/chat-widget-bundle.js', () => {
          console.log("Chat widget bundle loaded");
          if (!container._reactRootContainer) {
            const root = ReactDOM.createRoot(container);
            // Add your React rendering logic here, e.g.,
            // root.render(<ChatComponent />);
            container._reactRootContainer = root;
          } else {
            // Add your React rendering logic here, e.g.,
            // container._reactRootContainer.render(<ChatComponent />);
          }
        });
      } else {
        // Retry after a short delay if the container is not found
        setTimeout(checkAndLoadScript, 1000);
      }
    };

    checkAndLoadScript();
  }, [id]);

  return <div id="aissie-chat" style={{fontFamily:"Poppins"}} />;
};

export default TestChatbotTab;
