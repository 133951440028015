import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

const HubSpot = () => {
    console.log("HubSpot component is rendering...");

    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('access_token');

        const connectHubspot = async () => {
            try {
                await axios.post(`${process.env.REACT_APP_API_URL}/api/hubspot-connect`,
                    { code },
                    {
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                // Once done, you can navigate the user if needed:
                navigate('/chatbots');

            } catch (error) {
                console.error('Error connecting to HubSpot:', error);
            }
        };
        console.log('code',code)
        if (code) {
            connectHubspot();
        }
    }, [code, navigate]);

    return (
        <div className="hubspot">
            <p>Connecting to HubSpot...</p>
        </div>
    );
};

export default HubSpot;
