import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Paper, IconButton
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faComments, faSyncAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';
import '../ChatWidget.css'; // Importing CSS
import ColorPicker from './ColorPicker'; // Import the ColorPicker component
import axios from 'axios';
import { toast } from 'react-toastify';
import RemoveIcon from '@mui/icons-material/Remove';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
const ChatLayoutTab = () => {
  const { id } = useParams();

  const details = localStorage.getItem('aissie-chatbot-details-' + id)
    ? JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id))
    : [];
  const layout = details.style ? details.style : ''
  const [chatbotId, setChatbotId] = useState(details.id);
  const [initialMessages, setInitialMessages] = useState(details.initial_messages);
  const [fallbackMessage, setFallbackMessage] = useState(details.fallback_message);
  const [suggestedMessages, setSuggestedMessages] = useState(details.suggested_messages || []);
  const [messagePlaceholder, setMessagePlaceholder] = useState(layout.messagePlaceholder);
  const [footer, setFooter] = useState(layout.footer);
  const [backgroundColor, setBackgroundColor] = useState(layout.backgroundColor); // Default background color in HEX
  const [botFontColor, setBotFontColor] = useState(layout.botFontColor); // Default bot font color in HEX
  const [userFontColor, setUserFontColor] = useState(layout.userFontColor); // Default user font color in HEX
  const [userBackgroundColor, setUserBackgroundColor] = useState(layout.userBackgroundColor); // Default user background color in HEX
  const [botBackgroundColor, setBotBackgroundColor] = useState(layout.botBackgroundColor); // Default bot background color in HEX  
  const [ThemeBackgroundColor, setThemeBackgroundColor] = useState(layout.ThemeBackgroundColor); // Default theme background color in HEX  
  const [ThemeFontColor, setThemeFontColor] = useState(layout.ThemeFontColor); // Default theme font color in HEX  
  const [displayName, setDisplayName] = useState(layout.displayName);
  const [sendLabel, setSendLabel] = useState(layout.sendLabel);

  const [botProfilePicture, setBotProfilePicture] = useState(
    layout.chatbot_icon ? 'https://aissieragfiles.blob.core.windows.net/chatbot-icons/' + layout.chatbot_icon : null
  );
  const [fileId, setFileId] = useState('');
  const [chatIconID, setChatIconID] = useState(layout.chatbot_icon ? layout.chatbot_icon : null)
  const messagesEndRef = useRef(null);

  // New state variables for saving status
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModified, setIsModified] = useState(false); // New flag for tracking modifications

  // Function to save state to local storage
  const saveStateToLocalStorage = () => {
    console.log('save state function called');

    // Retrieve the existing state from local storage
    const existingState = JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id)) || {};

    // Define the new state
    const newState = {
      id: chatbotId,
      initial_messages: initialMessages,
      fallback_message: fallbackMessage,
      suggested_messages: suggestedMessages,
      style: {
        messagePlaceholder,
        footer,
        backgroundColor,
        botFontColor,
        userFontColor,
        userBackgroundColor,
        botBackgroundColor,
        ThemeBackgroundColor,
        ThemeFontColor,
        displayName,
        botProfilePicture,
        sendLabel,
        chatbot_icon: chatIconID
      }
    };

    // Update only the keys that exist in the new state
    const updatedState = {
      ...existingState,
      ...newState,
      style: {
        ...existingState.style,
        ...newState.style
      }
    };

    // Save the updated state to local storage
    localStorage.setItem('aissie-chatbot-details-' + id, JSON.stringify(updatedState));
  };

  useEffect(() => {
    const localStorageDetails = JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id));

    if ((JSON.stringify(details) !== JSON.stringify(localStorageDetails)) && !isModified) {

      const detailsLayout = details.style ? details.style : ''
      setChatbotId(details.id);
      setInitialMessages(details.initial_messages);
      setFallbackMessage(details.fallback_message);
      setSuggestedMessages(details.suggested_messages);
      setMessagePlaceholder(detailsLayout.messagePlaceholder);
      setFooter(detailsLayout.footer);
      setBackgroundColor(detailsLayout.backgroundColor); // Default background color in HEX
      setBotFontColor(detailsLayout.botFontColor); // Default bot font color in HEX
      setUserFontColor(detailsLayout.userFontColor); // Default user font color in HEX
      setUserBackgroundColor(detailsLayout.userBackgroundColor); // Default user background color in HEX
      setBotBackgroundColor(detailsLayout.botBackgroundColor); // Default bot background color in HEX  
      setThemeBackgroundColor(detailsLayout.ThemeBackgroundColor); // Default theme background color in HEX  
      setThemeFontColor(detailsLayout.ThemeFontColor); // Default theme font color in HEX  
      setDisplayName(detailsLayout.displayName);
      setSendLabel(detailsLayout.sendLabel);
      setBotProfilePicture('https://aissieragfiles.blob.core.windows.net/chatbot-icons/' + detailsLayout.chatbot_icon);
    }
  }, [details]);

  useEffect(() => {
    if (isModified) {
      saveStateToLocalStorage();
    }
  }, [
    isSaving
  ]);

  // Add this useEffect hook to update state when 'id' changes
  useEffect(() => {
    const details = localStorage.getItem('aissie-chatbot-details-' + id)
      ? JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id))
      : {};

    const layout = details.style || {};

    setChatbotId(details.id || null);
    setInitialMessages(details.initial_messages || '');
    setFallbackMessage(details.fallback_message || '');
    setSuggestedMessages(details.suggested_messages || []);
    setMessagePlaceholder(layout.messagePlaceholder || '');
    setFooter(layout.footer || '');
    setBackgroundColor(layout.backgroundColor || '');
    setBotFontColor(layout.botFontColor || '');
    setUserFontColor(layout.userFontColor || '');
    setUserBackgroundColor(layout.userBackgroundColor || '');
    setBotBackgroundColor(layout.botBackgroundColor || '');
    setThemeBackgroundColor(layout.ThemeBackgroundColor || '');
    setThemeFontColor(layout.ThemeFontColor || '');
    setDisplayName(layout.displayName || '');
    setSendLabel(layout.sendLabel || '');
    setBotProfilePicture(
      layout.chatbot_icon
        ? 'https://aissieragfiles.blob.core.windows.net/chatbot-icons/' + layout.chatbot_icon
        : null
    );
    setChatIconID(layout.chatbot_icon || null);
  }, [id]); // Depend on 'id' so this runs when 'id' changes


  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml') && file.size <= 1048576) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('chatbot_id', chatbotId);
      formData.append('type', 'icon');
      const token = localStorage.getItem('access_token');

      const uploadFile = async () => {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token,
          },
        });
        //setBotProfilePicture(response.data.file_url); // Assuming the backend returns the URL of the uploaded file
        setBotProfilePicture('https://aissieragfiles.blob.core.windows.net/chatbot-icons/' + response.data[0].filename); // Assuming the backend returns the URL of the uploaded file
        setChatIconID(response.data[0].filename)
        setFileId(response.data[0].filename); // Store the file_id from the response
      };

      toast.promise(
        uploadFile(),
        {
          pending: 'Uploading file...',
          success: 'File uploaded successfully!',
          error: 'Failed to upload file.'
        },
        {
          position: "top-center"
        }).catch(error => {
          console.error('Error uploading file:', error);
        });
    } else {
      alert('File must be JPG, PNG, or SVG and less than 1MB');
    }
  };

  const handleSaveClick = async () => {
    setIsSaving(true);
    const token = localStorage.getItem('access_token'); // Assuming the token is stored in localStorage
    const layout = {
      messagePlaceholder,
      footer,
      backgroundColor,
      botFontColor,
      userFontColor,
      userBackgroundColor,
      botBackgroundColor,
      ThemeBackgroundColor,
      ThemeFontColor,
      displayName,
      sendLabel,
      chatbot_icon: chatIconID// Include the chatbot_icon attribute with the file_id value
    };

    const saveChatbot = async () => {
      await axios.patch(`${process.env.REACT_APP_API_URL}/api/chatbots/${chatbotId}`, {
        initial_messages: initialMessages,
        fallback_message: fallbackMessage,
        suggested_messages: suggestedMessages,
        style: layout
      }, {
        headers: {
          Authorization: token,
        },
      });
    };

    toast.promise(
      saveChatbot(),
      {
        pending: 'Saving AI assistant...',
        success: 'AI assistant saved successfully!',
        error: 'Failed to save AI assistant.'
      }, {
      position: "top-center"
    }
    ).catch(error => {
      console.error('Error saving AI assistant:', error);
    }).finally(() => {
      setIsSaving(false);
      setIsModified(true); // Reset modified flag after saving
    });
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...suggestedMessages];
    updatedQuestions[index] = value;
    setSuggestedMessages(updatedQuestions);
  };

  const handleAddQuestion = () => {
    setSuggestedMessages([...suggestedMessages, '']);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = suggestedMessages.filter((_, i) => i !== index);
    setSuggestedMessages(updatedQuestions);
  };


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, []);

  const inlineFooter = footer ? ` - ${footer.replace(/<p>/g, '').replace(/<\/p>/g, '')}` : '';

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box display="flex" style={{ maxWidth: "60vw" }} gap={4}>
      <Box flex={1} component="form" onSubmit={(event) => { event.preventDefault(); handleSaveClick(); }} >
        <Typography variant="h4" fontWeight="700" padding="1rem 0">Chat Layout</Typography>
        <Typography fontWeight="400" marginBottom={"1rem"}>You can change the appearance of the AI assistant to match your website's color and theme.</Typography>

        <Paper square={false} elevation={4} style={{ padding: "0.5rem", width: '80%', padding: "1rem 3rem" }}>
          <Typography variant="h6" mt={2}>Display Name</Typography>
          <TextField
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{
              backgroundColor: 'white'
            }}
          />
          <Typography variant="h6">Welcome Message</Typography>
          <TextField
            value={initialMessages}
            onChange={(e) => setInitialMessages(e.target.value)}
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            sx={{
              backgroundColor: 'white'
            }}
          />
          <Typography variant="h6">Fallback Message (when the AI Assistant is unable to answer)</Typography>
          <TextField
            value={fallbackMessage}
            onChange={(e) => setFallbackMessage(e.target.value)}
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            sx={{
              backgroundColor: 'white'
            }}
          />
          <Typography variant="h6" sx={{ marginBottom: '0.1rem' }} mt={2}>
            Suggested Questions
          </Typography>
          <Typography  sx={{ marginBottom: '0.2rem' }} mt={2}>
          You can add suggested questions that allow users to simply click on a question instead of typing it out. This makes it easier for users to quickly select predefined questions, and you can customise these suggestions to suit your needs.          </Typography>
          {suggestedMessages && (
            <>

              {suggestedMessages.map((question, index) => (
                <Box key={index} display="flex" alignItems="center" mb={2}>
                  <TextField
                    required
                    value={question}
                    onChange={(e) => handleQuestionChange(index, e.target.value)}
                    variant="outlined"
                    placeholder="What service do you provide?"
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      marginRight: '8px'
                    }}
                  />
                  <IconButton onClick={() => handleRemoveQuestion(index)} >
                    <RemoveIcon />
                  </IconButton>

                </Box>

              ))}
              <Button variant="outlined" color="primary" onClick={handleAddQuestion}>
                Add suggested questions
              </Button>
            </>
          )}

          <Typography variant="h6" mt={2}>Message Placeholder</Typography>
          <TextField
            value={messagePlaceholder}
            onChange={(e) => setMessagePlaceholder(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{
              backgroundColor: 'white'
            }}
          />
          <Typography variant="h6" mt={2}>Send Button Label</Typography>
          <TextField
            value={sendLabel}
            onChange={(e) => setSendLabel(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{
              backgroundColor: 'white'
            }}
          />
          {/*
          <Typography variant="h6" mt={2}>Footer</Typography>
          <ReactQuill value={footer} onChange={setFooter} />
          <Typography variant="body2" color="textSecondary">You can use this to add a disclaimer or a link to your privacy policy.</Typography> */}
          <Typography variant="h6" mt={2}>Header & Buttons Background Color</Typography>
          <ColorPicker
            defaultColor={ThemeBackgroundColor}
            onChangeComplete={(color) => setThemeBackgroundColor(color.hex)}
          />
          <Typography variant="h6" mt={2}>Header & Buttons Font Color</Typography>
          <ColorPicker
            defaultColor={ThemeFontColor}
            onChangeComplete={(color) => setThemeFontColor(color.hex)}
          />
          <Typography variant="h6" mt={2}>Background Color</Typography>
          <ColorPicker
            defaultColor={backgroundColor}
            onChangeComplete={(color) => setBackgroundColor(color.hex)}
          />
          <Typography variant="h6" mt={2}>Bot Font Color</Typography>
          <ColorPicker
            defaultColor={botFontColor}
            onChangeComplete={(color) => setBotFontColor(color.hex)}
          />
          <Typography variant="h6" mt={2}>User Font Color</Typography>
          <ColorPicker
            defaultColor={userFontColor}
            onChangeComplete={(color) => setUserFontColor(color.hex)}
          />
          <Typography variant="h6" mt={2}>Bot Background Color</Typography>
          <ColorPicker
            defaultColor={botBackgroundColor}
            onChangeComplete={(color) => setBotBackgroundColor(color.hex)}
          />
          <Typography variant="h6" mt={2}>User Background Color</Typography>
          <ColorPicker
            defaultColor={userBackgroundColor}
            onChangeComplete={(color) => setUserBackgroundColor(color.hex)}
          />
          <Typography variant="h6" mt={2}>Bot Profile Picture</Typography>
          <input type="file" accept="image/*" onChange={handleFileChange} />
          {botProfilePicture && <img src={botProfilePicture} alt="Bot" style={{ width: '100px', height: '100px', marginTop: '10px' }} />}
          <Button type="submit" variant="contained" color="primary" disabled={isSaving}>
            {isSaving ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </Paper>
      </Box>
      <Box flex={1} p={2} className="chat-widget-preview-box" >
        <Paper square={false} elevation={4} style={{ padding: "0.5rem", width: '100%', height: "100%" }}>
          <Typography variant="h6">Chat Interface Preview</Typography>
          <div id="chat-widget-preview" style={{ width: '100%', height: '90%', display: 'flex', flexDirection: 'column', fontFamily: 'Poppins, sans-serif' }}>
            <div id="chat-header-preview" style={{ backgroundColor: ThemeBackgroundColor, color: ThemeFontColor, padding: '12px', borderRadius: '15px 15px 0 0', textAlign: 'center', fontWeight: '600' }}>
              <div className="header-content-preview">
                <span className="minimise-text-preview">{displayName}</span>
                <FontAwesomeIcon icon={faMinus} className="minimise-icon-preview" title='Minimise Chat' />
                <FontAwesomeIcon icon={faSyncAlt} className="refresh-icon-preview" title='Refresh Chat' />
              </div>

            </div>
            <div id="chat-body-preview" style={{ flex: 1, overflowY: 'auto', padding: '0.8rem', backgroundColor, overflowX: "hidden" }}>
              <div className="message assistant" style={{ backgroundColor: botBackgroundColor, marginTop: '20px', whiteSpace: 'pre-wrap', color: botFontColor }}>
                {botProfilePicture && (<img src={botProfilePicture} alt="Bot" className="bot-icon" style={{ width: '40px', height: '40px', borderRadius: '50%', verticalAlign: 'middle', marginRight: '10px' }} />)}
                {initialMessages && (<span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(initialMessages.replace(/\n/g, '<br>')) }} />)}
              </div>
              <div className="message user" style={{ backgroundColor: userBackgroundColor, marginTop: '20px', whiteSpace: 'pre-wrap', color: userFontColor }}>
                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize("Hi!") }} />
              </div>
              <div ref={messagesEndRef} />
              {suggestedMessages && suggestedMessages.length > 0 && (
                <div id="suggested-questions-preview">
                  <div className="suggestions-header-preview">
                    <FontAwesomeIcon icon={faTimes} className="close-icon-preview" />
                  </div>
                  <div className="suggestions-container-preview">
                    {suggestedMessages.map((question, index) => (
                      <Button
                        variant='outlined'
                        key={index}
                        sx={{
                          backgroundColor: ThemeBackgroundColor, color: ThemeFontColor, textTransform: 'none',
                          '&:hover': {
                            opacity: 0.8, // Reduce opacity on hover
                            backgroundColor: ThemeBackgroundColor
                          }
                        }}
                        endIcon={<ChevronRightIcon />}
                      >
                        {question}
                      </Button>
                    ))}
                  </div>
                </div>
              )}

            </div>
            <div id="chat-footer-preview" style={{ display: 'flex', padding: '10px', backgroundColor: '#f5f5f5' }}>
              <textarea
                id="chat-input-preview"
                placeholder={messagePlaceholder}
                style={{
                  flex: 1,
                  padding: '10px',
                  marginRight: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '20px',
                  outline: 'none',
                  resize: 'none',
                  overflow: 'hidden',
                  minHeight: '20px',
                  maxHeight: '100px',
                  background: "white"
                }}
                rows={1}
                disabled
              />
              <button
                id="send-button-preview"
                style={{
                  backgroundColor: ThemeBackgroundColor,
                  color: ThemeFontColor,
                  border: 'none',
                  padding: '10px 15px',
                  cursor: 'pointer',
                  borderRadius: '20px',
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: '600'
                }}
                disabled
              >
                {sendLabel}
              </button>
            </div>
            <div id="footer-preview" style={{ textAlign: 'center', padding: '10px', fontSize: '0.875rem', color: '#555', backgroundColor: '#f5f5f5', borderTop: '1px solid #e0e0e0' }}>
              Powered by <a href="https://www.aissie.com.au" target="_blank" rel="noopener noreferrer">AIssie</a>
            </div>
            {botProfilePicture ? (
              <div className="circle-icon" >
                <img src={botProfilePicture} alt="Bot" className="bot-icon" />
                <div className="badge">1</div> {/* Add this badge element */}

              </div>
            ) : (
              <div className="circle-icon" style={{ backgroundColor: ThemeBackgroundColor }} >
                <FontAwesomeIcon icon={faComments} />
                <div className="badge">1</div> {/* Add this badge element */}
              </div>
            )}
          </div>
        </Paper>
      </Box>
    </Box>
  );
};

export default ChatLayoutTab;
