import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Spinner from '../components/Spinner'; // Assuming you have a Spinner component
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
} from '@mui/material';
import Logo from '../images/logolight6.webp'; // Adjust the path as needed
import axios from 'axios';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Joyride from '../components/JoyRide';
import LeadsTab from '../components/LeadsTab.js'
import GeneralTab from '../components/GeneralTab';
import ModelTab from '../components/ModelTab';
import ChatLayoutTab from '../components/ChatLayoutTab';
import KnowledgeBaseTab from '../components/KnowledgeBaseTab';
import Profile from '../components/Profile';
import ChatMessagesTab from '../components/ChatMessagesTab';
import ChannelsTab from '../components/ChannelsTab';
import ChannelsTabBooking from '../components/ChannelsTabBooking.js';
import TestChatbotTab from '../components/TestChatbotTab';
import TestBookingTab from '../components/TestBookingTab.js';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie'; // Import the js-cookie library
import FixedMenu from '../components/FixedMenu'; // Import the new component
import MyTeam from '../components/MyTeam.js';
import PricingPage from '../components/PricingPage.js';
import Support from '../components/Support';
import Scheduler from '../components/Scheduler.js';
import BookingsTab from '../components/BookingTab.js'

const ChatbotDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const [chatbots, setChatbots] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [chatbotName, setChatbotName] = useState('');
  const [selectedChatbot, setSelectedChatbot] = useState(id || '');
  const [activeTab, setActiveTab] = useState('knowledgeBase');
  const [activeSubtab, setActiveSubtab] = useState('upload');
  const [chatbotDetails, setChatbotDetails] = useState([]);
  const [KBDetails, setKBDetails] = useState([]);
  const [channels, setChannels] = useState({});
  const [profile, setProfile] = useState({});
  const [showJoyride, setShowJoyride] = useState(false);
  const [isMobile, setIsMobile] = useState(false); // New state to track mobile access
  const [showMobileDialog, setShowMobileDialog] = useState(false); // State to control the dialog visibility

  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);

  const newUserCookie = Cookies.get('aissie_new_user');
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const fetchChatbots = useCallback(async () => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/chatbots`, {
        headers: {
          Authorization: token,
        },
      });
      setChatbots(response.data.chatbots_list);
    } catch (error) {
      navigate('/login');
      console.error('Error fetching chatbots:', error);
    }
  }, [navigate]);

  useEffect(() => {
    fetchChatbots();
  }, [fetchChatbots]);


  // Detect if the user is on a mobile device
  useEffect(() => {
    const isMobileDevice = /Mobi|Android/i.test(window.navigator.userAgent);
    setIsMobile(isMobileDevice);
    if (isMobileDevice) {
      setShowMobileDialog(true); // Show dialog if on a mobile device
    }
  }, []);

  useEffect(() => {
    // Check if the URL contains the parameter "code" and redirect to the appropriate tab
    const params = new URLSearchParams(location.search);
    if (params.has('code')) {
      setActiveTab('channels');
    }
  }, [location]);

  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/; Secure; SameSite=Strict";
  };

  useEffect(() => {
    const fetchChatbotDetails = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/chatbots/${selectedChatbot}`, {
          headers: {
            Authorization: token,
          },
        });
        setChatbotDetails(response.data);
        if (response.data && id) {
          localStorage.setItem('aissie-chatbot-details-' + id, JSON.stringify(response.data));
        } else {
          localStorage.setItem('aissie-chatbot-details-' + id, JSON.stringify([])); // Set as empty array if response is empty
        }

      } catch (error) {
        console.error('Error fetching AI assistant details:', error);
      }
    };

    const fetchKBDetails = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sources?chatbot=${selectedChatbot}`, {
          headers: {
            Authorization: token,
          },
        });
        setKBDetails(response.data);
        if (response.data && response.data.length > 0 && id) {
          localStorage.setItem('aissie-kb-details-' + id, JSON.stringify(response.data));
        } else {
          localStorage.setItem('aissie-kb-details-' + id, JSON.stringify([])); // Set as empty array if response is empty
        }

      } catch (error) {
        navigate('/login');
        console.error('Error fetching knowledge base details:', error);
      }
    };

    const fetchChannels = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/channels?chatbot=${selectedChatbot}`, {
          headers: {
            Authorization: token,
          },
        });
        setChannels(response.data);
        if (response.data && response.data.length > 0 && id) {
          localStorage.setItem('aissie-channels-' + id, JSON.stringify(response.data));
        } else {
          localStorage.setItem('aissie-channels-' + id, JSON.stringify([])); // Set as empty array if response is empty
        }

      } catch (error) {
        console.error('Error fetching channels details:', error);
      }
    };
    // Fetch user data on page load
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users?profile=true`, {
          headers: {
            Authorization: token,
          },

        });
        setProfile(response.data)

      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    Promise.all([fetchChatbotDetails(), fetchKBDetails(), fetchChannels(), fetchUserData()])
      .then(() => setLoading(false))
      .catch((error) => {
        console.error('Error in fetching data:', error);
        setLoading(false);
      });
  }, [selectedChatbot, navigate]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    handleDrawerToggle();
    setActiveSubtab(newValue === 'knowledgeBase' ? 'upload' : 'general');
  };

  const handleChatbotChange = (event) => {
    setLoading(true)
    setSelectedChatbot(event.target.value);
    setCookie('aissie_chatbot_id', event.target.value, 365 * 10); // Set cookie for 10 years

    navigate(`/chatbots/${event.target.value}`);

    //window.location.reload(); // This will refresh the page
  };

  const handleNewChatbot = async () => {
    try {
      setShowDialog(true); // Display the modal to get the chatbot name
    } catch (error) {
      console.error('Error displaying modal:', error);
    }
  };

  const handleCreateChatbot = async () => {
    setShowDialog(false);
    setLoading(true);

    const token = localStorage.getItem('access_token');

    toast.promise(
      axios.post(
        `${process.env.REACT_APP_API_URL}/api/chatbots`,
        { name: chatbotName }, // Send chatbot name in payload
        {
          headers: {
            Authorization: token,
          },
        }
      ),
      {
        pending: 'Creating AI assistant...',
        success: 'AI assistant created successfully!',
        error: 'Error creating AI assistant',
      },
      { position: 'top-center' }
    ).then(response => {
      const chatbotId = response.data.chatbot_id; // Assuming the response contains the new chatbot's id

      navigate(`/chatbots/${chatbotId}`);
      window.location.reload(); // This will refresh the page
    }).catch(error => {
      console.error('Error creating new AI assistant:', error);
    }).finally(() => {
      setLoading(false);
    });
  };


  const renderTabContent = () => {
    switch (activeTab) {
      case 'general':
        return <GeneralTab details={chatbotDetails} />;
      case 'channels':
        return <ChannelsTab details={channels} />;
      case 'channelsBooking':
        return <ChannelsTabBooking details={chatbotDetails} />;
      case 'chatLayout':
        return <ChatLayoutTab details={chatbotDetails} />;
      case 'leadsTab':
        return <LeadsTab details={chatbotDetails} />;
      case 'bookingsTab':
        return <Scheduler details={chatbotDetails} />;
      case 'quotesTab':
        return <BookingsTab details={chatbotDetails} />;
      case 'profile':
        return <Profile profile={profile} />;
      case 'support':
        return <Support setShowJoyride={setShowJoyride} />;
      case 'myTeam':
        return <MyTeam />;
      case 'model':
        return <ModelTab details={chatbotDetails} />;
      case 'subscription':
        return <PricingPage details={chatbotDetails} profile={profile} />
      case 'logout':
        // Call the logout API endpoint
        const token = localStorage.getItem('access_token');
        fetch(`${process.env.REACT_APP_API_URL}/logout`, {
          method: 'POST', // or 'GET' depending on your API
          credentials: 'include', // include credentials if required (cookies, etc.)
          headers: {
            'Content-Type': 'application/json', // Adjust the content type if necessary
            'Authorization': token
            // Add any additional headers if required
          },
        })
          .then(response => {
            if (response.ok) {
              localStorage.removeItem('access_token');

              // Handle successful logout if necessary
              navigate('/login'); // Redirect to login page
            } else {
              // Handle errors if needed
              console.error('Logout failed');
            }
          })
          .catch(error => {
            console.error('An error occurred:', error);
          });
        break;
      case 'knowledgeBase':
        return (
          <Box>
            <Box mt={2}>
              <KnowledgeBaseTab details={KBDetails} chatBotdetails={chatbotDetails} activeSubtab={activeSubtab} onSubtabChange={setActiveSubtab} />
            </Box>
          </Box>
        );
      case 'messages':
        return (
          <Box>
            <Typography variant="h4" fontWeight="700" padding="1rem 0">Conversations</Typography>

            <Box mt={2}>
              <ChatMessagesTab details={chatbotDetails} />
            </Box>
          </Box>
        );
      case 'testChatbot':
        return <TestChatbotTab />;
      case 'testBooking':
        return <TestBookingTab details={chatbotDetails} />;
      default:
        return null;
    }
  };



  return (
    <div className="chatbot-detail" style={{ display: 'flex' }}>
      {newUserCookie === "true" && !isSmallScreen && (
        <Joyride />)}
      {showJoyride && <Joyride />}
      {loading === true && (<Spinner />)}

      {isSmallScreen && (
        <AppBar sx={{ backgroundColor: '#06525B' }} position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
        <img src={Logo} alt="AI Assistant" className="nav-image" id="nav-image" style={{marginRight: '1rem'}} />
            <Typography variant="h6" noWrap component="div" sx={{marginTop: '15px'}}>
               Admin Portal
            </Typography>
          </Toolbar>
        </AppBar>
      )}

      {/* Drawer for Navigation */}
      <Drawer
        variant={isSmallScreen ? "temporary" : "permanent"}
        open={isSmallScreen ? mobileOpen : true}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: 240,
            overflowX: 'hidden',
            zIndex: 2
          },
        }}
      >

        <FixedMenu
          chatbots={chatbots}
          selectedChatbot={selectedChatbot}
          handleChatbotChange={handleChatbotChange}
          handleNewChatbot={handleNewChatbot}
          handleTabChange={handleTabChange}
          activeTab={activeTab}
          chatbotDetails={chatbotDetails}

        />
      </Drawer>


      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: isSmallScreen ? 0 : '240px',
          marginTop: isSmallScreen ? '64px' : 0,
        }}
      >
        <ToastContainer />

        {renderTabContent()}
      </Box>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>Enter AI assistant Name</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter a name for your new AI assistant.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="AI assistant Name"
            fullWidth
            value={chatbotName}
            onChange={(e) => setChatbotName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleCreateChatbot}
            color="primary"
            variant="contained"
            disabled={!chatbotName || loading}
          >
            Create AI assistant
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showMobileDialog}
        onClose={() => setShowMobileDialog(false)}
      >
        <DialogTitle>Mobile Device Detected</DialogTitle>
        <DialogContent>
          <DialogContentText>
            For a better experience, we recommend using a notebook or desktop to access this page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowMobileDialog(false)} color="primary">
            Continue Anyway
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default ChatbotDetail;