// src/components/ConversationDetail.js
import React from 'react';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import BotImage from '../images/Bot.webp';
import ChatUser from '../images/ChatUser.webp';
import CustomMessage from '../components/CustomMessages';

dayjs.extend(utc);
dayjs.extend(timezone);

const ConversationDetail = ({ messages }) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formattedMessages = messages
    .map((message) => ({
      position: message.role === 'assistant' ? 'right' : 'left',
      date: dayjs.utc(message.created).tz(userTimezone).format('DD MMM YYYY, h:mm A'), // Format date and time
      avatar: message.role === 'assistant' ? BotImage : ChatUser,
      className: message.role === 'assistant' ? 'assistant-message' : 'user-message',
      text: message.content,
    }))

  return (
    <Box p={2} height="100%" overflow="auto">
      {formattedMessages.map((message, index) => (
        <CustomMessage
          key={index}
          position={message.position}
          avatar={message.avatar}
          text={message.text}
          date={message.date}
        />
      ))}
      <style jsx>{`
        .assistant-message .rce-mbox {
          background-color: #092F36;
          color: white;
        }
        .rce-mbox-right-notch {
          fill: #092F36;
        }
        .user-message .rce-mbox {
          background-color: #e5e5e5;
        }
        .rce-mbox-left-notch {
          fill: #e5e5e5;
        }
        .rce-avatar-container .rce-avatar {
          height: 100%;
          width: 100%;
          border-radius: 1rem;
        }
      `}</style>
    </Box>
  );
};

export default ConversationDetail;
