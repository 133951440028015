import React, { useState } from 'react';
import Joyride from 'react-joyride';

const Tour = () => {
    const [steps, setSteps] = useState([
        
        {
            target: '#nav-image', // Targeting an element with the class "my-first-step"
            content: "Welcome to the AIssie AI assistant Admin Portal demonstration! We're excited to help you manage your AI assistant with ease. Simply follow the steps provided, and we'll guide you through the website. Enjoy your journey with us.",
            disableBeacon: true
        },
        {
            target: '#select-chatbot', // Targeting an element with the class "my-first-step"
            content: "Here you can choose your existing AI assistant or create a new one. Each AI assistant can have its own Knowledge Base and be linked to different websites and social media platforms.",
        },
        {
            target: '#knowledge-base-tab', // Targeting an element with the class "my-first-step"
            content: "Here, you can upload content about your business for the AI assistant to use in answering your customers' queries.",
        },
        {
            target: '#file-upload-tab', // Targeting an element with the class "my-first-step"
            content: "Here you can upload files about your business. The AIssie AI assistant will use it to answer your customers' queries.",
        },
        {
            target: '#faq-tab', // Targeting an element with the class "my-first-step"
            content: "Here you can add common question & answers (FAQs). The AI assistant will reply exactly as provided here.",
        },
        {
            target: '#website-tab', // Targeting an element with the class "my-first-step"
            content: "By adding a website, the AI assistant will fetch its content and use it to provide better responses.",
        },
                
        {
            target: '#train-chatbot-button', // Targeting an element with the class "my-first-step"
            content: "Once you have uploaded the required files, click here to update the AI assistant with the latest content.",
        },
        
        {
            target: '#chat-layout-tab', // Targeting an element with the class "my-first-step"
            content: "Here, you can customise the appearance of the AI assistant as it appears on your website. Be sure to align the colours with your website's theme.",
        },
        {
            target: '#leads-tab', // Targeting an element with the class "my-first-step"
            content: "You can customise whether to capture potential customer details and specify the relevant data to be collected.",
        },
        {
            target: '#advanced-settings-tab', // Targeting an element with the class "my-first-step"
            content: "Here, you can adjust advanced settings to customise the AI assistant's behaviour. Just make sure you know what you're doing.",
        },
        {
            target: '#test-chatbot-tab', // Targeting an element with the class "my-first-step"
            content: "Here, you can test the chatbot to see how it will look on your website.",
        },
        {
            target: '#connect-tab', // Targeting an element with the class "my-first-step"
            content: "Here, you can copy the script needed to add the AI assistant to your website. You can also connect your Facebook and Instagram accounts so the AI assistant can respond to your customers through these channels.",
        },
        {
            target: '#dashboard-tab', // Targeting an element with the class "my-first-step"
            content: "Here you can view your subscription usage against your limits and some analytics.",
        },
        {
            target: '#conversations-tab', // Targeting an element with the class "my-first-step"
            content: "Here you view the conversation history between your customers and the AI assistant.",
        },
        {
            target: '#profile', // Targeting an element with the class "my-first-step"
            content: "Adjust your user details here.",
        },
        {
            target: '#myTeam', // Targeting an element with the class "my-first-step"
            content: "Add team members to manage the AI assistants associated with your organisation.",
        },
        {
            target: '#subscription', // Targeting an element with the class "my-first-step"
            content: "You can change, upgrade, or cancel your subscription, and update your payment details, from this section.",
        },



    ]);
    const handleJoyrideCallback = (data) => {
        console.log('data', data);
        const { action, index, type } = data;
        if (action === 'next' || action === "prev") {
            document.getElementById(data.step.target.replace('#', '')).click();
            
        }
        if (action === 'reset' || action === "close") {
            document.cookie = "aissie_new_user=false; path=/";

        }
    }


    return (
        <Joyride
            steps={steps}
            continuous
            disableScrolling
            showSkipButton
            showProgress
            run={true}
            callback={handleJoyrideCallback}

        />
    );
};

export default Tour;