// src/components/CustomMessage.js
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Box, Typography } from '@mui/material';

const CustomMessage = ({ position, avatar, text, date }) => (
  <Box
    display="flex"
    justifyContent={position === 'right' ? 'flex-end' : 'flex-start'}
    alignItems="center"
    mb={1}
  >
    {position === 'left' && (
      <img
        src={avatar}
        alt="avatar"
        style={{ width: 40, height: 40, borderRadius: '50%', marginRight: 10 }}
      />
    )}
    <Box
      p={2}
      bgcolor={position === 'right' ? '#092F36' : '#e5e5e5'}
      color={position === 'right' ? 'white' : 'black'}
      borderRadius={10}
      maxWidth="60%"
      fontSize="0.875rem" // Decrease font size
    >
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{text}</ReactMarkdown>
      <Typography variant="caption" display="block" align={position === 'right' ? 'right' : 'left'}>
        {date}
      </Typography>
    </Box>
    {position === 'right' && (
      <img
        src={avatar}
        alt="avatar"
        style={{ width: 40, height: 40, borderRadius: '50%', marginLeft: 10 }}
      />
    )}
  </Box>
);

export default CustomMessage;
