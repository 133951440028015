import React, { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Typography,
    Button,
    CircularProgress,
    Paper,
    Checkbox,
    FormControlLabel,
    MenuItem,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Select
} from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { TableVirtuoso } from 'react-virtuoso';
import Table from '@mui/material/Table';
//import Lead from '../images/leadForm.webp'
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
        <TableContainer component={Paper} sx={{ elevation: 1, variant: "outlined", square: false, height: '100%' }} {...props} ref={ref} className="table-container" />
    )),
    Table: (props) => (
        <Table {...props} className="table" sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
    TableRow,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};


const ServiceQuote = ({service, onInputUpdate}) => {
    const { id } = useParams();
    console.log('services',service)
    const details = localStorage.getItem('aissie-chatbot-details-' + id)
        ? JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id))
        : [];
    const [chatbotId, setChatbotId] = useState(details.id);
    const [quoteInputs, setQuoteInputs] = useState(service.tools?.properties || {});
    const [serviceId, setServiceId  ] = useState(service.id || null);

    console.log ('quoteINputs', quoteInputs);
    const [requiredInputs, setRequiredInputs] = useState(service.tools?.required || []);
    const [dialogMessage, setDialogMessage] = useState('');
    const [onConfirm, setOnConfirm] = useState(() => () => { });
    const [inputDialogOpen, setInputDialogOpen] = useState(false);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    // State for adding new input
    const [newInput, setNewInput] = useState({
        title: '',
        description: '',
        isMandatory: true,
        enum: [],
        enumText: '',

    });
    const [isSaving, setIsSaving] = useState(false);

    const [isModified, setIsModified] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editInput, setEditInput] = useState({ key: '', title: '', description: '', isMandatory: true, enum:[], enumText: '' });
    const [selectedTemplate, setSelectedTemplate] = useState(''); // New state for template selection

    // Pre-defined templates for auto-filling
    const templates = {
        Removalist: {
            properties: {
                date: {
                    title: 'Date and Time',
                    type: 'string',
                   // examples: ["2024-01-01T12:00:00+08:00"],
                    description: 'The date and time when the service is to be held ',
                },
                full_origin_address: {
                    title: 'Full Origin Address',
                    type: 'string',
                   // examples: ["Innaloo, Scarborough"],
                    description: 'The full origin address of where the service is to be started. Must include house/apartment number, street number, suburb and postcode',
                },
                full_destination_address: {
                    title: 'Full Destiny Address',
                    type: 'string',
                  //  examples: ["Innaloo, Scarborough"],
                    description: 'The full destination address of where the service is to be delivered. Must include house/apartment number, street number, suburb and postcode',
                },
                building_type: {
                    title: 'Building Type',
                    type: 'string',
                    enum: ['House', 'Apartment'],
                    description: 'The type of the building',
                },
                elevator: {
                    title: 'Elevator',
                    type: 'boolean',
                    description: 'If building type is apartment, whether there is an elevator',
                },
                bedrooms: {
                    title: 'Bedrooms',
                    type: 'integer',
                    description: 'Number of bedrooms in the house/apartment',
                },
                packing_required: {
                    title: 'Packing Required',
                    type: 'boolean',
                    description: 'Is packing service required?',
                },
            },
            required: [
                'date',
                'full_origin_address',
                'full_destination_address',
                'building_type',
                'elevator',
                'bedrooms',
                'packing_required',
            ],
        },
        Cleaning: {
            properties: {
                date: {
                    title: 'Date and Time',
                    type: 'string',
                   // examples: ["2024-01-01T12:00:00+08:00"],
                    description: 'The date and time when the service is to be held ',
                },
                full_origin_address: {
                    title: 'Full Origin Address',
                    type: 'string',
                   // examples: ["Innaloo, Scarborough"],
                    description: 'The full origin address of where the service is to be started. Must include house/apartment number, street number, suburb and postcode',
                },

                bedrooms: {
                    title: 'Bedrooms',
                    type: 'integer',
                    description: 'Number of bedrooms in the house/apartment',
                },

            },
            required: [
                'date',
                'full_origin_address',
                'bedrooms',
            ],
        },
    };
    // Convert input name to a JSON-acceptable key

    const openConfirmDialog = (message, onConfirmAction) => {
        setDialogMessage(message);
        setOnConfirm(() => onConfirmAction);
        setConfirmDialogOpen(true);
    };
    const handleOpenEditDialog = (key, title, description, isMandatory, enumerator, enumText) => {
        const enumString = enumerator?.join('\n') || '';  // Join enum values into a string

        setEditInput({ key, title, description, isMandatory, enum: enumerator, enumText: enumString  });
        setEditDialogOpen(true);
    };


    // Close the edit dialog
    const handleCloseEditDialog = () => {
        setEditDialogOpen(false);
        setEditInput({ key: '', title: '', description: '', isMandatory: true,  enum: [], enumText: '', });
    };

    const saveStateToLocalStorage = () => {
        const existingState = JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id)) || {};

        // New format for the state
        const newState = {
            id: chatbotId,
            tools: {
                properties: quoteInputs,
                required: requiredInputs,
                strict: true,
            },
        };

        const updatedState = {
            ...existingState,
            ...newState,
        };

        localStorage.setItem('aissie-chatbot-details-' + id, JSON.stringify(updatedState));
    };
    useEffect(() => {
        if (selectedTemplate === 'Removalist') {
            const template = templates.Removalist;
            setQuoteInputs(template.properties);
            setRequiredInputs(template.required);
            setIsModified(true);
            const newTools = {
                properties: template.properties,
                required: template.required,
                strict: true,
            }
          
            onInputUpdate(newTools);
        } else if (selectedTemplate === 'Cleaning') {
            const template = templates.Cleaning;
            setQuoteInputs(template.properties);
            setRequiredInputs(template.required);
            setIsModified(true);
            const newTools = {
                properties: template.properties,
                required: template.required,
                strict: true,
            }
          
            onInputUpdate(newTools);
            // Handle Cleaning template auto-fill here if needed
        }
    }, [selectedTemplate]);


   /* useEffect(() => {
        if (isModified) {
            saveStateToLocalStorage();
        }
    }, [isSaving]);*/

    const handleSaveClick = async () => {
        setIsSaving(true);
        const token = localStorage.getItem('access_token');

        const saveChatbot = async () => {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/services/${serviceId}`, {
                tools: {
                    properties: quoteInputs,
                    required: requiredInputs,
                    strict: true,
                },
            }, {
                headers: {
                    Authorization: token,
                },
            });
        };

        toast.promise(
            saveChatbot(),
            {
                pending: 'Saving AI assistant...',
                success: 'AI assistant saved successfully!',
                error: 'Failed to save AI assistant.'
            }, {
            position: "top-center"
        }
        ).catch(error => {
            console.error('Error saving AI assistant:', error);
        }).finally(() => {
            setIsSaving(false);
            setIsModified(false);
        });
    };

    const handleQuestionChange = (key, field, value) => {
        const updatedInputs = { ...quoteInputs };
        updatedInputs[key] = {
            ...updatedInputs[key],
            [field]: value,
        };
        setQuoteInputs(updatedInputs);
        setIsModified(true);
    };

    const openInputDialog = () => {

        setInputDialogOpen(true);
    };

    const closeInputDialog = () => {
        setInputDialogOpen(false);
        setNewInput({
            title: '', description: '', isMandatory: true, enum: [],
            enumText: '',
        });

    };

    const handleAddNewInput = () => {
        const key = newInput.title.toLowerCase().replace(/\s+/g, '_');
        // Process the enumText to create the enum array
        
        const enumValues = (newInput.enumText ?? '')
        .split('\n')
        .map((s) => s.trim())
        .filter((s) => s.length > 0);
      

        const newInputObject = {
            type: 'string',
            description: newInput.description,
            title: newInput.title,
        };

        if (enumValues.length > 0) {
            newInputObject.enum = enumValues;
        }

        // Update the inputs
        const updatedInputs = {
            ...quoteInputs,
            [key]: newInputObject,
        };
        setQuoteInputs(updatedInputs);

        if (newInput.isMandatory) {
            setRequiredInputs([...requiredInputs, key]);
        }
        const newTools = {
            properties: updatedInputs,
            required: [...requiredInputs, key],
            strict: true,
        }
      
        onInputUpdate(newTools);
        setIsModified(true);
        closeInputDialog();

    };

    const handleRemoveQuestion = (key) => {
        const updatedInputs = { ...quoteInputs };
        delete updatedInputs[key];
        setQuoteInputs(updatedInputs);

        const updatedRequired = requiredInputs.filter((item) => item !== key);
        setRequiredInputs(updatedRequired);


        const newTools = {
            properties: updatedInputs,
            required: updatedRequired,
            strict: true,
        }
      
        onInputUpdate(newTools);



        setIsModified(true);
    };
    const handleSaveEdit = () => {
        const updatedInputs = { ...quoteInputs };
        const key = editInput.title.toLowerCase().replace(/\s+/g, '_');

        // Check if the key has changed
        if (key !== editInput.key) {
            // Assign the existing data to the new key
            updatedInputs[key] = { ...updatedInputs[editInput.key] };
            // Delete the old key
            delete updatedInputs[editInput.key];
        } else {
            // If the key hasn't changed, ensure the key exists
            if (!updatedInputs[key]) {
                updatedInputs[key] = {};
            }
        }
        
        const enumValues = (editInput.enumText ?? '')
        .split('\n')
        .map((s) => s.trim())
        .filter((s) => s.length > 0);
      

        // Update the properties of the new or existing key
        updatedInputs[key].title = editInput.title;
        updatedInputs[key].description = editInput.description;

        // Conditionally update the 'enum' property
        if (enumValues.length > 0) {
            updatedInputs[key].enum = enumValues;
        } else {
            // Remove 'enum' property if enumValues is empty
            delete updatedInputs[key].enum;
        }

        let updatedRequired = [...requiredInputs];
        if (key !== editInput.key) {
            // Remove the old key from requiredInputs if it exists
            updatedRequired = updatedRequired.filter((item) => item !== editInput.key);
        }
        if (editInput.isMandatory) {
            // Add the new key if it's not already in requiredInputs
            if (!updatedRequired.includes(key)) {
                updatedRequired.push(key);
            }
        } else {
            // Remove the new key if it exists in requiredInputs
            updatedRequired = updatedRequired.filter((item) => item !== key);
        }

        // Update the state with the modified requiredInputs array
        setRequiredInputs(updatedRequired);


        



        setQuoteInputs(updatedInputs);
        setIsModified(true);
        handleCloseEditDialog();
        const newTools = {
            properties: updatedInputs,
            required: updatedRequired,
            strict: true,
        }
      
        onInputUpdate(newTools);
    };
    return (
        <Box display="flex" style={{ maxWidth: "100%" }} gap={4}>
            <Box flex={1} >
 

                    <Typography variant="h6" sx={{ marginBottom: '0.5rem' }} mt={2}>
                        Quote Inputs - What information do you need from the customer to calculate a quote?
                    </Typography>
                    <Typography  sx={{ marginBottom: '0.5rem' }} mt={2}>
                        Template
                    </Typography>
                    <Select
                        fullWidth
                        value={selectedTemplate}
                        onChange={(e) => setSelectedTemplate(e.target.value)}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>Select Template</MenuItem>
                        <MenuItem value="Removalist">Removalist</MenuItem>
                        <MenuItem value="Cleaning">Cleaning</MenuItem>
                    </Select>
                    <Button variant="contained" onClick={openInputDialog} sx={{ marginTop: '1rem' }}>Add New Input</Button>

                    <TableVirtuoso
                        data={Object.entries(quoteInputs)}
                        allowFullScreen
                       // useWindowScroll
                        components={VirtuosoTableComponents}
                        fixedHeaderContent={() => (
                            <TableRow>
                                <TableCell style={{ width: '20%' }}>Name</TableCell>
                                <TableCell style={{ width: '50%' }}>Description</TableCell>
                                <TableCell style={{ width: '20%' }}>Action</TableCell>
                            </TableRow>
                        )}
                        itemContent={(index, [key, input]) => (
                            <>
                                <TableCell style={{ width: '20%' }}>{input.title}</TableCell>
                                <TableCell style={{ width: '50%' }}>{input.description}</TableCell>

                                <TableCell style={{ width: '20%' }}>
                                    <Button
                                        variant="outlined"
                                        startIcon={<EditIcon />}
                                        onClick={() => handleOpenEditDialog(key, input.title, input.description, requiredInputs.includes(key), input.enum, input.enumText)}
                                    >
                                        Edit
                                    </Button>
                                    <Button variant="outlined" color="error"
                                        onClick={() => openConfirmDialog('Are you sure you want to delete this item? This action cannot be undone.', () => handleRemoveQuestion(key))}
                                        startIcon={<DeleteIcon />}>
                                        Delete
                                    </Button>
                                </TableCell>
                            </>
                        )}
                    />
             
                
            </Box>
            <Dialog open={editDialogOpen} onClose={handleCloseEditDialog}>
                <DialogTitle>Edit Input</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Input Name"
                        value={editInput.title}
                        onChange={(e) => setEditInput({ ...editInput, title: e.target.value })}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Description"
                        fullWidth
                        value={editInput.description}
                        onChange={(e) => setEditInput({ ...editInput, description: e.target.value })}
                        margin="dense"
                        multiline
                    />

                    <TextField
                        label="Possible Values (one per line)"
                        value={editInput.enumText}
                        onChange={(e) =>
                            setEditInput({
                                ...editInput,
                                enumText: e.target.value,
                            })
                        }
                        fullWidth
                        margin="dense"
                        multiline
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog}>Cancel</Button>
                    <Button onClick={handleSaveEdit} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={inputDialogOpen} onClose={closeInputDialog}>
                <DialogTitle>Add New Input</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Input Name"
                        value={newInput.title}
                        onChange={(e) => setNewInput({ ...newInput, title: e.target.value })}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Description"
                        value={newInput.description}
                        onChange={(e) => setNewInput({ ...newInput, description: e.target.value })}
                        fullWidth
                        margin="dense"
                        multiline
                    />

                    <TextField
                        label="Possible Values (one per line)"
                        value={newInput.enumText}
                        onChange={(e) =>
                            setNewInput({
                                ...newInput,
                                enumText: e.target.value,
                            })
                        }
                        fullWidth
                        margin="dense"
                        multiline
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeInputDialog}>Cancel</Button>
                    <Button onClick={handleAddNewInput} variant="contained" color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
            >
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
                    <Button
                        onClick={() => {
                            onConfirm();
                            setConfirmDialogOpen(false);
                        }}
                        color="error"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
};

export default ServiceQuote;
