import React, { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Typography,
    Button,
    Tab,
    Tabs,
    Paper,
    Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, IconButton,
    Select, FormControl, InputLabel, MenuItem, CircularProgress,
    Chip,
    DialogContentText,
    Unstable_Grid2 as Grid,
    Stack,
    FormHelperText,
    useMediaQuery
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { TableVirtuoso } from 'react-virtuoso';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Spinner from './Spinner';
import TimezoneSelect from 'react-timezone-select';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import CustomSelect from 'react-select';
import QuoteTab from './QuoteTab';
import FileUpload from './FileUpload';
import { useParams } from 'react-router-dom';
import ServiceQuote from './ServiceQuote';

const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
        <TableContainer component={Paper} sx={{ elevation: 1, variant: "outlined", square: false, height: '100%', overflowX: 'auto' }} {...props} ref={ref} />
    )),
    Table: (props) => (
        <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'auto', minWidth: '600px' }} />
    ),
    TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
    TableRow,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

const BookingTab = () => {
    const { id } = useParams();
    const [isSaving, setIsSaving] = useState(false);

    const [activeSubtab, setActiveSubtab] = useState('services');
    const [servicesError, setServicesError] = useState(false);
    const [timeSlotErrors, setTimeSlotErrors] = useState({});
    const [dialogMessage, setDialogMessage] = useState('');
    const [onConfirm, setOnConfirm] = useState(() => () => { });
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const details = localStorage.getItem('aissie-chatbot-details-' + id)
        ? JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id))
        : [];
    const [chatbotId, setChatbotId] = useState(details.id);
    const [durationError, setDurationError] = useState(false);
    const [minDurationError, setMinDurationError] = useState(false);
    const [minCancellationError, setMinCancellationError] = useState(false);
    const [serviceNameError, setServiceNameError] = useState(false);
    const [serviceTypeError, setServiceTypeError] = useState(false);
    const [services, setServices] = useState([]);
    const [resources, setResources] = useState([]);
    const [locations, setLocations] = useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [quoteInstructions, setQuoteInstructions] = useState(details.quote_instructions || '');
    const [isModified, setIsModified] = useState(false);
    const [hasFlexibleService, setHasFlexibleService] = useState(false); // New state for flexible services

    const [isFetching, setIsFetching] = useState(false);
    const [serviceDialogOpen, setServiceDialogOpen] = useState(false);
    const [locationDialogOpen, setLocationDialogOpen] = useState(false);
    const [resourceDialogOpen, setResourceDialogOpen] = useState(false);
    const [newService, setNewService] = useState({ name: '', price: '', type: '', tools: [] });
    const [newLocation, setNewLocation] = useState({ name: '', timezone: { value: 'Australia/Perth', label: 'Australia/Perth' }, });

    const [chatbotTools, setChatbotTools] = useState(() => {
        const flexibleServices = services?.filter(service => service.type === 'flexible');
        if (flexibleServices.length > 0) {
            const serviceNames = flexibleServices?.map(service => service.name);
            const itemAnyOfArray = flexibleServices.map(service => {
                const originalProperties = JSON.parse(JSON.stringify(service.tools.properties || {}));
                const properties = {
                    service_type: {
                        type: 'string',
                        const: service.name,
                        description: 'Discriminator to distinguish between services',
                    },
                    ...originalProperties,
                };
                const requiredFields = Object.keys(properties);
                for (const key in properties) {
                    if (properties[key].enum && !Array.isArray(properties[key].enum)) {
                        properties[key].enum = [properties[key].enum];
                    }
                }
                return {
                    type: 'object',
                    description: `The ${service.name} service_name mandatory parameters. Ensure these parameters are obtained based on the service.`,
                    properties: properties,
                    required: requiredFields,
                    additionalProperties: false
                };
            });
            const jsonSchema = {
                type: 'function',
                function: {
                    name: 'get_price_and_duration',
                    strict: true,
                    description: 'Create a quote when required by customer how much the service costs. Always call this function whenever new quotes or updates are required/provided, but only for services which type equals "flexible"',
                    parameters: {
                        type: 'object',
                        properties: {
                            service_name: {
                                type: 'string',
                                enum: serviceNames,
                                description: 'The name of the service'
                            },
                            item: {
                                anyOf: itemAnyOfArray
                            }
                        },
                        required: ['item', 'service_name'],
                        additionalProperties: false
                    }
                }
            };
            console.log(JSON.stringify(jsonSchema, null, 2));
            return jsonSchema;
        }
        else
            return null
    });

    const saveStateToLocalStorage = () => {
        const existingState = JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id)) || {};
        const newState = {
            id: chatbotId,
            quote_instructions: quoteInstructions,
            tools: chatbotTools
        };
        const updatedState = {
            ...existingState,
            ...newState,
        };
        localStorage.setItem('aissie-chatbot-details-' + id, JSON.stringify(updatedState));
    };

    useEffect(() => {
        if (isModified) {
            saveStateToLocalStorage();
        }
    }, [isSaving]);

    useEffect(() => {
        const hasFlexible = services.some(service => service.type === 'flexible');
        setHasFlexibleService(hasFlexible);
    }, [services]);

    useEffect(() => {
        setChatbotTools(() => {
            const flexibleServices = services?.filter(service => service.type === 'flexible');
            if (flexibleServices.length > 0) {
                const serviceNames = flexibleServices?.map(service => service.name);
                const itemAnyOfArray = flexibleServices.map(service => {
                    const originalProperties = JSON.parse(JSON.stringify(service.tools.properties || {}));
                    const properties = {
                        service_type: {
                            type: 'string',
                            const: service.name,
                            description: 'Discriminator to distinguish between services',
                        },
                        ...originalProperties,
                    };
                    const requiredFields = Object.keys(properties);
                    for (const key in properties) {
                        if (properties[key].enum && !Array.isArray(properties[key].enum)) {
                            properties[key].enum = [properties[key].enum];
                        }
                    }
                    return {
                        type: 'object',
                        description: `The ${service.name} service_name mandatory parameters. Ensure these parameters are obtained based on the service.`,
                        properties: properties,
                        required: requiredFields,
                        additionalProperties: false
                    };
                });
                const jsonSchema = {
                    type: 'function',
                    function: {
                        name: 'get_price_and_duration',
                        strict: true,
                        description: 'Retrieves the price and duration for a flexible service. This function should be invoked whenever the customer requests information about the cost or duration of a flexible service. Additionally, always call this function when new quotes are generated or updates are provided. Ensure that the input parameters match the specific details provided by the customer. Avoid making any assumptions about input values—ensure they align with the exact requirements of each parameter.',
                        parameters: {
                            type: 'object',
                            properties: {
                                service_name: {
                                    type: 'string',
                                    enum: serviceNames,
                                    description: 'The name of the service'
                                },
                                item: {
                                    anyOf: itemAnyOfArray
                                }
                            },
                            required: ['item', 'service_name'],
                            additionalProperties: false
                        }
                    }
                };
                console.log(JSON.stringify(jsonSchema, null, 2));
                return jsonSchema;
            }
            else
                return null;
        });
    }, [services]);

    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const initializeWorkingHours = () => {
        const workingHours = {
            recurring_schedules: [{
                recurring_schedule_id: null,
                schedule_blocks: []
            }]
        };
        daysOfWeek.forEach(day => {
            if (['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(day)) {
                workingHours.recurring_schedules[0].schedule_blocks.push({
                    schedule_block: {
                        weekday: day,
                        start_time: '08:00',
                        end_time: '17:00'
                    },
                    schedule_block_id: null
                });
            }
        });
        return workingHours;
    };

    const isOverlappingOrInclusive = (day, newStart, newEnd, excludeIndex = null) => {
        const resource = editResource ? editResource : newResource;
        const recurringSchedule = resource?.working_hours?.recurring_schedules?.[0];
        if (!recurringSchedule) {
            console.error("No recurring schedule found");
            return false;
        }
        const existingSlots = recurringSchedule.schedule_blocks
            .map((slot, index) => ({ slot, index }))
            .filter(
                ({ slot, index }) => slot.schedule_block.weekday === day && index !== excludeIndex
            );
        const newStartTime = parseTime(newStart);
        const newEndTime = parseTime(newEnd);

        if (newStartTime === null || newEndTime === null) {
            console.error("Invalid new start or end time");
            return false;
        }

        return existingSlots.some(({ slot }) => {
            const existingStartTime = parseTime(slot.schedule_block.start_time);
            const existingEndTime = parseTime(slot.schedule_block.end_time);

            if (existingStartTime === null || existingEndTime === null) {
                return false;
            }

            // Check for overlap
            return newStartTime < existingEndTime && newEndTime > existingStartTime;
        });
    };


    const parseTime = (timeStr) => {
        if (!timeStr) return null;
        const [hoursStr, minutesStr] = timeStr.split(':');
        const hours = parseInt(hoursStr, 10);
        const minutes = parseInt(minutesStr, 10);
        if (isNaN(hours) || isNaN(minutes)) {
            return null;
        }
        return hours * 60 + minutes;
    };


    const [newResource, setNewResource] = useState({
        name: '',
        working_hours: initializeWorkingHours(),
        services: []
    });
    const [editResource, setEditResource] = useState(null);
    const [editService, setEditService] = useState(null);
    const [editLocation, setEditLocation] = useState({ name: '', timezone: { value: 'Australia/Perth', label: 'Australia/Perth' }, });

    const [files, setFiles] = useState([]);

    const [selectedTab, setSelectedTab] = useState('services');

    useEffect(() => {
        fetchFiles();
    }, []);

    const handleDeleteFile = async (fileId) => {
        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                axios.delete(`${process.env.REACT_APP_API_URL}/api/sources/${fileId}`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                }).then(response => {
                    fetchFiles();
                }),
                {
                    pending: 'Deleting quote file...',
                    success: 'Quote file deleted successfully!',
                    error: 'Error deleting quote file',
                },
                {
                    position: "top-center"
                }
            );
        } catch (error) {
            console.error('Error deleting quote file:', error);
        }
    };

    useEffect(() => {
        if (editResource) {
            const processWorkingHours = (workingHours) => {
                const formattedWorkingHours = {
                    recurring_schedules: workingHours.recurring_schedules.map(schedule => ({
                        recurring_schedule_id: schedule.recurring_schedule_id,
                        schedule_blocks: schedule.schedule_blocks.map((block) => ({
                            schedule_block: {
                                weekday: block.schedule_block.weekday,
                                start_time: block.schedule_block.start_time,
                                end_time: block.schedule_block.end_time
                            },
                            schedule_block_id: block.schedule_block_id
                        }))
                    }))
                };
                return formattedWorkingHours;
            };
            setNewResource({
                name: editResource.name || '',
                working_hours: processWorkingHours(editResource.working_hours),
                services: editResource.services || [],
                id: editResource.id || null
            });
        } else {
            setNewResource({
                name: '',
                working_hours: initializeWorkingHours(),
                services: []
            });
        }
    }, [editResource]);

    const handleWorkingHoursChange = (day, dayIndex, field, value) => {
        const resource = editResource ? editResource : newResource;
        const updatedHours = { ...resource.working_hours };
        const recurringSchedule = updatedHours.recurring_schedules[0];
        if (!recurringSchedule) {
            console.error(`No recurring schedule found at index 0`);
            return;
        }

        const scheduleBlocksForDayWithIndex = recurringSchedule.schedule_blocks.reduce((acc, block, index) => {
            if (block.schedule_block.weekday === day) {
                acc.push({ block, index });
            }
            return acc;
        }, []);

        const scheduleBlockWithIndex = scheduleBlocksForDayWithIndex[dayIndex];
        if (!scheduleBlockWithIndex) {
            console.error(`No schedule block found for day ${day} at index ${dayIndex}`);
            return;
        }

        const { block: scheduleBlock, index: blockIndex } = scheduleBlockWithIndex;
        const newBlock = {
            ...scheduleBlock,
            schedule_block: {
                ...scheduleBlock.schedule_block,
                [field]: value
            }
        };

        let errors = { ...timeSlotErrors };

        // Extract start and end times
        const startTime = newBlock.schedule_block.start_time;
        const endTime = newBlock.schedule_block.end_time;

        // Perform validation only if both times are provided
        if (startTime && endTime) {
            const startMinutes = parseTime(startTime);
            const endMinutes = parseTime(endTime);

            if (startMinutes === null || endMinutes === null) {
                // Invalid time format
                if (!errors[day]) errors[day] = {};
                errors[day][dayIndex] = 'Invalid time format';
            } else if (startMinutes >= endMinutes) {
                // Start time is not before end time
                if (!errors[day]) errors[day] = {};
                errors[day][dayIndex] = 'Start time must be before end time';
            } else if (isOverlappingOrInclusive(day, startTime, endTime, blockIndex)) {
                // Time slot overlaps with existing slot
                if (!errors[day]) errors[day] = {};
                errors[day][dayIndex] = 'Time slot overlaps with an existing slot';
            } else {
                // No errors
                if (errors[day]) delete errors[day][dayIndex];
            }
        } else {
            // If either time is missing, clear any existing errors for this slot
            if (errors[day]) delete errors[day][dayIndex];
        }

        // Update the schedule block
        if (blockIndex !== undefined) {
            updatedHours.recurring_schedules[0].schedule_blocks[blockIndex] = newBlock;
        }

        // Update the appropriate resource state
        if (editResource) {
            setEditResource({ ...editResource, working_hours: updatedHours });
        } else {
            setNewResource({ ...newResource, working_hours: updatedHours });
        }

        // Update the error state
        setTimeSlotErrors(errors);
    };




    const handleSaveChatbot = () => {
        setIsSaving(true);
        const token = localStorage.getItem('access_token');

        const saveQuoteInstructions = async () => {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/chatbots/${chatbotId}`, {
                quote_instructions: quoteInstructions,
                tools: chatbotTools
            }, {
                headers: {
                    Authorization: token,
                },
            });
        };

        toast.promise(
            saveQuoteInstructions(),
            {
                pending: 'Saving AI assistant...',
                success: 'AI assistant saved successfully!',
                error: 'Failed to save AI assistant.'
            }, {
            position: "top-center"
        }
        ).catch(error => {
            console.error('Error saving AI assistant:', error);
        }).finally(() => {
            setIsSaving(false);
            setIsModified(true);
        });
    };

    const handleAddTimeSlot = (day) => {
        const resource = editResource ? editResource : newResource;
        const updatedSchedules = [...(resource.working_hours?.recurring_schedules || [])];

        if (updatedSchedules.length === 0) {
            updatedSchedules.push({ schedule_blocks: [] });
        }

        const newBlock = {
            schedule_block: {
                weekday: day,
                start_time: '',
                end_time: '',
            },
            schedule_block_id: null,
        };

        updatedSchedules[0].schedule_blocks.push(newBlock);

        const updatedWorkingHours = {
            ...resource.working_hours,
            recurring_schedules: updatedSchedules,
        };

        if (editResource) {
            setEditResource({
                ...editResource,
                working_hours: updatedWorkingHours,
            });
        } else {
            setNewResource({
                ...newResource,
                working_hours: updatedWorkingHours,
            });
        }
    };

    const convertWorkingHoursToScheduleBlocks = (workingHours) => {
        if (!workingHours.recurring_schedules || !workingHours.recurring_schedules.length) {
            return { recurring_schedules: [] };
        }
        const recurringSchedules = workingHours.recurring_schedules.map(schedule => ({
            recurring_schedule_id: schedule.recurring_schedule_id,
            schedule_blocks: schedule.schedule_blocks.map((slot) => ({
                schedule_block: {
                    weekday: slot.schedule_block.weekday,
                    start_time: slot.schedule_block.start_time,
                    end_time: slot.schedule_block.end_time
                },
                schedule_block_id: slot.schedule_block_id || null
            }))
        }));
        return { recurring_schedules: recurringSchedules };
    };

    const handleRemoveTimeSlot = (day, index) => {
        // Determine the current resource
        const resource = editResource ? editResource : newResource;

        // Copy existing recurring schedules or initialize an empty array
        const updatedSchedules = [...(resource.working_hours?.recurring_schedules || [])];

        if (updatedSchedules.length > 0) {
            // Copy schedule blocks to avoid mutating state directly
            const scheduleBlocks = [...updatedSchedules[0].schedule_blocks];

            // Filter blocks for the specified day
            const dayBlocks = scheduleBlocks.filter(block => block.schedule_block.weekday === day);

            if (dayBlocks.length > 0) {
                // Find the block to remove using the provided index
                const blockToRemove = dayBlocks[index];
                const blockIndex = scheduleBlocks.indexOf(blockToRemove);

                // Remove the block if it exists
                if (blockIndex > -1) {
                    scheduleBlocks.splice(blockIndex, 1);
                }
            }

            // Update the schedule blocks in the first recurring schedule
            updatedSchedules[0].schedule_blocks = scheduleBlocks;
        }

        // Prepare the updated working hours
        const updatedWorkingHours = {
            ...resource.working_hours,
            recurring_schedules: updatedSchedules,
        };

        // Update the appropriate resource state
        if (editResource) {
            setEditResource({
                ...editResource,
                working_hours: updatedWorkingHours,
            });
        } else {
            setNewResource({
                ...newResource,
                working_hours: updatedWorkingHours,
            });
        }
    };


    // Fetch Services Data
    const fetchServices = async () => {
        setIsFetching(true);
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/services`, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            const fetchedServices = Array.isArray(response.data.message) ? response.data.message : [];
            const servicesWithConvertedDuration = fetchedServices.map(service => ({
                ...service,
                duration_hours: convertFromIsoDuration(service.duration).hours,
                duration_minutes: convertFromIsoDuration(service.duration).minutes,
                min_duration_hours: convertFromIsoDuration(service.min_duration).hours,
                min_duration_minutes: convertFromIsoDuration(service.min_duration).minutes,
                max_duration_hours: convertFromIsoDuration(service.max_duration).hours,
                max_duration_minutes: convertFromIsoDuration(service.max_duration).minutes,
                cancellation_threshold_hours: convertFromIsoDuration(service.cancellation_threshold).hours,
            }));
            setServices(servicesWithConvertedDuration);
        } catch (error) {
            console.error('Error fetching services:', error);
        }
        setIsFetching(false);
    };

    const convertToIsoDuration = (hours, minutes) => {
        const h = parseInt(hours, 10) || 0;
        const m = parseInt(minutes, 10) || 0;
        if (h === 0 && m === 0) {
            return null;
        }
        return `PT${h > 0 ? `${h}H` : ''}${m > 0 ? `${m}M` : ''}`;
    };

    const convertFromIsoDuration = (isoDuration) => {
        if (!isoDuration) {
            return {
                hours: '',
                minutes: ''
            };
        }
        const regex = /PT(?:(\d+)H)?(?:(\d+)M)?/;
        const matches = isoDuration.match(regex);
        return {
            hours: matches && matches[1] ? matches[1] : '00',
            minutes: matches && matches[2] ? matches[2] : '00'
        };
    };

    const handleSuccessfulUpload = (uploadedFilesData) => {
        console.log('Uploaded Files Data:', uploadedFilesData);
        fetchFiles();
    };

    const fetchFiles = async () => {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sources?chatbot=${chatbotId}&type='quote`, {
                headers: {
                    Authorization: token,
                },
            });
            setFiles(response.data || []);
        } catch (error) {
            console.error('Error fetching quote files:', error);
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#fff',
            borderColor: state.isFocused ? '#092F36' : '#ccc',
            '&:hover': {
                borderColor: '#092F36',
            },
            boxShadow: state.isFocused ? '0 0 0 1px #092F36' : 'none',
            height: '56px',
            minHeight: '56px',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#f5f5f5',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#06525B',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: '#06525B',
            '&:hover': {
                backgroundColor: '#e5e5e5',
                color: '#092F36',
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#9e9e9e',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#fff',
            zIndex: 9999,
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#e5e5e5' : '#fff',
            color: state.isSelected ? '#092F36' : '#000',
            '&:hover': {
                backgroundColor: '#e5e5e5',
            },
        }),
    };

    const openConfirmDialog = (message, onConfirmAction) => {
        setDialogMessage(message);
        setOnConfirm(() => onConfirmAction);
        setConfirmDialogOpen(true);
    };

    // Fetch Locations Data
    const fetchLocations = async () => {
        setIsFetching(true);
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/locations`, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            setLocations(response.data.message);
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
        setIsFetching(false);
    };

    // Fetch Resources Data
    const fetchResources = async () => {
        setIsFetching(true);
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/resources`, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            setResources(response.data.message);
        } catch (error) {
            console.error('Error fetching resources:', error);
        }
        setIsFetching(false);
    };

    useEffect(() => {
        if (activeSubtab === 'services') {
            fetchServices();
        } else if (activeSubtab === 'resources') {
            fetchResources();
        } else if (activeSubtab === 'locations') {
            fetchLocations();
        }
    }, [activeSubtab]);

    const handleAddLocation = async () => {
        const endpoint = `${process.env.REACT_APP_API_URL}/api/locations`;
        try {
            const locationData = {
                ...newLocation,
                timezone: newLocation.timezone.value,
            };
            const token = localStorage.getItem('access_token');
            await toast.promise(
                axios.post(endpoint, locationData, {
                    headers: {
                        Authorization: `${token}`,
                    },
                }).then(response => {
                    setLocationDialogOpen(false);
                    fetchLocations();
                }),
                {
                    pending: 'Creating timezone...',
                    success: 'Timezone created successfully!',
                    error: 'Error creating timezone',
                },
                {
                    position: "top-center"
                }
            );
        } catch (error) {
            console.error('Error adding service:', error);
        }
    };

    const handleAddService = async () => {

        const duplicateService = services.find(
            (service) => service.name.toLowerCase() === newService.name.toLowerCase()
        );
        if (duplicateService) {
            toast.error('A service with the same name already exists.', {
                position: "top-center",
            });
            return;
        }
        const serviceType = newService.type; // Get the type of service (fixed or flexible)
        if (!validateMinCancellation(newService) && serviceType == 'flexible') {
            return;
        }
        if (!validateServiceName(newService.name)) {
            return;
        }
        if (!validateServiceType(serviceType))
            return;
        if (!validateDuration(newService)) {
            return; // Prevent form submission if validation fails
        }
        const duration = convertToIsoDuration(newService.duration_hours, newService.duration_minutes);
        const min_duration = convertToIsoDuration(newService.min_duration_hours, newService.min_duration_minutes);
        const max_duration = convertToIsoDuration(newService.max_duration_hours, newService.max_duration_minutes);
        const cancellation_threshold = convertToIsoDuration(newService.cancellation_threshold_hours);
        let price = parseFloat(newService.price);

        if (!isNaN(price)) {
            price = price.toFixed(3);  // Ensure it's formatted only if it's a valid number
        }
        const formattedItem = {
            ...newService,
            duration,
            min_duration,
            max_duration,
            cancellation_threshold,
            ...(price && { price }),  // Only include price if it is valid
        };

        const endpoint = `${process.env.REACT_APP_API_URL}/api/services`;

        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                (async () => {
                    await axios.post(endpoint, formattedItem, {
                        headers: {
                            Authorization: `${token}`,
                        },
                    });
                    setServiceDialogOpen(false);
                    await fetchServices();
                })(),
                {
                    pending: 'Creating service...',
                    success: 'Service created successfully!',
                    error: 'Error creating service',
                },
                {
                    position: "top-center"
                }
            );
            await handleSaveChatbot();
        } catch (error) {
            console.error('Error adding service:', error);
        }
    };

    const onInputUpdate = (serviceTools) => {
        console.log('on input added', serviceTools);
        editService ?
            (setEditService({
                ...editService,
                tools: serviceTools
            }))
            :
            setNewService({
                ...newService,
                tools: serviceTools
            });
    };

    const handleAddResource = async () => {
        if (!newResource.services || newResource.services.length === 0) {
            setServicesError(true);
            return;
        }
        setEditResource(null);
        setServicesError(false);

        let hasInvalidTimeSlot = false;
        const newErrors = { ...timeSlotErrors };
        let formattedWorkingHours = {};
        daysOfWeek.forEach(day => {
            newErrors[day] = {};
            const recurringSchedule = newResource.working_hours.recurring_schedules[0];
            const dayScheduleBlocks = recurringSchedule.schedule_blocks.filter(block => block.schedule_block.weekday === day);

            const validTimeSlots = dayScheduleBlocks.filter((block, index) => {
                const timeSlot = block.schedule_block;
                if (!timeSlot.start_time || !timeSlot.end_time) {
                    hasInvalidTimeSlot = true;
                    newErrors[day][index] = 'Start time and end time are required.';
                    return false;
                }
                const startTime = new Date(`1970-01-01T${timeSlot.start_time}:00`).getTime();
                const endTime = new Date(`1970-01-01T${timeSlot.end_time}:00`).getTime();
                if (startTime >= endTime) {
                    hasInvalidTimeSlot = true;
                    newErrors[day][index] = 'Start time must be before end time.';
                    return false;
                }
                return true;
            });

            formattedWorkingHours[day] = validTimeSlots.map(block => block.schedule_block);
        });

        if (hasInvalidTimeSlot) {
            setTimeSlotErrors(newErrors);
            return;
        }

        const formattedResource = {
            ...newResource,
            working_hours: newResource.working_hours,
            services: newResource.services.map(service => ({
                id: service.id,
                name: service.name
            }))
        };

        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                axios.post(`${process.env.REACT_APP_API_URL}/api/resources`, formattedResource, {
                    headers: {
                        Authorization: `${token}`,
                    },
                }).then(response => {
                    setResourceDialogOpen(false);
                    fetchResources();
                }),
                {
                    pending: 'Creating resource...',
                    success: 'Resource created successfully!',
                    error: 'Error creating resource',
                },
                {
                    position: "top-center"
                }
            );
        } catch (error) {
            console.error('Error adding resource:', error);
        }
    };

    const handleEditResource = async () => {
        // Validate that at least one service is selected
        if (!editResource.services || editResource.services.length === 0) {
            setServicesError(true);
            return;
        }
        setServicesError(false);

        let hasInvalidTimeSlot = false;
        const newErrors = { ...timeSlotErrors };
        const recurringSchedule = editResource.working_hours.recurring_schedules[0];

        daysOfWeek.forEach(day => {
            newErrors[day] = {};
            const dayScheduleBlocks = recurringSchedule.schedule_blocks.filter(block => block.schedule_block.weekday === day);

            dayScheduleBlocks.forEach((block, index) => {
                const timeSlot = block.schedule_block;
                if (!timeSlot.start_time || !timeSlot.end_time) {
                    hasInvalidTimeSlot = true;
                    newErrors[day][index] = 'Start time and end time are required.';
                    return;
                }
                const startMinutes = parseTime(timeSlot.start_time);
                const endMinutes = parseTime(timeSlot.end_time);
                if (startMinutes === null || endMinutes === null) {
                    hasInvalidTimeSlot = true;
                    newErrors[day][index] = 'Invalid time format.';
                    return;
                }
                if (startMinutes >= endMinutes) {
                    hasInvalidTimeSlot = true;
                    newErrors[day][index] = 'Start time must be before end time.';
                    return;
                }
            });
        });

        if (hasInvalidTimeSlot) {
            setTimeSlotErrors(newErrors);
            return;
        } else {
            setTimeSlotErrors({});
        }

        const formattedResource = {
            ...editResource,
            services: editResource.services.map(service => ({
                id: service.id,
                name: service.name
            })),
            working_hours: editResource.working_hours
        };

        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                axios.patch(`${process.env.REACT_APP_API_URL}/api/resources/${editResource.id}`, formattedResource, {
                    headers: {
                        Authorization: `${token}`,
                    },
                }).then(response => {
                    setResourceDialogOpen(false);
                    setEditResource(null);
                    fetchResources();
                }),
                {
                    pending: 'Updating resource...',
                    success: 'Resource updated successfully!',
                    error: 'Error updating resource',
                },
                {
                    position: "top-center"
                }
            );
        } catch (error) {
            console.error('Error editing resource:', error);
        }
    };


    const handleEditLocation = async () => {
        const updatedLocation = {
            ...editLocation,
            timezone: editLocation.timezone.value,
        };

        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                axios.patch(`${process.env.REACT_APP_API_URL}/api/locations/${editLocation.id}`, updatedLocation, {
                    headers: {
                        Authorization: `${token}`,
                    },
                }).then(response => {
                    setLocationDialogOpen(false);
                    setEditLocation(null);
                    fetchLocations();
                }),
                {
                    pending: 'Updating timezone...',
                    success: 'Timezone updated successfully!',
                    error: 'Error updating timezone',
                },
                {
                    position: "top-center"
                }
            );
        } catch (error) {
            console.error('Error editing location:', error);
        }
    };

    const handleEditService = async () => {
        const duplicateService = services.find(
            (service) =>
                service.name.toLowerCase() === editService.name.toLowerCase() &&
                service.id !== editService.id
        );

        if (duplicateService) {
            toast.error('A service with the same name already exists.', {
                position: "top-center",
            });
            return;
        }
        const serviceType = editService.type; // Get the type of service (fixed or flexible)
        if (serviceType === 'fixed') {
            // Set min and max duration to null for fixed services
            editService.min_duration_hours = null;
            editService.min_duration_minutes = null;
            editService.max_duration_hours = null;
            editService.max_duration_minutes = null;
        } else if (serviceType === 'flexible') {
            // Set duration to null for flexible services
            editService.duration_hours = null;
            editService.duration_minutes = null;
        }
        if (!validateMinCancellation(editService)) {
            return;
        }
        if (!validateServiceName(editService.name)) {
            return;
        }
        if (!validateServiceType(serviceType))
            return;
        if (!validateDuration(editService)) {
            return; // Prevent form submission if validation fails
        }

        const duration = convertToIsoDuration(editService.duration_hours, editService.duration_minutes);
        const min_duration = convertToIsoDuration(editService.min_duration_hours, editService.min_duration_minutes);
        const max_duration = convertToIsoDuration(editService.max_duration_hours, editService.max_duration_minutes);
        const cancellation_threshold = convertToIsoDuration(editService.cancellation_threshold_hours);
        let price = parseFloat(editService.price);

        if (!isNaN(price)) {
            price = price.toFixed(3);  // Ensure it's formatted only if it's a valid number
        }
        const updatedService = {
            ...editService,
            duration,
            min_duration,
            max_duration,
            cancellation_threshold,
            ...(price && { price }),  // Only include price if it is valid
        };

        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                (async () => {
                    await axios.patch(`${process.env.REACT_APP_API_URL}/api/services/${editService.id}`, updatedService, {
                        headers: {
                            Authorization: `${token}`,
                        },
                    });
                    setServiceDialogOpen(false);
                    await fetchServices();
                })(),
                {
                    pending: 'Updating service...',
                    success: 'Service updated successfully!',
                    error: 'Error updating service',
                },
                {
                    position: "top-center"
                }
            );
            await handleSaveChatbot();
        } catch (error) {
            console.error('Error editing service:', error);
        }
    };

    const handleDeleteResource = async (resourceId) => {
        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                axios.delete(`${process.env.REACT_APP_API_URL}/api/resources/${resourceId}`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                }).then(response => {
                    fetchResources();
                }),
                {
                    pending: 'Deleting resource...',
                    success: 'Resource deleted successfully!',
                    error: 'Error deleting resource',
                },
                {
                    position: "top-center"
                }
            );
        } catch (error) {
            console.error('Error deleting resource:', error);
        }
    };

    const handleDeleteLocation = async (locationId) => {
        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                axios.delete(`${process.env.REACT_APP_API_URL}/api/locations/${locationId}`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                }).then(response => {
                    fetchLocations();
                }),
                {
                    pending: 'Deleting timezone...',
                    success: 'Timezone deleted successfully!',
                    error: 'Error deleting timezone',
                },
                {
                    position: "top-center"
                }
            );
        } catch (error) {
            console.error('Error deleting timezone:', error);
        }
    };

    const handleDeleteService = async (serviceId) => {
        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                axios.delete(`${process.env.REACT_APP_API_URL}/api/services/${serviceId}`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                }),
                {
                    pending: 'Deleting service...',
                    success: 'Service deleted successfully!',
                    error: 'Error deleting service',
                },
                {
                    position: "top-center"
                }
            );
            await fetchServices();
            await handleSaveChatbot();
        } catch (error) {
            console.error('Error deleting service:', error);
        }
    };

    const openEditDialog = (resource) => {
        setEditResource(resource);
        setResourceDialogOpen(true);
    };
    const openEditServiceDialog = (service) => {
        setEditService(service);
        setServiceDialogOpen(true);
    };
    const openEditLocationDialog = (location) => {
        setEditLocation(location);
        setLocationDialogOpen(true);
    };
    const validateServiceName = (serviceName) => {
        if (!serviceName || serviceName.length > 100) {
            setServiceNameError(true);
            return false;
        }
        setServiceNameError(false);
        return true;
    }
    const validateServiceType = (serviceType) => {
        if (!serviceType) {
            setServiceTypeError(true);
            return false;
        }
        setServiceTypeError(false);
        return true;
    }
    const validateMinCancellation = (service) => {
        if (!service.cancellation_threshold_hours) {
            setMinCancellationError(true);
            return false;
        }
        setServiceTypeError(false);
        return true;
    }
    const validateDuration = (service) => {
        const isInvalid = (value) => value === '' || value === null || value === undefined || value === '00';

        if (service.type === 'fixed') {
            // For 'fixed' type, ensure both fields are filled
            if (isInvalid(service.duration_hours) && isInvalid(service.duration_minutes)) {
                setDurationError(true);
                return false;
            }
        } else if (service.type === 'flexible') {
            // For 'flexible' type, ensure at least one field is filled
            if (isInvalid(service.max_duration_hours) && isInvalid(service.max_duration_minutes)) {
                setDurationError(true);
                return false;
            }
            if (isInvalid(service.min_duration_hours) && isInvalid(service.min_duration_minutes)) {
                setMinDurationError(true);
                return false;
            }
            const max_duration = convertToIsoDuration(service.max_duration_hours, service.max_duration_minutes);
            const min_duration = convertToIsoDuration(service.min_duration_hours, service.min_duration_hours);

            if (max_duration <= min_duration) {
                setDurationError(true);
                return false;
            }
        }

        setDurationError(false);
        setMinCancellationError(false)
        setMinDurationError(false);
        return true;
    };

    const renderContent = () => {
        switch (activeSubtab) {
            case 'quoteRules':
                return (
                    <Box>
                        <Typography>
                            Provide clear instructions on how you'd like your quote for flexible services to be calculated using the data you've supplied.
                            <br />
                            <strong>Think about it as if you were going on holiday, and your co-worker would be responsible for providing the quote. How would you explain the quote process to them?</strong>
                            <br />
                            <strong>You should be clear on how the price and duration of the service must be calculated.</strong> You may also add additional files to support the quote calculation (e.g., an excel file containing the price per item)
                        </Typography>
                        <Box mb={2}>
                            <TextField
                                sx={{
                                    backgroundColor: 'white',
                                }}
                                value={quoteInstructions}
                                onChange={(e) => setQuoteInstructions(e.target.value)}
                                variant="outlined"
                                fullWidth
                                multiline
                                placeholder={`Example 1 (Removalist): Baseline price is $400 for any removalist job. 
If removal date is on the weekends or public holidays, add $100
If the origin location is greater than 30 kms from Perth CBD, then add $5 per additional kilometer.
If the destiny location is greater than 30kms from Perth CBD, add $5 per additional kilometer. 
Add $100 per number of bedrooms
If it’s an apartment without elevator, add $100.
If packing is required, add $200.
We only do removalist job within 500km of Perth CBD
`}
                                rows={10}
                            />
                            <Button
                                onClick={handleSaveChatbot}
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={isSaving}
                                sx={{ marginTop: '16px' }}
                                fullWidth={isMobile}
                            >
                                {isSaving ? <CircularProgress size={24} /> : 'Save'}
                            </Button>
                        </Box>
                        <FileUpload
                            label="Drag and drop your PDF, CSV, DOCX, or TXT file here, or click to select it. (20 MB limit)"
                            type="file"
                            multiple={true}
                            onSuccessfulUpload={handleSuccessfulUpload}
                            fileTypes={['PDF', 'DOCX', 'TXT', 'CSV']}
                        />
                        <Box mt={2} sx={{ overflowX: 'auto' }}>
                            <TableVirtuoso
                                data={files}
                                allowFullScreen
                                useWindowScroll
                                components={VirtuosoTableComponents}
                                fixedHeaderContent={() => (
                                    <TableRow>
                                        <TableCell>File Name</TableCell>
                                        <TableCell>Characters</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                )}
                                itemContent={(index, file) => (
                                    <>
                                        <TableCell> {file?.file_name}</TableCell>
                                        <TableCell>{file?.num_of_chars}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                onClick={() => openConfirmDialog('Are you sure you want to delete this item? This action cannot be undone.', () => handleDeleteFile(file.id))}
                                                startIcon={<DeleteIcon />}
                                                size={isMobile ? "small" : "medium"}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </>
                                )}
                            />
                        </Box>
                    </Box>
                );
            case "quoteInput":
                return <QuoteTab />;

            case 'services':
                return (
                    <Box>
                        <Typography padding="1rem 0">
                            Add below the services that you provide. For instance, if you run a clinic, you may provide "Short Appointment (fixed duration of 30 mins and $100) service" and "Long appointment (fixed duration of 1 hour and $180) service". On the other hand. if you are a removalist company, your service duration and price may be flexible based on the customer requirements.
                            <br />
                            Each service may have a fixed or flexible duration. Flexible services require inputs from the customer to be estimated properly.
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setNewService({ name: '', price: '', type: '', tools: [] });
                                setServiceDialogOpen(true);
                                setEditService(null);
                            }}
                            sx={{ marginBottom: '16px' }}
                            fullWidth={isMobile}
                        >
                            Add Service
                        </Button>
                        <Box sx={{ overflowX: 'auto' }}>
                            <TableVirtuoso
                                data={services}
                                allowFullScreen
                                useWindowScroll
                                components={VirtuosoTableComponents}
                                fixedHeaderContent={() => (
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Duration</TableCell>
                                        <TableCell>Min Duration</TableCell>
                                        <TableCell>Max Duration</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                )}
                                itemContent={(index, service) => (
                                    <>
                                        <TableCell>{service.name}</TableCell>
                                        <TableCell>{service.type}</TableCell>
                                        <TableCell>
                                            {service.price && !isNaN(service.price)
                                                ? `£${parseFloat(service.price).toFixed(2)}`  // Format and display if valid
                                                : '' // Fallback if price is invalid
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {service.duration
                                                ? `${convertFromIsoDuration(service.duration).hours}:${convertFromIsoDuration(service.duration).minutes}`
                                                : '' // Fallback if duration is invalid
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {service.min_duration
                                                ? `${convertFromIsoDuration(service.min_duration).hours}:${convertFromIsoDuration(service.min_duration).minutes}`
                                                : '' // Fallback if min_duration is invalid
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {service.max_duration
                                                ? `${convertFromIsoDuration(service.max_duration).hours}:${convertFromIsoDuration(service.max_duration).minutes}`
                                                : '' // Fallback if max_duration is invalid
                                            }
                                        </TableCell>

                                        <TableCell>
                                            <Stack direction="row" spacing={1}>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => openEditServiceDialog(service)}
                                                    startIcon={<EditIcon />}
                                                    size={isMobile ? "small" : "medium"}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={() => openConfirmDialog('Are you sure you want to delete this item? This action cannot be undone.', () => handleDeleteService(service.id))}
                                                    startIcon={<DeleteIcon />}
                                                    size={isMobile ? "small" : "medium"}
                                                >
                                                    Delete
                                                </Button>
                                            </Stack>
                                        </TableCell>
                                    </>
                                )}
                            />
                        </Box>
                    </Box>
                );
            case 'locations':
                return (
                    <Box>
                        <Typography padding="1rem 0">
                            Add below the timezone that you provide your services.
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setLocationDialogOpen(true);
                                setEditLocation(null);
                            }}
                            sx={{ marginBottom: '16px' }}
                            fullWidth={isMobile}
                        >
                            Add Location
                        </Button>
                        <Box sx={{ overflowX: 'auto' }}>
                            <TableVirtuoso
                                data={locations}
                                allowFullScreen
                                useWindowScroll
                                components={VirtuosoTableComponents}
                                fixedHeaderContent={() => (
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Timezone</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                )}
                                itemContent={(index, location) => (
                                    <>
                                        <TableCell>{location.name}</TableCell>
                                        <TableCell>{location.timezone}</TableCell>
                                        <TableCell>
                                            <Stack direction="row" spacing={1}>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => openEditLocationDialog(location)}
                                                    startIcon={<EditIcon />}
                                                    size={isMobile ? "small" : "medium"}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={() => openConfirmDialog('Are you sure you want to delete this item? This action cannot be undone.', () => handleDeleteLocation(location.id))}
                                                    startIcon={<DeleteIcon />}
                                                    size={isMobile ? "small" : "medium"}
                                                >
                                                    Delete
                                                </Button>
                                            </Stack>
                                        </TableCell>
                                    </>
                                )}
                            />
                        </Box>
                    </Box>
                );

            case 'resources':
                return (
                    <Box>
                        <Typography padding="1rem 0">
                            Add the resources that deliver your services. For example, if you run a clinic, <strong>each doctor is considered a resource</strong>. Similarly, if you offer removalist services, <strong>each truck you operate is a resource</strong>. For each resource, make sure to assign the relevant service they provide and specify their working hours.                        </Typography>
                        <Button
                            variant="contained"
                            onClick={() => {
                                fetchServices();
                                setResourceDialogOpen(true);
                                setEditResource(null);
                            }}
                            sx={{ marginBottom: '16px' }}
                            fullWidth={isMobile}
                        >
                            Add Resource
                        </Button>
                        <Box sx={{ overflowX: 'auto' }}>
                            <TableVirtuoso
                                data={resources}
                                allowFullScreen
                                useWindowScroll
                                components={VirtuosoTableComponents}
                                fixedHeaderContent={() => (
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Services</TableCell>
                                        <TableCell>Working Hours</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                )}
                                itemContent={(index, resource) => (
                                    <>
                                        <TableCell>{resource.name}</TableCell>
                                        <TableCell>
                                            {resource.services.map((service, index) => (
                                                <Chip key={index} label={service.name} sx={{ margin: '2px' }} />
                                            ))}
                                        </TableCell>
                                        <TableCell>
                                            {resource?.working_hours?.recurring_schedules?.map((schedule, schedIndex) => (
                                                schedule.schedule_blocks.map((block, blockIndex) => (
                                                    <Box key={`${schedIndex}-${blockIndex}`} mb={1}>
                                                        <strong>{block.schedule_block.weekday}:</strong> {block.schedule_block.start_time} - {block.schedule_block.end_time}
                                                    </Box>
                                                ))
                                            ))}
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction="row" spacing={1}>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => openEditDialog(resource)}
                                                    startIcon={<EditIcon />}
                                                    size={isMobile ? "small" : "medium"}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={() => openConfirmDialog('Are you sure you want to delete this item? This action cannot be undone.', () => handleDeleteResource(resource.id))}
                                                    startIcon={<DeleteIcon />}
                                                    size={isMobile ? "small" : "medium"}
                                                >
                                                    Delete
                                                </Button>
                                            </Stack>
                                        </TableCell>
                                    </>
                                )}
                            />
                        </Box>
                    </Box>
                );
            default:
                return null;
        }
    };

    const renderWorkingHoursInputs = () => {
        const resource = editResource ? editResource : newResource;
        const recurringSchedules = resource.working_hours?.recurring_schedules?.[0]?.schedule_blocks || [];
        const groupedByDay = daysOfWeek.reduce((acc, day) => {
            acc[day] = recurringSchedules.filter(block => block.schedule_block.weekday === day);
            return acc;
        }, {});

        return daysOfWeek.map((day) => (
            <Box key={day} marginBottom={2}>
                <Typography variant="subtitle1" fontWeight="bold">{day}</Typography>
                {groupedByDay[day].length > 0 && (
                    groupedByDay[day].map((block, index) => (
                        <Stack key={index} direction={isMobile ? "column" : "row"} spacing={1} alignItems="center" marginBottom={1}>
                            <TextField
                                label="Start Time"
                                type="time"
                                value={block.schedule_block.start_time || ''}
                                onChange={(e) => handleWorkingHoursChange(day, index, 'start_time', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ step: 300 }}
                                margin="dense"
                                error={Boolean(timeSlotErrors[day] && timeSlotErrors[day][index])}
                                fullWidth={isMobile}
                            />
                            <TextField
                                label="End Time"
                                type="time"
                                value={block.schedule_block.end_time || ''}
                                onChange={(e) => handleWorkingHoursChange(day, index, 'end_time', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ step: 300 }}
                                margin="dense"
                                error={Boolean(timeSlotErrors[day] && timeSlotErrors[day][index])}
                                fullWidth={isMobile}
                            />
                            <IconButton onClick={() => handleRemoveTimeSlot(day, index)} size={isMobile ? "small" : "medium"}>
                                <DeleteIcon />
                            </IconButton>
                        </Stack>
                    ))
                )}
                <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => handleAddTimeSlot(day)}
                    fullWidth={isMobile}
                >
                    Add Time Slot
                </Button>
                {timeSlotErrors[day] && Object.keys(timeSlotErrors[day]).length > 0 && (
                    Object.entries(timeSlotErrors[day]).map(([index, error]) => (
                        <Typography key={index} variant="caption" color="error" align="left">
                            {error}
                        </Typography>
                    ))
                )}
            </Box>
        ));
    };


    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant="h4" fontWeight="700" padding="1rem 0">Quote & Bookings Settings</Typography>

            <Tabs
                value={activeSubtab}
                onChange={(e, val) => setActiveSubtab(val)}
                aria-label="booking subtabs"
                variant={isMobile ? "fullWidth" : "standard"}
                scrollButtons={isMobile ? "on" : "off"}
                visibleScrollbar
                allowScrollButtonsMobile
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    width: '96%',
                }}
            >
                <Tab
                    value="services"
                    label="Services"
                    id="services"
                    sx={{
                        minWidth: isMobile ? 60 : 120, // Adjust minWidth based on screen size
                        padding: isMobile ? '6px 8px' : '12px 16px', // Reduce padding on mobile
                        textTransform: 'none', // Optional: Keep label case as is
                        fontSize: isMobile ? '0.875rem' : '1rem', // Adjust font size for readability
                    }}
                />
                {hasFlexibleService &&
                    <Tab
                        value="quoteRules"
                        label="How to Precify"
                        id="quoteRules"
                        sx={{
                            minWidth: isMobile ? 60 : 120, // Adjust minWidth based on screen size
                            padding: isMobile ? '6px 8px' : '12px 16px',
                            textTransform: 'none',
                            fontSize: isMobile ? '0.875rem' : '1rem',
                        }}
                    />}
                <Tab
                    value="locations"
                    label="Timezone"
                    id="locations"
                    sx={{
                        minWidth: isMobile ? 60 : 120, // Adjust minWidth based on screen size
                        padding: isMobile ? '6px 8px' : '12px 16px',
                        textTransform: 'none',
                        fontSize: isMobile ? '0.875rem' : '1rem',
                    }}
                />
                <Tab
                    value="resources"
                    label="Resources"
                    id="resources"
                    sx={{
                        minWidth: isMobile ? 60 : 120, // Adjust minWidth based on screen size
                        padding: isMobile ? '6px 8px' : '12px 16px',
                        textTransform: 'none',
                        fontSize: isMobile ? '0.875rem' : '1rem',
                    }}
                />
            </Tabs>
            <Paper square={false} elevation={4} sx={{ padding: isMobile ? "1rem" : "0.5rem", margin: '0', width: '95%' }}>

                {isFetching ? <Spinner /> : renderContent()}
            </Paper>

            {/* Service Dialog */}
            <Dialog
                fullScreen={isMobile}
                fullWidth
                maxWidth="lg"
                open={serviceDialogOpen}
                onClose={() => setServiceDialogOpen(false)}
            >
                <DialogTitle>{editService ? 'Edit Service' : 'Add Service'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {/* Service Name */}
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                <TextField
                                    label='Service Name'
                                    placeholder="Your Service Name e.g. Removalist Job, Short Appointment (30mins), Carpet Cleaning"
                                    InputLabelProps={{ shrink: true }}
                                    value={editService ? editService.name : newService.name}
                                    onChange={(e) => editService ? setEditService({ ...editService, name: e.target.value }) : setNewService({ ...newService, name: e.target.value })}
                                    fullWidth
                                    required
                                    margin="dense"
                                    error={serviceNameError}
                                    helperText={serviceNameError ? 'Service name is required and must be under 100 digits' : ''}
                                />
                                <Tooltip title="The name of the service.">
                                    <IconButton>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Grid>

                        {/* Type */}
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                <FormControl fullWidth margin="dense" disabled={selectedTab === 'resources'} error={serviceTypeError}>
                                    <InputLabel id="service-type-label">Type</InputLabel>
                                    <Select
                                        value={editService ? editService.type : newService.type}
                                        onChange={(e) => editService ? setEditService({ ...editService, type: e.target.value }) : setNewService({ ...newService, type: e.target.value })}
                                        label="Type"
                                        InputLabelProps={{ shrink: true }}
                                        required


                                    >
                                        <MenuItem value="fixed">Fixed</MenuItem>
                                        <MenuItem value="flexible">Flexible</MenuItem>
                                    </Select>
                                    <FormHelperText>Please fill the service type</FormHelperText>
                                </FormControl>
                                <Tooltip title="The type of the service.

The type fixed is used for services that have a fixed time duration.

The type flexible is used for services that have a flexible time duration..">
                                    <IconButton>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Grid>

                        {/* Conditional Fields */}
                        {editService ? (
                            editService.type === 'flexible' ? (
                                <>
                                    {/* Hourly Rate */}
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" alignItems="center">
                                            <TextField
                                                label="Hourly Rate"
                                                value={editService.price}
                                                placeholder="100.00"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => setEditService({ ...editService, price: e.target.value })}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                                fullWidth
                                                margin="dense"
                                            />
                                            <Tooltip title="The price of the service. For services with the type fixed, this is a fixed price. For services with the type flexible, this is the hourly rate">
                                                <IconButton>
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>

                                    {/* Min Duration */}
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <TextField
                                                label="Min Duration (Hours)"
                                                type="number"
                                                placeholder="Hours"
                                                InputLabelProps={{ shrink: true }}
                                                value={editService.min_duration_hours}
                                                onChange={(e) => setEditService({ ...editService, min_duration_hours: e.target.value })}
                                                fullWidth
                                                margin="dense"
                                                inputProps={{ min: 0 }}
                                                error={minDurationError}
                                                helperText={minDurationError ? 'Please fill either the minimum duration in hours or minutes' : ''}

                                            />
                                            <TextField
                                                label="Min Duration (Minutes)"
                                                type="number"
                                                placeholder="Minutes"
                                                InputLabelProps={{ shrink: true }}
                                                value={editService.min_duration_minutes}
                                                onChange={(e) => setEditService({ ...editService, min_duration_minutes: e.target.value })}
                                                fullWidth
                                                margin="dense"
                                                inputProps={{ min: 0, max: 59 }}

                                            />
                                            <Tooltip title="The minimum duration allowed for booking the service.">
                                                <IconButton>
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>

                                    {/* Max Duration */}
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <TextField
                                                label="Max Duration (Hours)"
                                                type="number"
                                                placeholder="Hours"
                                                InputLabelProps={{ shrink: true }}
                                                value={editService.max_duration_hours}
                                                onChange={(e) => setEditService({ ...editService, max_duration_hours: e.target.value })}
                                                fullWidth
                                                margin="dense"
                                                inputProps={{ min: 0 }}
                                                error={durationError}
                                                helperText={durationError ? 'Please fill either the maximum duration in hours or minutes' : ''}

                                            />
                                            <TextField
                                                label="Max Duration (Minutes)"
                                                type="number"
                                                placeholder="Minutes"
                                                InputLabelProps={{ shrink: true }}
                                                value={editService.max_duration_minutes}
                                                onChange={(e) => setEditService({ ...editService, max_duration_minutes: e.target.value })}
                                                fullWidth
                                                margin="dense"
                                                inputProps={{ min: 0, max: 59 }}

                                            />
                                            <Tooltip title="The maximum duration allowed for booking the service.">
                                                <IconButton>
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>

                                    {/* Cancellation Threshold */}
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <TextField
                                                label="Minimum Notice for Cancellation (Hours)"
                                                placeholder="Hours"
                                                InputLabelProps={{ shrink: true }}
                                                value={editService.cancellation_threshold_hours}
                                                onChange={(e) => setEditService({ ...editService, cancellation_threshold_hours: e.target.value })}
                                                fullWidth
                                                required
                                                margin="dense"
                                                error={minCancellationError}
                                                helperText={minCancellationError ? 'Please fill the minimum notice for cancellation' : ''}
                                            />
                                            <Tooltip title="Time required before booking start to allow cancellation">
                                                <IconButton>
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    {/* Price */}
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" alignItems="center">
                                            <TextField
                                                label="Price"
                                                value={editService.price}
                                                placeholder="100.00"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => setEditService({ ...editService, price: e.target.value })}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                                fullWidth
                                                margin="dense"
                                            />
                                            <Tooltip title="The price of the service. For services with the type fixed, this is a fixed price. For services with the type flexible, this is the hourly rate">
                                                <IconButton>
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>

                                    {/* Duration */}
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <TextField
                                                label="Duration (Hours)"
                                                type="number"
                                                value={editService.duration_hours}
                                                onChange={(e) => setEditService({ ...editService, duration_hours: e.target.value })}
                                                fullWidth
                                                margin="dense"
                                                error={durationError}
                                                helperText={durationError ? 'Please fill either the duration in hours or minutes' : ''}
                                            />
                                            <TextField
                                                label="Duration (Minutes)"
                                                type="number"
                                                value={editService.duration_minutes}
                                                onChange={(e) => setEditService({ ...editService, duration_minutes: e.target.value })}
                                                fullWidth
                                                margin="dense"
                                                inputProps={{ min: 0, max: 59 }}
                                            />
                                            <Tooltip title="The duration for the service.">
                                                <IconButton>
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                </>
                            )
                        ) : (
                            newService.type === 'flexible' ? (
                                <>
                                    {/* Hourly Rate */}
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" alignItems="center">
                                            <TextField
                                                label="Hourly Rate"
                                                value={newService.price}
                                                placeholder="100.00"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => setNewService({ ...newService, price: e.target.value })}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                                fullWidth
                                                margin="dense"

                                            />
                                            <Tooltip title="The price of the service. For services with the type fixed, this is a fixed price. For services with the type flexible, this is the hourly rate">
                                                <IconButton>
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>

                                    {/* Min Duration */}
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <TextField
                                                label="Min Duration (Hours)"
                                                type="number"
                                                placeholder="Hours"
                                                InputLabelProps={{ shrink: true }}
                                                value={newService.min_duration_hours}
                                                onChange={(e) => setNewService({ ...newService, min_duration_hours: e.target.value })}
                                                fullWidth
                                                margin="dense"
                                                inputProps={{ min: 0 }}
                                                error={minDurationError}
                                                helperText={minDurationError ? 'Please fill either the minimum duration in hours or minutes' : ''}
                                            />
                                            <TextField
                                                label="Min Duration (Minutes)"
                                                type="number"
                                                placeholder="Minutes"
                                                InputLabelProps={{ shrink: true }}
                                                value={newService.min_duration_minutes}
                                                onChange={(e) => setNewService({ ...newService, min_duration_minutes: e.target.value })}
                                                fullWidth
                                                margin="dense"
                                                inputProps={{ min: 0, max: 59 }}
                                            />
                                            <Tooltip title="The minimum duration allowed for booking the service.">
                                                <IconButton>
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>

                                    {/* Max Duration */}
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <TextField
                                                label="Max Duration (Hours)"
                                                type="number"
                                                placeholder="Hours"
                                                InputLabelProps={{ shrink: true }}
                                                value={newService.max_duration_hours}
                                                onChange={(e) => setNewService({ ...newService, max_duration_hours: e.target.value })}
                                                fullWidth
                                                margin="dense"
                                                inputProps={{ min: 0 }}
                                                error={durationError}
                                                helperText={durationError ? 'Please fill either the max duration in hours or minutes' : ''}
                                            />
                                            <TextField
                                                label="Max Duration (Minutes)"
                                                type="number"
                                                placeholder="Minutes"
                                                InputLabelProps={{ shrink: true }}
                                                value={newService.max_duration_minutes}
                                                onChange={(e) => setNewService({ ...newService, max_duration_minutes: e.target.value })}
                                                fullWidth
                                                margin="dense"
                                                disabled={selectedTab === 'resources'}
                                                inputProps={{ min: 0, max: 59 }}
                                            />
                                            <Tooltip title="The maximum duration allowed for booking the service.">
                                                <IconButton>
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>

                                    {/* Cancellation Threshold */}
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <TextField
                                                label="Minimum Notice for Cancellation (Hours)"
                                                placeholder="Hours"
                                                InputLabelProps={{ shrink: true }}
                                                value={newService.cancellation_threshold_hours}
                                                onChange={(e) => setNewService({ ...newService, cancellation_threshold_hours: e.target.value })}
                                                fullWidth
                                                margin="dense"
                                                disabled={selectedTab === 'resources'}
                                            />
                                            <Tooltip title="Time required before booking start to allow cancellation">
                                                <IconButton>
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    {/* Price */}
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" alignItems="center">
                                            <TextField
                                                label="Price"
                                                value={newService.price}
                                                placeholder="100.00"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => setNewService({ ...newService, price: e.target.value })}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                                fullWidth
                                                margin="dense"
                                                disabled={selectedTab === 'resources'}
                                            />
                                            <Tooltip title="The price of the service. For services with the type fixed, this is a fixed price. For services with the type flexible, this is the hourly rate">
                                                <IconButton>
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>

                                    {/* Duration */}
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <TextField
                                                label="Duration (Hours)"
                                                type="number"
                                                value={newService.duration_hours}
                                                onChange={(e) => setNewService({ ...newService, duration_hours: e.target.value })}
                                                fullWidth
                                                margin="dense"
                                                error={durationError}
                                                helperText={durationError ? 'Please fill either the duration in hours or minutes' : ''} />
                                            <TextField
                                                label="Duration (Minutes)"
                                                type="number"
                                                value={newService.duration_minutes}
                                                onChange={(e) => setNewService({ ...newService, duration_minutes: e.target.value })}
                                                fullWidth
                                                margin="dense"
                                                disabled={selectedTab === 'resources'}
                                                inputProps={{ min: 0, max: 59 }}
                                            />
                                            <Tooltip title="The duration for the service.">
                                                <IconButton>
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                </>
                            )
                        )}

                        {/* Service Quote Component */}
                        <Grid item xs={12}>
                            {editService && editService.type === 'flexible' ? (
                                <ServiceQuote service={editService} onInputUpdate={onInputUpdate} />
                            ) : (newService && newService.type === 'flexible' && <ServiceQuote service={newService} onInputUpdate={onInputUpdate} />)}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setServiceDialogOpen(false)} size={isMobile ? "small" : "medium"}>Cancel</Button>
                    <Button onClick={editService ? handleEditService : handleAddService} color="primary" size={isMobile ? "small" : "medium"}>
                        {editService ? 'Save Changes' : 'Add Service'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirm Dialog */}
            <Dialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                fullScreen={isMobile}
            >
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialogOpen(false)} size={isMobile ? "small" : "medium"}>Cancel</Button>
                    <Button
                        onClick={() => {
                            onConfirm();
                            setConfirmDialogOpen(false);
                        }}
                        color="error"
                        size={isMobile ? "small" : "medium"}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Resource Dialog */}
            <Dialog
                fullScreen={isMobile}
                fullWidth
                open={resourceDialogOpen}
                onClose={() => setResourceDialogOpen(false)}
            >
                <DialogTitle>{editResource ? 'Edit Resource' : 'Add Resource'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {/* Resource Name */}
                        <Grid item xs={12}>
                            <TextField
                                label="Resource Name"
                                placeholder="Resource Name"
                                InputLabelProps={{ shrink: true }}
                                value={editResource ? editResource.name : newResource.name}
                                onChange={(e) => editResource ? setEditResource({ ...editResource, name: e.target.value }) : setNewResource({ ...newResource, name: e.target.value })}
                                fullWidth
                                margin="dense"
                            />
                        </Grid>



                        {/* Services Selection */}
                        <Grid item xs={12}>
                            <CustomSelect
                                styles={customStyles}
                                isMulti
                                menuPortalTarget={document.body}
                                options={services}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                value={editResource ? editResource.services : newResource.services}
                                onChange={(selectedOptions) => {
                                    setServicesError(false);
                                    const selectedServices = selectedOptions
                                        ? selectedOptions.map((service) => ({ id: service.id, name: service.name }))
                                        : [];
                                    if (editResource) {
                                        setEditResource({ ...editResource, services: selectedServices });
                                    } else {
                                        setNewResource({ ...newResource, services: selectedServices });
                                    }
                                }}
                                placeholder="Select services"
                                className="custom-select"
                            />
                            {servicesError && (
                                <Typography variant="caption" color="error">
                                    At least one service must be selected.
                                </Typography>
                            )}
                        </Grid>

                        {/* Working Hours */}
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight="bold">Working Hours</Typography>
                            {renderWorkingHoursInputs()}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setResourceDialogOpen(false)} size={isMobile ? "small" : "medium"}>Cancel</Button>
                    <Button onClick={editResource ? handleEditResource : handleAddResource} color="primary" size={isMobile ? "small" : "medium"}>
                        {editResource ? 'Save Changes' : 'Add Resource'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Location Dialog */}
            <Dialog
                fullScreen={isMobile}
                fullWidth
                open={locationDialogOpen}
                onClose={() => setLocationDialogOpen(false)}
            >
                <DialogTitle>{editLocation ? 'Edit Timezone' : 'Add Timezone'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {/* Location Name */}
                        <Grid item xs={12}>
                            <TextField
                                label="Location Name"
                                placeholder="Location Name"
                                InputLabelProps={{ shrink: true }}
                                value={editLocation ? editLocation.name : newLocation.name}
                                onChange={(e) => editLocation ? setEditLocation({ ...editLocation, name: e.target.value }) : setNewLocation({ ...newLocation, name: e.target.value })}
                                fullWidth
                                margin="dense"
                                sx={{ backgroundColor: 'white' }}
                            />
                        </Grid>

                        {/* Timezone Selection */}
                        <Grid item xs={12}>
                            <TimezoneSelect
                                value={editLocation ? editLocation.timezone : newLocation.timezone}
                                onChange={(newValue) => {
                                    if (editLocation) {
                                        setEditLocation({ ...editLocation, timezone: newValue });
                                    } else {
                                        setNewLocation({ ...newLocation, timezone: newValue });
                                    }
                                }}
                                menuPortalTarget={document.body}
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        width: '100%',
                                        marginTop: theme.spacing(1),
                                    }),
                                    control: (provided, state) => ({
                                        ...provided,
                                        width: '100%',
                                        minHeight: '56px',
                                        height: 'auto',
                                        borderColor: state.isFocused ? theme.palette.primary.main : theme.palette.divider,
                                        '&:hover': {
                                            borderColor: theme.palette.text.primary,
                                        },
                                        boxShadow: 'none',
                                    }),
                                    placeholder: (provided) => ({
                                        ...provided,
                                        color: theme.palette.text.secondary,
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: theme.palette.text.primary,
                                    }),
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 1350,
                                    }),
                                }}
                                theme={(selectTheme) => ({
                                    ...selectTheme,
                                    colors: {
                                        ...selectTheme.colors,
                                        primary: theme.palette.primary.main,
                                    },
                                })}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setLocationDialogOpen(false)} size={isMobile ? "small" : "medium"}>Cancel</Button>
                    <Button onClick={editLocation ? handleEditLocation : handleAddLocation} color="primary" size={isMobile ? "small" : "medium"}>
                        {editLocation ? 'Save Changes' : 'Add Location'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default BookingTab;
