import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject, Resize, DragAndDrop, TimelineViews } from '@syncfusion/ej2-react-schedule';
import axios from 'axios';
import './Scheduler.css';
import Spinner from '../components/Spinner'
import { L10n, loadCldr, createElement } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-dropdowns';

// Import CLDR data
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as gregorian from 'cldr-data/main/en-AU/ca-gregorian.json';
import * as numbers from 'cldr-data/main/en-AU/numbers.json';
import * as timeZoneNames from 'cldr-data/main/en-AU/timeZoneNames.json';

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

const Scheduler = ({ details }) => {
  const scheduleObj = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState([]);
  const location = details.location_id;
  // Fetch Services Data
  const fetchServices = async () => {
    try {
      const token = localStorage.getItem('access_token');

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/services`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      // Ensure fetchedServices is not null or undefined, and it is an array
      const fetchedServices = Array.isArray(response.data.message) ? response.data.message : [];

      const dropDownData = fetchedServices.map(service => ({
        text: service.name,  // 'name' field from the API becomes 'text' for dropdown
        value: service.id    // 'id' field from the API becomes 'value' for dropdown
      }));

      // Set the mapped data to the state
      setServices(dropDownData); // Assuming setServices is a state setter function
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  useEffect(() => {
    fetchServices();

  }, []);
  const onPopupOpen = (args) => {
    if (args.type === 'Editor') {
      const eventData = args.data;

      if (!args.element.querySelector('.custom-field-row')) {
        let row = createElement('div', { className: 'custom-field-row' });
        let formElement = args.element.querySelector('.e-schedule-form');
        formElement.firstChild.insertBefore(row, formElement.firstChild.firstChild);

        // Price Field
        let priceContainer = createElement('div', { className: 'e-float-input e-control-wrapper' });
        let priceInput = createElement('input', {
          className: 'e-field', attrs: { name: 'Price', type: 'number', required: 'required' }
        });
        let priceLabel = createElement('span', { className: 'e-float-line' });
        let pricePlaceholder = createElement('label', { className: 'e-float-text', innerHTML: 'Price' });
        priceContainer.appendChild(priceInput);
        priceContainer.appendChild(priceLabel);
        priceContainer.appendChild(pricePlaceholder);
        row.appendChild(priceContainer);

        //Service Field

        let serviceContainer = createElement('div', { className: 'e-float-input e-control-wrapper' });
        let serviceInput = createElement('input', {
          className: 'e-field', attrs: { name: 'Service', required: 'required' }
        });
        // let serviceLabel = createElement('span', { className: 'e-float-line' });
        //  let servicePlaceholder = createElement('label', { className: 'e-float-text', innerHTML: 'Service' });
        serviceContainer.appendChild(serviceInput);
        //serviceContainer.appendChild(serviceLabel);
        // serviceContainer.appendChild(servicePlaceholder);
        row.appendChild(serviceContainer);
        let dropDownList = new DropDownList({
          dataSource: services,
          fields: { text: 'text', value: 'value' },
          value: args.data.Service,
          floatLabelType: 'Always', placeholder: 'Service'
        });
        dropDownList.appendTo(serviceInput);
        serviceInput.setAttribute('name', 'Service');


        // Customer Name Field
        let nameContainer = createElement('div', { className: 'e-float-input e-control-wrapper' });
        let nameInput = createElement('input', {
          className: 'e-field', attrs: { name: 'CustomerName', type: 'text', required: 'required' }
        });
        let nameLabel = createElement('span', { className: 'e-float-line' });
        let namePlaceholder = createElement('label', { className: 'e-float-text', innerHTML: 'Customer Name' });
        nameContainer.appendChild(nameInput);
        nameContainer.appendChild(nameLabel);
        nameContainer.appendChild(namePlaceholder);
        row.appendChild(nameContainer);

        // Customer Contact Number Field
        let contactContainer = createElement('div', { className: 'e-float-input e-control-wrapper' });
        let contactInput = createElement('input', {
          className: 'e-field', attrs: { name: 'CustomerContact', type: 'tel', required: 'required' }
        });
        let contactLabel = createElement('span', { className: 'e-float-line' });
        let contactPlaceholder = createElement('label', { className: 'e-float-text', innerHTML: 'Customer Contact Number' });
        contactContainer.appendChild(contactInput);
        contactContainer.appendChild(contactLabel);
        contactContainer.appendChild(contactPlaceholder);
        row.appendChild(contactContainer);

        // Customer Email Address Field
        let emailContainer = createElement('div', { className: 'e-float-input e-control-wrapper' });
        let emailInput = createElement('input', {
          className: 'e-field', attrs: { name: 'CustomerEmail', type: 'email', required: 'required' }
        });
        let emailLabel = createElement('span', { className: 'e-float-line' });
        let emailPlaceholder = createElement('label', { className: 'e-float-text', innerHTML: 'Customer Email Address' });
        emailContainer.appendChild(emailInput);
        emailContainer.appendChild(emailLabel);
        emailContainer.appendChild(emailPlaceholder);
        row.appendChild(emailContainer);
      }

      // Bind the values from event data to the custom fields
      if (eventData) {
        console.log('eventData', eventData)
        let dropDownList = args.element.querySelector('input[name="Service"]').ej2_instances[0];
        dropDownList.value = eventData?.ServiceID || null; // Setting the ServiceID to the DropDownList programmatically
        dropDownList.dataBind(); // Rebind the dropdown to apply the new value        args.element.querySelector('input[name="Price"]').value = eventData.Price || '';
        args.element.querySelector('input[name="CustomerName"]').value = eventData.CustomerName || '';
        args.element.querySelector('input[name="CustomerContact"]').value = eventData.CustomerContact || '';
        args.element.querySelector('input[name="CustomerEmail"]').value = eventData.CustomerEmail || '';
      }
    }
  };





  useEffect(() => {
    const fetchData = async () => {
      const currentDate = new Date();

      // Create 'from' date by subtracting 14 days
      const fromDate = new Date(currentDate);
      fromDate.setDate(fromDate.getDate() - 14);

      // Create 'to' date by adding 14 days
      const toDate = new Date(currentDate);
      toDate.setDate(toDate.getDate() + 14);

      // Format the dates using your existing function
      const from = formatDateWithTimezone(fromDate);
      const to = formatDateWithTimezone(toDate);


      try {
        setLoading(true)
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/bookings`, {
          params: { from, to },

          headers: {
            Authorization: token,

          }
        },
        );

        const fetchedData = response?.data?.message?.data?.map(booking => ({
          Id: booking.id,
          Subject: booking.service?.name,
          ServiceID: booking.service?.id,
          StartTime: new Date(booking.starts_at),
          EndTime: new Date(booking.ends_at),
          LocationID: booking.location?.id,
          Location: booking.metadata?.address,
          IsAllDay: false,
          CategoryColor: '#1aaa55', // Example color
          Price: booking.price || null, // Map the custom field
          CustomerName: booking.metadata?.customer_name || '',
          CustomerEmail: booking.metadata?.customer_email || '',
          CustomerContact: booking.metadata?.customer_mobile || '',
        }));
        console.log(' fetched data', fetchedData);
        setData(fetchedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // Hide the spinner after the data fetch is complete
        scheduleObj.current.hideSpinner();
        setLoading(false)
      }
    };

    fetchData();
  }, []);

  const onEventRendered = (args) => {
    let categoryColor = args.data.CategoryColor;
    if (!args.element || !categoryColor) {
      return;
    }
    if (scheduleObj.current.currentView === 'Agenda') {
      args.element.firstChild.style.borderLeftColor = categoryColor;
    } else {
      args.element.style.backgroundColor = categoryColor;
    }
  };

  function formatPrice(price) {
    if (price == null || isNaN(price)) {
      return null; // If the price is not a valid number, return null
    }

    // Convert the price to a fixed 3 decimal places and ensure it's a string
    let formattedPrice = parseFloat(price).toFixed(3);

    // Ensure that the formatted price matches the pattern [0-9]{1,12}\.[0-9]{3}
    const pricePattern = /^[0-9]{1,12}\.[0-9]{3}$/;

    // If it matches, return the formatted price; otherwise, return null
    return pricePattern.test(formattedPrice) ? formattedPrice : null;
  }

  const handleActionComplete = async (args) => {
    if (args.requestType === 'viewNavigate' || args.requestType === 'dateNavigate') {
      // Get the visible start and end dates
      const startDate = scheduleObj.current.getCurrentViewDates()[0];
      const endDate = scheduleObj.current.getCurrentViewDates().slice(-1)[0];

      const from = formatDateWithTimezone(startDate);
      const to = formatDateWithTimezone(endDate);

      try {
        setLoading(true)
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/bookings`, {
          params: { from, to },

          headers: {
            Authorization: token,

          }
        },
        );

        const fetchedData = response.data.message.data.map(booking => ({
          Id: booking.id,
          Subject: booking.service.name,
          ServiceID: booking.service.id,
          StartTime: new Date(booking.starts_at),
          EndTime: new Date(booking.ends_at),
          LocationID: booking.location.id,
          Location: booking.metadata?.address,
          IsAllDay: false,
          CategoryColor: '#1aaa55', // Example color
          Price: booking.price || null, // Map the custom field
          CustomerName: booking.metadata?.customer_name || '',
          CustomerEmail: booking.metadata?.customer_email || '',
          CustomerContact: booking.metadata?.customer_mobile || ''
        }));

        setData(fetchedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // Hide the spinner after the data fetch is complete
        scheduleObj.current.hideSpinner();
        setLoading(false)
      }
    }
  };



  const handleActionBegin = async (args) => {
    const { requestType, event, data, changedRecords } = args;
    console.log('requestType ', requestType);
    console.log('event ', event);
    console.log('data ', data);
    console.log('changedRecords ', changedRecords);

    if (requestType === 'eventCreate') {
      args.cancel = true; // Cancel the event creation

      const event = data[0]; // The newly created event
      try {
        const startsAt = formatDateWithTimezone(event.StartTime);
        const endsAt = formatDateWithTimezone(event.EndTime);
        console.log('event123', event)
        // Extract the custom field values
        const price = formatPrice(event.Price);
        const customerName = event.CustomerName;
        const customerContact = event.CustomerContact;
        const customerEmail = event.CustomerEmail;
        const Location = event.Location;
        const serv = event.Service;
        setLoading(true)
        const token = localStorage.getItem('access_token');
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/bookings`, {
          service_id: serv, // Map your data accordingly
          starts_at: startsAt,
          ends_at: endsAt,
          location_id: location,
          price, // Include custom field
          metadata: {
            customer_name: customerName, // Include custom field
            customer_mobile: customerContact, // Include custom field
            customer_email: customerEmail,
            address: Location,
          }
        },
          {  // Include custom field
            headers: {
              Authorization: token,
            }
          },
        );

        if (response.data.success) {
          const newEvent = {
            Id: response.data.message.id,
            Subject: response.data.message.service.name,
            ServiceID: response.data.message.service.id,
            StartTime: new Date(response.data.message.starts_at),
            EndTime: new Date(response.data.message.ends_at),
            LocationID: response.data.message.location.id,
            Location: Location,
            IsAllDay: false,
            CategoryColor: '#1aaa55', // Example color
            Price: price, // Include custom field
            CustomerName: customerName, // Update custom field
            CustomerContact: customerContact, // Update custom field
            CustomerEmail: customerEmail, // Update custom field// Include custom field
          };
          setData(prevData => [...prevData, newEvent]);
        } else {
          console.error('Unexpected response status:', response.status);
        }
      } catch (error) {
        console.error('Error creating event:', error);
        // Optionally, show an error message to the user
      }
      finally {
        setLoading(false)

      }
      return; // Early return
    }

    if (requestType === 'eventChange') {
      args.cancel = true; // Cancel the event creation

      const event = data;
      try {
        const startsAt = formatDateWithTimezone(event.StartTime);
        const endsAt = formatDateWithTimezone(event.EndTime);

        // Extract the custom field values
        const price = formatPrice(event.Price);
        const customerName = event.CustomerName;
        const customerContact = event.CustomerContact;
        const customerEmail = event.CustomerEmail;
        const location = event.Location
        setLoading(true)
        const token = localStorage.getItem('access_token');
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/bookings/${event.Id}`, {
          service_id: event.ServiceID, // Map your data accordingly
          starts_at: startsAt,
          ends_at: endsAt,
          location_id: event.LocationID,
          price, // Include custom field
          metadata: {
            customer_name: customerName, // Include custom field
            customer_mobile: customerContact, // Include custom field
            customer_email: customerEmail,
            address: location
          }
        }, // Include custom field
          {
            headers: {
              Authorization: token,
            },
          }
        );

        // Check if the response status is 200 before proceeding
        if (response.data.success) {
          setData(prevData => prevData.map(item =>
            item.Id === event.Id ?
              {
                ...item,
                StartTime: new Date(response.data.message.starts_at),
                EndTime: new Date(response.data.message.ends_at),
                Price: price, // Update custom field
                CustomerName: customerName, // Update custom field
                CustomerContact: customerContact, // Update custom field
                CustomerEmail: customerEmail,
                Location: location// Update custom field
              } : item
          ));
          // Optionally update state after successful update
        } else {
          console.error('Unexpected response status:', response.status);
        }
      } catch (error) {
        console.error('Error updating event:', error);
        // Optionally, show an error message to the user
      }
      finally {
        setLoading(false)

      }
      return; // Early return
    }

    if (requestType === 'eventRemove') {
      args.cancel = true; // Cancel the event creation

      const event = data[0];
      try {
        setLoading(true)
        const token = localStorage.getItem('access_token');
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/bookings/${event.Id}`, {
          headers: {
            Authorization: token,
          }
        },);

        // Check if the response status is 200 before removing the event from state
        if (response.data.success) {
          setData(prevData => prevData.filter(item => item.Id !== event.Id));
        } else {
          console.error('Unexpected response status:', response.status);
        }
      } catch (error) {
        console.error('Error deleting event:', error);
        // Optionally, show an error message to the user
      }
      finally {
        setLoading(false)

      }
      return; // Early return
    }
  };


  // Utility function to format date with timezone offset
  function formatDateWithTimezone(date) {
    const pad = (num) => num < 10 ? '0' + num : num;

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    // Get timezone offset in +HH:MM format
    const timezoneOffset = -date.getTimezoneOffset();
    const offsetSign = timezoneOffset >= 0 ? '+' : '-';
    const offsetHours = pad(Math.floor(Math.abs(timezoneOffset) / 60));
    const offsetMinutes = pad(Math.abs(timezoneOffset) % 60);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offsetSign}${offsetHours}:${offsetMinutes}`;
  }



  return (
    <div className='schedule-control-section'>
      {loading === true && (<Spinner />)}
      <div className='col-lg-12 control-section'>
        <div className='control-wrapper'>
          <ScheduleComponent
            popupOpen={onPopupOpen}
            locale="en-AU"
            width='100%'
            height='650px'
            ref={scheduleObj}
            eventSettings={{ dataSource: data }}
            eventRendered={onEventRendered}
            actionBegin={handleActionBegin} // Bind the actionBegin handler
          //actionComplete={handleActionComplete}
          >
            <Inject services={[Day, Week, WorkWeek, Month, Agenda, Resize, DragAndDrop, TimelineViews]} />
          </ScheduleComponent>
        </div>
      </div>
    </div>
  );
};

export default Scheduler;
