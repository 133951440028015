// FixedMenu.js
import React from 'react';
import { IconButton, MenuItem, FormControl, InputLabel, Select, Tab, Tabs, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MessageIcon from '@mui/icons-material/QuestionAnswer';
import ContactIcon from '@mui/icons-material/Contacts';
import TestIcon from '@mui/icons-material/EmojiObjects';
import GeneralIcon from '@mui/icons-material/Assessment';
import ChannelsIcon from '@mui/icons-material/Share';
import LayoutIcon from '@mui/icons-material/Preview';
import AdvancedSettingsIcon from '@mui/icons-material/Tune';
import AccountIcon from '@mui/icons-material/AccountCircle';
import Groups from '@mui/icons-material/Groups';
import Paid from '@mui/icons-material/Paid';
import Payment from '@mui/icons-material/Payment';
import Logout from '@mui/icons-material/Logout';
import Help from '@mui/icons-material/HelpOutline'
import Logo from '../images/logolight6.webp'; // Adjust the path as needed
import CalendarMonth from '@mui/icons-material/CalendarMonth'
const FixedMenu = ({
  chatbots,
  selectedChatbot,
  handleChatbotChange,
  handleNewChatbot,
  handleTabChange,
  activeTab,
  chatbotDetails

}) => {
  return (
    <div className="fixed-menu">
      <div className="nav-brand" style={{ backgroundColor: "#092F36" }}>
        <img src={Logo} alt="AI Assistant" className="nav-image" id="nav-image" />
      </div>
      <FormControl id="select-chatbot" sx={{ width: "100%" }}>
        <InputLabel id="select-chatbot-label">Select Chatbot</InputLabel>
        <Select
          labelId="select-chatbot-label"
          value={selectedChatbot}
          label="Select Chatbot"
          onChange={(event) => {
            if (event.target.value === "new") {
              handleNewChatbot(); // Trigger the new chatbot creation
            } else {
              handleChatbotChange(event); // Handle chatbot selection
            }
          }}
        >
          <MenuItem value="new">
            <IconButton aria-label="add new chatbot" size="small">
              <AddIcon fontSize="inherit" />
            </IconButton>
            Add New AI assistant
          </MenuItem>
          {Array.isArray(chatbots) && chatbots.map((chatbot) => (
            <MenuItem key={chatbot.id} value={chatbot.id}>
              {chatbot.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={activeTab}
        onChange={handleTabChange}
        aria-label="main tabs"
        sx={{ borderRight: 1, borderColor: 'divider', width: '100%', backgroundColor: 'white', minWidth: 'unset' }}
      >
        <Divider variant="middle" sx={{ marginBottom: '1rem', marginTop: '1rem', color: "rgba(0, 0, 0, 0.5)", fontWeight: "600" }}>
          <b> AI Assistant Settings</b>
        </Divider>

        <Tab
          icon={<LibraryBooksIcon fontSize="small" sx={{ marginLeft: '15px' }} />}
          value="knowledgeBase"
          label="Knowledge Base"
          id="knowledge-base-tab"
          iconPosition="start"
          sx={{
            minHeight: '45px',
            textTransform: 'none',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '10px'
          }}
        />
        <Tab
          icon={<LayoutIcon fontSize="small" sx={{ marginLeft: '15px' }} />}
          value="chatLayout"
          label="Chat Layout"
          id="chat-layout-tab"
          iconPosition="start"
          sx={{
            minHeight: '45px',
            textTransform: 'none',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '10px'
          }}
        />


        <Tab
          icon={<ContactIcon fontSize="small" sx={{ marginLeft: '15px' }} />}
          value="leadsTab"
          label="Leads"
          id="leads-tab"
          iconPosition="start"
          sx={{
            minHeight: '45px',
            textTransform: 'none',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '10px'
          }}
        />
        <Tab
          icon={<AdvancedSettingsIcon fontSize="small" sx={{ marginLeft: '15px' }} />}
          value="model"
          label="Advanced Settings"
          id="advanced-settings-tab"
          iconPosition="start"
          sx={{
            minHeight: '45px',
            textTransform: 'none',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '10px'
          }}
        />
        <Tab
          icon={<TestIcon fontSize="small" sx={{ marginLeft: '15px' }} />}
          value="testChatbot"
          label="Test your Chatbot"
          id="test-chatbot-tab"
          iconPosition="start"
          sx={{
            minHeight: '45px',
            textTransform: 'none',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '10px'
          }}
        />

        <Tab
          icon={<ChannelsIcon fontSize="small" sx={{ marginLeft: '15px' }} />}
          value="channels"
          label="Add to Website/Social Media"
          id="connect-tab"
          iconPosition="start"
          sx={{
            minHeight: '45px',
            textTransform: 'none',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '10px'
          }}
        />
        <Tab
          icon={<GeneralIcon fontSize="small" sx={{ marginLeft: '15px' }} />}
          value="general"
          label="Dashboard"
          id="dashboard-tab"
          iconPosition="start"
          sx={{
            minHeight: '45px',
            textTransform: 'none',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '10px'
          }}
        />
        <Tab
          icon={<MessageIcon fontSize="small" sx={{ marginLeft: '15px' }} />}
          value="messages"
          label="Conversations"
          id="conversations-tab"
          iconPosition="start"
          sx={{
            minHeight: '45px',
            textTransform: 'none',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '10px'
          }}
        />
        {chatbotDetails.hapio && (
          [
            <Divider variant='middle' sx={{ marginBottom: '1rem', marginTop: '1rem', color: "rgba(0, 0, 0, 0.5)", fontWeight: "600" }}>
              <b> Booking Settings </b>
            </Divider>,

            <Tab
              icon={<Paid fontSize="small" sx={{ marginLeft: '15px' }} />}
              value="quotesTab"
              label="Quote & Booking Settings"
              id="quotesTab"
              iconPosition="start"
              sx={{
                minHeight: '45px',
                textTransform: 'none',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '10px'
              }}
            />,
            <Tab
              icon={<CalendarMonth fontSize="small" sx={{ marginLeft: '15px' }} />}
              value="bookingsTab"
              label="My Bookings"
              id="bookingsTab"
              iconPosition="start"
              sx={{
                minHeight: '45px',
                textTransform: 'none',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '10px'
              }}
            />,
            <Tab
              icon={<TestIcon fontSize="small" sx={{ marginLeft: '15px' }} />}
              value="testBooking"
              label="Test your Booking"
              id="test-booking-tab"
              iconPosition="start"
              sx={{
                minHeight: '45px',
                textTransform: 'none',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '10px'
              }}
            />,
            <Tab
              icon={<ChannelsIcon fontSize="small" sx={{ marginLeft: '15px' }} />}
              value="channelsBooking"
              label="Add to Website"
              id="connect-tab"
              iconPosition="start"
              sx={{
                minHeight: '45px',
                textTransform: 'none',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '10px'
              }}
            />
          ])}
        <Divider variant='middle' sx={{ marginBottom: '1rem', marginTop: '1rem', color: "rgba(0, 0, 0, 0.5)", fontWeight: "600" }}>
          <b> Account Settings </b>
        </Divider>
        <Tab
          icon={<AccountIcon fontSize="small" sx={{ marginLeft: '15px' }} />}
          value="profile"
          label="Profile"
          id="profile"
          iconPosition="start"
          sx={{
            minHeight: '45px',
            textTransform: 'none',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '10px'
          }}
        />
        <Tab
          icon={<Groups fontSize="small" sx={{ marginLeft: '15px' }} />}
          value="myTeam"
          label="My Team"
          id="myTeam"
          iconPosition="start"
          sx={{
            minHeight: '45px',
            textTransform: 'none',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '10px'
          }}
        />
        <Tab
          icon={<Payment fontSize="small" sx={{ marginLeft: '15px' }} />}
          value="subscription"
          label="Manage Subscription"
          id="subscription"
          iconPosition="start"
          sx={{
            minHeight: '45px',
            textTransform: 'none',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '10px'
          }}
        />
        <Tab
          icon={<Help fontSize="small" sx={{ marginLeft: '15px' }} />}
          value="support"
          label="Support"
          id="support"
          iconPosition="start"
          sx={{
            minHeight: '45px',
            textTransform: 'none',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '10px'
          }}
        />
        <Tab
          icon={<Logout fontSize="small" sx={{ marginLeft: '15px' }} />}
          value="logout"
          label="Logout"
          id="logout"
          iconPosition="start"
          sx={{
            minHeight: '45px',
            textTransform: 'none',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '10px'
          }}
        />



      </Tabs>


    </div >
  );
};

export default FixedMenu;
