import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import BookingComponent from './BookingComponent';
const TestBookingTab = ({details}) => {
  const { id } = useParams();
  console.log ('details',details);
  useEffect(() => {
    window.embeddedBookingConfig = {
      accountId: details.account_id,
      locationId: details.location_id,
      themeBackgroundColor: details.style.ThemeBackgroundColor
    };

    // Function to dynamically load the script
    const loadScript = (src, onLoad) => {
      const script = document.createElement('script');
      script.src = src;
      script.defer = true;
      script.onload = onLoad;
     // document.body.appendChild(script);
    };

    // Ensure the script runs after the DOM is ready
    const checkAndLoadScript = () => {
      const container = document.getElementById('aissie-booking');
      if (container) {
        // Load the booking widget bundle
        loadScript('/booking-bundle.js', () => {
          console.log("Booking widget bundle loaded");
          if (!container._reactRootContainer) {
            const root = ReactDOM.createRoot(container);
            // Add your React rendering logic here, e.g.,
            // root.render(<BookingComponent />);
            container._reactRootContainer = root;
          } else {
            // Add your React rendering logic here, e.g.,
             container._reactRootContainer.render(<BookingComponent />);
          }
        });
      } else {
        // Retry after a short delay if the container is not found
        setTimeout(checkAndLoadScript, 1000);
      }
    };

    checkAndLoadScript();
  }, [details]);

  return <div id="aissie-booking" style={{fontFamily:'Poppins'}}>
  <BookingComponent accId={details.account_id} locId={details.location_id} themeBgColor={details.style.ThemeBackgroundColor}/>
  </div>;
};

export default TestBookingTab;
