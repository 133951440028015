import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Bot from '../images/Bot.webp';
import User from '../images/user.webp';
import Instagram from '../images/instagram.svg';
import Messenger from '../images/messenger.svg';
import Web from '../images/web.webp';
import { styled } from '@mui/material';

dayjs.extend(utc);
dayjs.extend(timezone);
const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const SourceIcon = styled('img')({
  position: 'absolute',
  bottom: 0,
  right: 0,
  width: '1rem',
  height: '1rem',
  borderRadius: '10rem',
});

const AvatarContainer = styled('div')({
  position: 'relative',
  display: 'inline-block',
  objectFit: 'unset'
});

const ConversationList = ({ groupedMessages, onConversationClick, selectedConversation }) => {
  const [page, setPage] = useState(1);
  const conversationsPerPage = 10;

  const handleConversationClick = (userId) => {
    onConversationClick(userId);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getSourceIcon = (source) => {
    switch (source) {
      case 'instagram':
        return Instagram;
      case 'facebook':
        return Messenger;
      default:
        return Web;
    }
  };

  // Flatten the conversations and sort by the newest message
  const sortedConversations = Object.keys(groupedMessages)
    .map((userId) => ({
      userId,
      ...groupedMessages[userId],
      lastMessage: groupedMessages[userId].messages[groupedMessages[userId].messages.length - 1],
    }))
    .sort((a, b) => new Date(b.lastMessage.created) - new Date(a.lastMessage.created));

  // Paginate conversations
  const paginatedConversations = sortedConversations.slice(
    (page - 1) * conversationsPerPage,
    page * conversationsPerPage
  );
  const FlexContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  });
  return (
    <>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {paginatedConversations.map(({ userId, channelName, lastMessage }) => {
          const lastMessageContent = lastMessage.content;
          const lastMessageRole = lastMessage.role;
          const sourceIcon = getSourceIcon(lastMessage.source);
          const isSelected = userId === selectedConversation;

          return (
            <React.Fragment key={userId}>
              <ListItemButton
                alignItems="flex-start"
                onClick={() => handleConversationClick(userId)}
                sx={{ backgroundColor: isSelected ? '#e0e0e0' : 'inherit' }} // Change background color if selected
              >
                <ListItemAvatar>
                  <AvatarContainer>
                    <Avatar
                      slotProps={{ img: { style: { objectFit: "unset" } } }}
                      alt={lastMessageRole === 'assistant' ? 'Assistant' : 'User'}
                      src={lastMessageRole === 'assistant' ? Bot : User}
                    />
                    <SourceIcon src={sourceIcon} alt="Source Icon" />
                  </AvatarContainer>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <FlexContainer>
                      <span>{`${capitalizeFirstLetter(channelName)} User`}</span>
                      <span style= {{color:"rgba(0, 0, 0, 0.5)"}}>{dayjs.utc(lastMessage.created).tz(userTimezone).format('DD MMM h:mm A')}</span>
                    </FlexContainer>
                  }
                  secondary={`${lastMessageContent.slice(0, 100)}...`}
                />
              </ListItemButton>
              <Divider component="li" />
            </React.Fragment>
          );
        })}
      </List>
      <Stack spacing={2} sx={{ marginTop: 2 }}>
        <Pagination
          count={Math.ceil(sortedConversations.length / conversationsPerPage)}
          page={page}
          onChange={handlePageChange}
        />
      </Stack>
    </>
  );
};

export default ConversationList;
