import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Signup.css';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import axios from 'axios';
import Navigation from '../Navigation';
import {
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { isEmail } from 'validator'; // Importing only isEmail from validator.js

const passwordRequirements = [
  {
    label: 'At least 8 characters',
    test: (password) => password.length >= 8,
  },
  {
    label: 'At least one uppercase letter',
    test: (password) => /[A-Z]/.test(password),
  },
  {
    label: 'At least one lowercase letter',
    test: (password) => /[a-z]/.test(password),
  },
  {
    label: 'At least one number',
    test: (password) => /[0-9]/.test(password),
  },
  {
    label: 'At least one special character',
    test: (password) => /[\W_]/.test(password),
  },
];

const SignupPage = () => {
  const [username, setUsername] = useState('');
  const [firstName, setUserFirstName] = useState('');
  const [lastName, setUserLastName] = useState('');
  const [companyName, setAccountName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Toggle password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Toggle confirm password visibility
  const [error, setError] = useState(null); // Handle signup errors
  const [fieldErrors, setFieldErrors] = useState({}); // Handle individual field errors
  const navigate = useNavigate(); // For navigation after signup
  const location = useLocation(); // Get current URL

  // Extract subscription parameter from URL
  const searchParams = new URLSearchParams(location.search);
  const subscriptionType = searchParams.get('subscription') || 'Trial';

  const handleSignup = async () => {
    setError(null); // Clear previous errors
    const errors = {};

    // Check for empty fields
    if (!firstName.trim()) {
      errors.firstName = 'First name is required';
    }
    if (!lastName.trim()) {
      errors.lastName = 'Last name is required';
    }
    if (!companyName.trim()) {
      errors.companyName = 'Organisation name is required';
    }
    if (!username.trim()) {
      errors.username = 'Email is required';
    } else if (!isEmail(username)) {
      errors.username = 'Please enter a valid email address';
    }
    if (!password) {
      errors.password = 'Password is required';
    }
    if (!confirmPassword) {
      errors.confirmPassword = 'Confirm password is required';
    }

    // If there are any errors, set them and abort signup
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      setError('Please fill in all required fields.');
      return;
    } else {
      setFieldErrors({});
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    // Validate password strength
    const passwordValid = passwordRequirements.every((req) => req.test(password));
    if (!passwordValid) {
      setError(
        'Password must be at least 8 characters long and include uppercase, lowercase, number, and special character'
      );
      return;
    }

    try {
      const signupPromise = axios.post(`${process.env.REACT_APP_API_URL}/register`, {
        username,
        firstName,
        lastName,
        companyName,
        password,
        subscriptionType,
      });

      toast.promise(
        signupPromise,
        {
          pending: 'Registration in progress...',
          success: 'Registration complete! Please sign in.',
          error: 'Failed registering',
        },
        { position: 'top-center' }
      );
      const response = await signupPromise;

      if (response.data.success) {
        Cookies.set('aissie_new_user', 'true', { secure: true, sameSite: 'strict' });
        if (response.data.access_token) {
          localStorage.setItem('access_token', response.data.access_token);
        } else {
          setError('Access token not received from the server');
        }

        const { user_id } = response.data;

        if (subscriptionType.toLowerCase() === 'starter') {
          window.location.href = `https://buy.stripe.com/fZe6py47j6ws0h2dQQ?prefilled_email=${username}&client_reference_id=${user_id}`; //prod
        } else if (subscriptionType.toLowerCase() === 'business') {
          window.location.href = `https://buy.stripe.com/8wM6py47j5so6FqbIJ?prefilled_email=${username}&client_reference_id=${user_id}`;
        } else {
          navigate('/login');
        }
      } else {
        if (response.data.message === "Account already exists") {
          setError("An account with this organisation already exists.");
        }
        else if (response.data.message === "Username already exists") {
          setError("An account with this email already exists. If you've forgotten your password, please reset it or log in instead.");
        }
        else {
          console.log(' test', response.data.message);
          setError("Something went wrong. Please try again in a few moments. If the problem persists, contact support for assistance."); // Handle signup error message
        }
      }
    } catch (err) {
      setError("Something went wrong. Please try again in a few moments. If the problem persists, contact support for assistance."); // Handle signup error message
    }
  };

  // Optional: Disable the signup button if any required field is empty
  const isSignupDisabled = !(
    firstName.trim() &&
    lastName.trim() &&
    companyName.trim() &&
    isEmail(username) &&
    password &&
    confirmPassword &&
    password === confirmPassword &&
    passwordRequirements.every((req) => req.test(password))
  );

  return (
    <div className="signup-container">
      <Navigation />
      <h1 className="signup-heading">Sign Up</h1>
      <Typography variant="body2" className="signup-link" sx={{ marginBottom: '10px' }}>
        Already have an account?{' '}
        <a
          href="/login"
          style={{
            color: '#1976d2',
            textDecoration: 'underline',
            cursor: 'pointer',
            marginBottom: '10px',
          }}
        >
          Login
        </a>
      </Typography>
      <div className="signup-form">
        <div className="signup-input-group">
          <TextField
            InputLabelProps={{ shrink: true }}
            label="First Name"
            variant="outlined"
            fullWidth
            value={firstName}
            onChange={(e) => setUserFirstName(e.target.value)}
            error={!!fieldErrors.firstName}
            helperText={fieldErrors.firstName || ''}
            required
          />
        </div>
        <div className="signup-input-group">
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Last Name"
            variant="outlined"
            fullWidth
            value={lastName}
            onChange={(e) => setUserLastName(e.target.value)}
            error={!!fieldErrors.lastName}
            helperText={fieldErrors.lastName || ''}
            required
          />
        </div>
        <div className="signup-input-group">
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Organisation Name"
            variant="outlined"
            fullWidth
            value={companyName}
            onChange={(e) => setAccountName(e.target.value)}
            error={!!fieldErrors.companyName}
            helperText={fieldErrors.companyName || ''}
            required
          />
        </div>
        <div className="signup-input-group">
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Email"
            variant="outlined"
            fullWidth
            type="email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={!!fieldErrors.username || (username.length > 0 && !isEmail(username))}
            helperText={
              fieldErrors.username ||
              (username.length > 0 && !isEmail(username) ? 'Enter a valid email address' : '')
            }
            required
          />
        </div>
        <div className="signup-input-group password-group">
          <TextField
            InputLabelProps={{ shrink: true }}
            type={showPassword ? 'text' : 'password'}
            label="Password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={
              !!fieldErrors.password ||
              (password.length > 0 &&
                !passwordRequirements.every((req) => req.test(password)))
            }
            helperText={
              fieldErrors.password ||
              (password.length > 0 &&
                !passwordRequirements.every((req) => req.test(password))
                ? 'Please meet all password requirements.'
                : '')
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            required
          />
          {/* Password Requirements List */}
          <List dense>
            {passwordRequirements.map((req, index) => {
              const isValid = req.test(password);
              return (
                <ListItem key={index}>
                  <ListItemIcon>
                    {isValid ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <CancelIcon color="error" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={req.label}
                    primaryTypographyProps={{
                      style: { color: isValid ? 'green' : 'red' },
                    }}
                  />
                </ListItem>
              );
            })}
          </List>
        </div>
        <div className="signup-input-group password-group">
          <TextField
            InputLabelProps={{ shrink: true }}
            type={showConfirmPassword ? 'text' : 'password'}
            label="Confirm Password"
            variant="outlined"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={
              !!fieldErrors.confirmPassword ||
              (confirmPassword.length > 0 && confirmPassword !== password)
            }
            helperText={
              fieldErrors.confirmPassword ||
              (confirmPassword.length > 0 && confirmPassword !== password
                ? 'Passwords do not match'
                : '')
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            required
          />
          {/* Confirm Password Matching Indicator */}
          {confirmPassword.length > 0 && (
            <List dense>
              <ListItem>
                <ListItemIcon>
                  {confirmPassword === password ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <CancelIcon color="error" />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Passwords match"
                  primaryTypographyProps={{
                    style: {
                      color: confirmPassword === password ? 'green' : 'red',
                    },
                  }}
                />
              </ListItem>
            </List>
          )}
        </div>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSignup}
          disabled={isSignupDisabled}
        >
          Sign Up
        </Button>
      </div>

      {error && !/^Please enter a valid email address$/.test(error) && (
        <Typography variant="body2" color="error" sx={{ marginTop: '10px' }}>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default SignupPage;
