import React, { useState, useEffect, useMemo } from 'react'; // Added useMemo
import {
    Tabs,
    Tab,
    Typography,
    Box,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    useMediaQuery,
    Checkbox,
    FormControlLabel
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2

import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import Clear from '@mui/icons-material/SearchOff';
import { TableVirtuoso } from 'react-virtuoso';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import axios from 'axios';
import '../css/KnowledgeBaseTab.css';
import FileUpload from './FileUpload';
import { toast } from 'react-toastify';

import TrainChatbot from './TrainChatbot';

const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
        <TableContainer component={Paper} sx={{
            // Custom properties for Paper
            elevation: 1,
            variant: "outlined",
            square: false,
            height: '100%',
            // Add any additional customization
        }}  {...props} ref={ref} className="table-container" />
    )),
    Table: (props) => (
        <Table {...props} className="table" sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
    TableRow,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

const KnowledgeBaseTab = ({ onSubtabChange }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile screens

    const [searchQuery, setSearchQuery] = useState('');
    const [urlSearchInput, setUrlSearchInput] = useState(''); // New state for URL search input
    const [urlSearchQuery, setUrlSearchQuery] = useState(''); // New state for URL search query
    const [error, setError] = useState('');
    const { id } = useParams();
    const [activeSubtab, setActiveSubtab] = useState('upload');
    const details = localStorage.getItem('aissie-kb-details-' + id)
        ? JSON.parse(localStorage.getItem('aissie-kb-details-' + id))
        : [];
    const chatbotDetails = localStorage.getItem('aissie-chatbot-details-' + id)
        ? JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id))
        : {};
    const [allDetails, setAllDetails] = useState(details);
    const [allDetailsTable, setAllDetailsTable] = useState(allDetails);
    const [chatStatus, setChatStatus] = useState(chatbotDetails.status || '');
    const [loading, setLoading] = React.useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [editQuestion, setEditQuestion] = useState('');
    const [editAnswer, setEditAnswer] = useState('');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [onConfirm, setOnConfirm] = useState(() => () => { });
    const [faqDialogOpen, setFaqDialogOpen] = useState(false);
    const [newQuestion, setNewQuestion] = useState('');
    const [newAnswer, setNewAnswer] = useState('');
    // State variables for managing the edit dialog
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [totalNumOfChars, setTotalNumOfChars] = useState(0);
    const [isModified, setIsModified] = useState(false); // New flag for tracking modifications

    // New state variables for website mapping and selection
    const [mappedLinks, setMappedLinks] = useState([]);
    const [selectedLinks, setSelectedLinks] = useState([]);
    const [isMapping, setIsMapping] = useState(false);
    const [isFetchingContents, setIsFetchingContents] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    // State for website URL input (Controlled Component)
    const [websiteUrl, setWebsiteUrl] = useState('');

    // Function to save state to local storage
    const saveStateToLocalStorage = () => {
        console.log('save state function called');
        localStorage.setItem('aissie-kb-details-' + id, JSON.stringify(allDetails));

    };
    const saveChatStatusToLocalStorage = () => {
        console.log('save chat status function called');

        // Retrieve the existing state from local storage
        const existingState = JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id)) || {};

        // Define the new state
        const newState = {
            status: chatStatus,
        }

        const updatedState = {
            ...existingState,
            ...newState,
        };
        localStorage.setItem('aissie-chatbot-details-' + id, JSON.stringify(updatedState));
    };

    useEffect(() => {
        if ((JSON.stringify(details) !== JSON.stringify(allDetails)) && !isModified) {
            setAllDetails(details);
        }
    }, [details]);

    useEffect(() => {
        if ((JSON.stringify(chatbotDetails.status) !== JSON.stringify(chatStatus)) && !isModified) {
            setChatStatus(chatbotDetails.status);
        }
    }, [chatbotDetails]);

    useEffect(() => {
        if (isModified) {
            saveStateToLocalStorage();
        }
    }, [
        allDetails, // Depend on isModified to save only when modified
    ]);
    useEffect(() => {
        if (isModified) {
            saveChatStatusToLocalStorage();
        }
    }, [
        chatStatus, // Depend on isModified to save only when modified
    ]);
    // State for sorting
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const handleSelectRow = (id) => {
        setSelectedRows((prevSelected) =>
            prevSelected.includes(id) ? prevSelected.filter((rowId) => rowId !== id) : [...prevSelected, id]
        );
    };

    const isSelected = (id) => selectedRows.includes(id);

    useEffect(() => {
        setAllDetailsTable(allDetails);
        const validDetails = Array.isArray(allDetails) ? allDetails : [];
        setTotalNumOfChars(validDetails.reduce((total, detail) => total + (detail.num_of_chars || 0), 0));
    }, [allDetails]);


    // Function to open the edit dialog
    const handleOpenEditDialog = (source_id, question, answer) => {
        console.log('id:', source_id, 'question', question, 'answer', answer)
        setEditIndex(source_id);
        setEditQuestion(question);
        setEditAnswer(answer);
        setEditDialogOpen(true);
    };

    // Function to close the edit dialog
    const handleCloseEditDialog = () => {
        setEditIndex(null);
        setEditQuestion('');
        setEditAnswer('');
        setEditDialogOpen(false);
    };

    const openConfirmDialog = (message, onConfirmAction) => {
        setDialogMessage(message);
        setOnConfirm(() => onConfirmAction);
        setConfirmDialogOpen(true);
    };

    const openFaqDialog = () => {
        setNewQuestion('');
        setNewAnswer('');
        setFaqDialogOpen(true);
    };

    const closeFaqDialog = () => {
        setFaqDialogOpen(false);
    };

    const handleSaveFaq = async () => {
        if (newQuestion.trim() === '' || newAnswer.trim() === '') {
            setError('Please insert the Question and Answer.');
            return;
        }
        setLoading(true);

        setError('');

        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                axios.post(`${process.env.REACT_APP_API_URL}/api/sources`,
                    {
                        question: newQuestion,
                        answer: newAnswer,
                        type: 'qa',
                        chatbot_id: id
                    },
                    {
                        headers: {
                            Authorization: token,
                        },
                    }
                ).then(response => {
                    const data = response.data;
                    const savedQa = {
                        question: newQuestion,
                        answer: newAnswer,
                        source_id: data.source_id,
                        num_of_chars: data.num_of_chars,
                    };

                    setAllDetails([...allDetails, { type: 'qa', question: newQuestion, answer: newAnswer, id: data.source_id, num_of_chars: data.num_of_chars , status: "Pending"}]);
                    setAllDetailsTable([...allDetails, { type: 'qa', question: newQuestion, answer: newAnswer, id: data.source_id, num_of_chars: data.num_of_chars , status: "Pending"}]);
                    setIsModified(true);
                    setChatStatus('Update Required');
                    closeFaqDialog();
                    setLoading(false);

                }),
                {
                    pending: 'Saving FAQ...',
                    success: 'FAQ saved successfully!',
                    error: 'Error saving FAQ',
                },
                {
                    position: "top-center"
                }
            );
        } catch (error) {
            console.error('Error saving FAQ:', error);
            setError('Error saving FAQ.');
        }
    };

    // New function to handle mapping websites
    const handleMapWebsites = async (url) => {
        if (!url) {
            toast.error('Please enter a valid URL.');
            return;
        }
        setIsMapping(true);
        setMappedLinks([]);
        setSelectedLinks([]);
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/map_websites`, {
                params: { url },
                headers: {
                    Authorization: token,
                },
            });
            const data = response.data;
            if (data.success) {
                setMappedLinks(data.links);
                if (data.links.length === 0) {
                    toast.info('No links found for the provided URL.');
                } else {
                    toast.success('Websites mapped successfully!');
                }
            } else {
                toast.error('Failed to map websites.');
            }
        } catch (error) {
            console.error('Error mapping websites:', error);
            toast.error('Error mapping websites.');
        } finally {
            setIsMapping(false);
        }
    };

    const handleFetchWebsitesContents = async () => {
        if (selectedLinks.length === 0) {
            toast.error('Please select at least one website to fetch contents.');
            return;
        }
        setIsFetchingContents(true);
        setButtonsDisabled(true);
        try {
            const token = localStorage.getItem('access_token');
            const payload = {
                chatbot_id: id,
                urls: selectedLinks,
                type: 'url'
            };
            const response = await toast.promise(
                axios.post(`${process.env.REACT_APP_API_URL}/api/fetch_websites_content`, payload, {
                    headers: {
                        Authorization: token,
                    },
                }),
                {
                    pending: 'Fetching website contents...',
                    success: 'Website contents fetched successfully!',
                    error: 'Error fetching website contents.'
                },
                {
                    position: "top-center"
                }
            );
    
            // Extract the data from the response
            const data = response.data;
            const fetchedURLs = data.fetchedURLs;
    
            // Copy the current details and update with the new URLs
            let updatedAllDetails = [...allDetails];
            fetchedURLs.forEach(item => {
                updatedAllDetails.push({
                    type: 'url',
                    url: item.url,
                    id: item.source_id,
                    num_of_chars: item.num_of_chars
                });
            });
    
            // Update the state with the new details and set flags
            setAllDetails(updatedAllDetails);
            setIsModified(true);
            setChatStatus('Update Required');
    
            // Actions to run only if the endpoint call was successful
            setIsFetchingContents(false);
            setMappedLinks('');
            setButtonsDisabled(false);
    
        } catch (error) {
            console.error('Error fetching website contents:', error);
            toast.error('Error fetching website contents.');
    
            // Optionally, you might want to reset some states even on error
            setIsFetchingContents(false);
            setButtonsDisabled(false);
        }
    };
    

    const saveEditedQa = async () => {
        console.log('edit index', editIndex);
        const editedQaIndex = allDetails.findIndex(details => details.id === editIndex);
        if (editedQaIndex !== -1) {
            const editedQa = allDetails[editedQaIndex];

            const updateFaq = async () => {
                const token = localStorage.getItem('access_token');
                const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/sources/${editedQa.id}`,
                    {
                        question: editQuestion,
                        answer: editAnswer,
                        type: 'qa',
                        chatbot_id: id
                    },
                    {
                        headers: {
                            Authorization: token,
                        },
                    }
                );

                const updatedQa = {
                    ...editedQa,
                    question: editQuestion,
                    answer: editAnswer,
                    num_of_chars: response.data.num_of_chars,
                };

                setAllDetails(allDetails.map(detail => detail.id === editedQa.id ? updatedQa : detail));

                setChatStatus('Update Required');
                setEditIndex(null);
                setEditDialogOpen(false);
                setIsModified(true);
            };

            toast.promise(
                updateFaq(),
                {
                    pending: 'Updating FAQ...',
                    success: 'FAQ updated successfully',
                    error: 'Error updating FAQ'
                },
                {
                    position: "top-center"
                }
            ).then(() => {
                setLoading(false);
            }).catch(error => {
                console.error('Error updating FAQ:', error);
                setLoading(false);
            });
        } else {
            console.error('Invalid edit index or QA data');
        }
    };


    const handleCancelEdit = () => {
        setEditIndex(null);
        setEditQuestion('');
        setEditAnswer('');
    };

    const handleSearch = () => {
        const filteredDetails = allDetails.filter(detail => (
            (
                detail.type === 'qa' &&
                (detail.question?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    detail.answer?.toLowerCase().includes(searchQuery.toLowerCase()))
            ) ||
            detail.type === 'url' && (
                detail.url?.toLowerCase().includes(searchQuery.toLowerCase())
            ) ||
            detail.type === 'file' && (
                detail.file_name?.toLowerCase().includes(searchQuery.toLowerCase()
                )))).map(detail => ({
                    question: detail.question || '',
                    answer: detail.answer || '',
                    id: detail.id || '',
                    num_of_chars: detail.num_of_chars || 0,
                    type: detail.type || '',
                    file_name: detail.file_name || '',
                    url: detail.url || '',
                }));
        setAllDetailsTable(filteredDetails);
    };

    const handleDeleteAllQas = async () => {
        try {
            const token = localStorage.getItem('access_token');
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/sources`, {
                params: {
                    type: 'qa',
                    chatbot_id: id
                },
                headers: {
                    Authorization: token,
                },
            });

            // Remove all QAs from the state
            const updatedDetails = allDetails.filter(detail => detail.type !== 'qa');
            setAllDetails(updatedDetails);
            setChatStatus('Update Required');
        } catch (error) {
            console.error('Error deleting all QAs:', error);
        }
    };

    const handleDeleteAllUrls = async () => {
        try {
            const token = localStorage.getItem('access_token');
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/sources`, {
                params: {
                    type: 'url',
                    chatbot_id: id
                },
                headers: {
                    Authorization: token,
                },
            });

            // Remove all URLs from the state
            const updatedDetails = allDetails.filter(detail => detail.type !== 'url');
            setAllDetails(updatedDetails);
            setChatStatus('Update Required');
        } catch (error) {
            console.error('Error deleting all URLs:', error);
        }
    };

    const handleClearSearch = () => {
        setSearchQuery('');
        // Restore the original qasList from allDetails

        setAllDetailsTable(allDetails);
    };

    const handleDelete = async (source_id) => {
        const deleteSource = async () => {
            const token = localStorage.getItem('access_token');
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/sources/${source_id}`, {
                headers: {
                    Authorization: token,
                },
            });

            const updatedDetails = allDetails.filter(detail => detail.id !== source_id);
            setAllDetails(updatedDetails);
            setAllDetailsTable(updatedDetails);

            setChatStatus('Update Required');
            setIsModified(true);
        };

        toast.promise(
            deleteSource(),
            {
                pending: 'Deleting...',
                success: 'Deleted successfully',
                error: 'Error deleting'
            },
            {
                position: "top-center"
            }
        ).catch(error => {
            console.error('Error deleting source:', error);
        });
    };


    const handleSuccessfulUpload = (uploadedFilesData) => {
        console.log('Uploaded Files Data:', uploadedFilesData);
        const newFiles = uploadedFilesData.map(file => ({
            type: 'file',
            file_name: file.file_name,
            id: file.source_id,
            num_of_chars: file.num_of_chars,
            status: 'Pending'
        }));
        setAllDetails(prevDetails => {
            const updatedDetails = [...prevDetails, ...newFiles];
            console.log('Updated Details:', updatedDetails);
            return updatedDetails;
        });
        setAllDetailsTable(prevDetails => {
            const updatedDetails = [...prevDetails, ...newFiles];
            console.log('Updated Details:', updatedDetails);
            return updatedDetails;
        });
        setIsModified(true);
        setChatStatus('Update Required');
    };

    const updateQasList = (newQas) => {
        const formattedQas = newQas.map(qa => ({
            question: qa.question,
            answer: qa.answer,
            source_id: qa.source_id,
            num_of_chars: qa.num_of_chars,
            status: 'Pending'
        }));
        setAllDetails(prevDetails => [
            ...prevDetails,
            ...formattedQas.map(qa => ({
                type: 'qa',
                question: qa.question,
                answer: qa.answer,
                id: qa.source_id,
                num_of_chars: qa.num_of_chars,
                status: 'Pending'
            }))
        ]);
        setChatStatus('Update Required');
        setIsModified(true);
    };
    const filteredMappedLinks = useMemo(() => {
        if (!urlSearchQuery) return mappedLinks;
        // Escape regex special characters except * and %
        const escapedQuery = urlSearchQuery.replace(/[-[\]{}()+?.,\\^$|#\s]/g, '\\$&').replace(/[*%]/g, '.*');
        try {
            const regex = new RegExp(escapedQuery, 'i');
            return mappedLinks.filter(link => regex.test(link));
        } catch (e) {
            console.error('Invalid regex:', e);
            return mappedLinks;
        }
    }, [mappedLinks, urlSearchQuery]);
    const formatLocalDate = (dateString) => {
        // Parse the GMT date string into a Date object
        const date = new Date(dateString);
      
        // Format the date for the user's locale
        const formattedDate = new Intl.DateTimeFormat(navigator.language, {
          dateStyle: 'medium', // e.g., "03 Dec 2024"
          timeStyle: 'short', // e.g., "4:18 AM"
        }).format(date);
      
        return formattedDate;
      };
    const renderContent = () => {
        // Compute filteredMappedLinks based on urlSearchQuery
        switch (activeSubtab) {
            case 'qas':
                return (
                    <div>
                        <Typography>The questions and answers in this section will be used by the bot to respond precisely as given. You can add FAQs individually by clicking on "Add New FAQ" below. Alternatively, you may <b>bulk add the FAQs</b> by uploading a file using the format from <a href="https://aissieragfiles.blob.core.windows.net/chatbot-icons/Q&A Sample.csv" download="Q&A Sample.csv">this example</a>. Please note that the bot is intelligent enough to understand and respond to similar queries, so users do not need to ask questions in exactly the same wording as provided in the FAQ.</Typography>
                        <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-evenly" }}>
                            <Button variant="contained" onClick={openFaqDialog} sx={{ marginTop: '1rem' }}>Add New FAQ</Button>{!isMobile && (

                                <>

                                    <Typography sx={{ color: "#8c8b8b", fontWeight: 700, alignSelf: "center" }}>OR</Typography>
                                    <FileUpload label={'Drag and drop your CSV file here, or click to select it. (20 MB limit)'} type={'qa'} multiple={false} details={allDetails} onSuccessfulUpload={handleSuccessfulUpload} updateQasList={updateQasList} fileTypes={["CSV"]} /> </>)}
                        </div>
                        {error && <p className="error">{error}</p>}

                    </div>
                );
            case 'upload':
                return (
                    <div>
                        <Typography>The files you upload will be utilised by the chatbot to respond to your customers' enquiries more effectively.</Typography>
                        <FileUpload
                            label="Drag and drop your PDF, XLSX, CSV, DOCX, or TXT file here, or click to select it. (20 MB limit)"
                            type="file"
                            multiple={true}
                            details={allDetails}
                            onSuccessfulUpload={handleSuccessfulUpload}
                            fileTypes={['PDF', 'DOCX', 'TXT', 'CSV', 'XLSX']}
                        />
                    </div>
                );

            case 'website':
                return (
                    <div>
                        <Typography>Enter your website below, and the chatbot will scan all web pages starting from the provided URL to answer customer queries.</Typography>
                        <div className="url-field" style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                            <TextField
                                type="text"
                                placeholder="https://www.your-website.com.au/"
                                id="crawlUrl"
                                className="url-input"
                                value={websiteUrl}
                                onChange={(e) => setWebsiteUrl(e.target.value)}
                                fullWidth
                            />
                            <Button
                                variant="contained"
                                disabled={isMapping || buttonsDisabled}
                                onClick={() => handleMapWebsites(websiteUrl)}
                                sx={{ marginLeft: '0.5rem' }}
                            >
                                {isMapping ? <CircularProgress size={24} /> : 'Map Websites'}
                            </Button>
                        </div>

                        {/* Display mapped links with checkboxes if available */}
                        {mappedLinks.length > 0 && (
                            <div style={{ marginTop: '1rem' }}>
                                <Typography variant="h6" gutterBottom>
                                    Mapped Websites
                                </Typography>

                                {/* New URL Search Box */}
                                <div className="url-search-bar" style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '1rem' }}>
                                    <TextField
                                        type="text"
                                        placeholder="Search URLs..."
                                        value={urlSearchInput}
                                        onChange={(e) => setUrlSearchInput(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                    />
                                    <Button variant='outlined' startIcon={<SearchIcon />} onClick={() => setUrlSearchQuery(urlSearchInput)}>Search</Button>
                                    <Button variant='outlined' color='error' startIcon={<Clear />} onClick={() => { setUrlSearchInput(''); setUrlSearchQuery(''); }}>Clear</Button>
                                </div>

                                <Box sx={{ display: 'flex', justifyContent: "flex-end", marginBottom: '0.5rem' }}>
                                    <Button
                                        variant="outlined"
                                        onClick={handleSelectAll} // Updated to handleSelectAll from filtered list
                                        disabled={selectedLinks.length >= Math.min(filteredMappedLinks.length, 10) || buttonsDisabled}
                                        sx={{ marginRight: '0.5rem' }}
                                    >
                                        Select All
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={handleDeselectAll}
                                        disabled={selectedLinks.length === 0 || buttonsDisabled}
                                    >
                                        Deselect All
                                    </Button>
                                </Box>
                                <Paper variant="outlined" sx={{ maxHeight: 300, overflow: 'auto', padding: '1rem', display: 'flex', flexDirection: 'column' }}>
                                {filteredMappedLinks.map((link, index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    checked={selectedLinks.includes(link)}
                                                    onChange={() => handleSelectLink(link)}
                                                    disabled={!selectedLinks.includes(link) && selectedLinks.length >= 10}
                                                />
                                            }
                                            label={link}
                                        />
                                    ))}
                                </Paper>
                                <Box sx={{ marginTop: '1rem' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleFetchWebsitesContents}
                                        disabled={selectedLinks.length === 0 || isFetchingContents || buttonsDisabled}
                                    >
                                        {isFetchingContents ? <CircularProgress size={24} /> : 'Fetch Websites Contents'}
                                    </Button>
                                </Box>
                            </div>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    // Sorting logic
    const handleSort = (column) => {
        let direction = 'asc';
        if (sortConfig.key === column && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key: column, direction });
    };

    useEffect(() => {
        if (sortConfig.key) {
            const sortedData = [...allDetails].sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
            setAllDetails(sortedData);
        }
    }, [sortConfig, allDetails]);

    // Handler to select/deselect individual links with a maximum limit of 10
    const handleSelectLink = (link) => {
        if (selectedLinks.includes(link)) {
            setSelectedLinks(selectedLinks.filter(l => l !== link));
        } else {
            if (selectedLinks.length < 10) {
                setSelectedLinks([...selectedLinks, link]);
            } else {
                toast.error('You can select a maximum of 10 websites.');
            }
        }
    };

    // Updated Handler to select all links from the filtered list (up to 10)
// Remove useMemo from handleSelectAll
const handleSelectAll = () => {
    const limitedLinks = urlSearchQuery ? filteredMappedLinks.slice(0, 10) : mappedLinks.slice(0, 10);

    setSelectedLinks(limitedLinks);
    if (limitedLinks.length >= 10) {
        toast.info('Only the first 10 websites have been selected.');
    } else {
        toast.success('All websites have been selected.');
    }
};


    // Handler to deselect all links
    const handleDeselectAll = () => {
        setSelectedLinks([]);
    };

    return (
        <div className="knowledge-base-tab-container">
            <Box sx={{ padding: isMobile ? '1rem' : '2rem' }}>
                <Grid2 container spacing={2} sx={{ justifyContent: " space-between", alignItems: "center" }}>
                    <Typography variant="h4" fontWeight="700" >Knowledge Base</Typography>
                    <Grid2 id="train-chatbot-button" sx={{ display: "flex", justifyContent: " space-between", alignItems: "center" }}>
                        <Typography fontWeight="700" marginRight={"2rem"}>Status: {chatStatus}</Typography>
                        <TrainChatbot disabled={!(chatStatus === "Update Required" || chatStatus === "Update Failed")}
                            onTrainingStart={() => setChatStatus('Update in Progress')}
                            onTrainingComplete={(status) => setChatStatus(status)}
                        />
                    </Grid2>
                </Grid2>
                <Typography fontWeight="400" marginBottom={"1rem"}>You can upload files, provide FAQs, and/or link websites for the AI assistant to use when responding to user queries.</Typography>
            </Box>
            <Tabs
                value={activeSubtab}
                onChange={(e, val) => setActiveSubtab(val)}
                aria-label="knowledge base subtabs"
                variant={isMobile ? "fullWidth" : "standard"}
                scrollButtons={isMobile ? "auto" : "off"}
                allowScrollButtonsMobile
                visibleScrollbar
                sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
                <Tab value="upload" label="File Upload" id="file-upload-tab" />
                <Tab value="qas" label="Frequently Asked Questions" id="faq-tab" />
                <Tab value="website" label="Websites" id="website-tab" />
            </Tabs>
            <div className="knowledge-base-tab">
                <Paper square={false} elevation={4} style={{ padding: "0.5rem", width: '100%', marginBottom: "1rem" }}>
                    {renderContent()}

                </Paper>
            </div>
            <div className="sources" id="sources">
                <Paper elevation={4} square={false} style={{ width: '100%' }}>
                    <div className="sources-content">

                        <Typography variant='h6' sx={{ fontWeight: 700 }}>Current Knowledge Base</Typography>
                        <Typography>Below, you will find all the Knowledge Base content used by your AI assistant. If this section is empty, please make sure to upload the necessary data above.</Typography>
                        <div className="qas-list">
                            <div className="search-bar" style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '1rem' }}>
                                <TextField
                                    type="text"
                                    placeholder="Search Knowledge Base..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    variant="outlined" // You can change this to "filled" or "standard" depending on your design preference
                                    fullWidth // This will make the input field take up the full width of its container
                                />
                                <Button variant='outlined' startIcon={<SearchIcon />} onClick={handleSearch}>Search</Button>
                                <Button variant='outlined' color='error' startIcon={<Clear />} onClick={handleClearSearch}>Clear</Button>
                            </div>
                            {/*} <Button variant="contained" color='error' sx={{ marginRight: '0.5rem' }} onClick={() => openConfirmDialog('Are you sure you want to delete all FAQs? This action cannot be undone.', handleDeleteAllQas)} startIcon={<DeleteIcon />}>
                            Delete All FAQs
                        </Button> */}
                        </div>
                        <TableVirtuoso
                            title="Test"
                            allowFullScreen={true}
                            selected={true}
                            about="test"
                            useWindowScroll
                            style={{ height: "100%", overflowX: "initial" }}
                            data={allDetailsTable}
                            components={VirtuosoTableComponents}
                            fixedHeaderContent={() => (
                                <TableRow>
                                    {/*<TableCell style={{ width: '5%' }}></TableCell> */}
                                    <TableCell style={{ width: '40%', cursor: 'pointer', whiteSpace: 'normal', wordBreak: 'break-word' }} onClick={() => handleSort('question')}>
                                        Knowledge Base Item {sortConfig.key === 'question' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : '▲▼'}
                                    </TableCell>
                                    <TableCell style={{ width: '5%', cursor: 'pointer' , whiteSpace: 'normal', wordBreak: 'break-word'}} onClick={() => handleSort('type')}>
                                        Type {sortConfig.key === 'type' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : '▲▼'}
                                    </TableCell>
                                    <TableCell style={{ width: '10%', cursor: 'pointer' , whiteSpace: 'normal', wordBreak: 'break-word'}} onClick={() => handleSort('num_of_chars')}>
                                        Characters (total {totalNumOfChars.toLocaleString('en-AU')}) {sortConfig.key === 'num_of_chars' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : '▲▼'}
                                    </TableCell>
                                    <TableCell style={{ width: '20%' , whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                        Action
                                    </TableCell>
                                    <TableCell style={{ width: '10%', whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                        Status
                                    </TableCell>
                                    <TableCell style={{ width: '10%' , whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                        Updated
                                    </TableCell>
                                </TableRow>
                            )}
                            itemContent={(index, kb) => (
                                <>
                                    {/*<TableCell>
                                        <Checkbox
                                            checked={isSelected(kb.id)}
                                            onChange={() => handleSelectRow(kb.id)}
                                        />
                                    </TableCell>*/}
                                    <TableCell style={{ width: '40%' , whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                        {kb.type === "file" && kb.file_name}
                                        {kb.type === "url" && kb.url}
                                        {kb.type === "qa" && (
                                            <>
                                                {kb.question} <br />
                                            </>
                                        )}
                                    </TableCell>
                                    <TableCell style={{ width: '5%' , whiteSpace: 'normal', wordBreak: 'break-word'}}>{kb.type === "qa" && "FAQ"}
                                        {kb.type === "file" && "File"}
                                        {kb.type === "url" && "Website"}</TableCell>
                                    <TableCell style={{ width: '10%' , whiteSpace: 'normal', wordBreak: 'break-word'}}>{kb.num_of_chars}</TableCell>
                                    <TableCell style={{ width: '20%' , whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                        {kb.type === "qa" && (
                                            <Button
                                                variant="outlined"
                                                sx={{ marginRight: '0.5rem' }}
                                                onClick={() => handleOpenEditDialog(kb.id, kb.question, kb.answer)}
                                                startIcon={<EditIcon />}
                                                size={isMobile ? "small" : "medium"}
                                                fullWidth={isMobile}
                                            >
                                                Edit
                                            </Button>
                                        )}
                                        <Button variant="outlined" color="error"
                                            onClick={() => openConfirmDialog('Are you sure you want to delete this item? This action cannot be undone.', () => handleDelete(kb.id))}
                                            startIcon={<DeleteIcon />}
                                            size={isMobile ? "small" : "medium"}
                                            fullWidth={isMobile}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' , whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                        {kb.status}
                                    </TableCell>
                                    <TableCell style={{ width: '10%' , whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                        {formatLocalDate(kb.updated)}
                                    </TableCell>
                                </>
                            )}
                        />
                    </div>

                </Paper>
            </div>
            <Dialog
                fullScreen={isMobile}

                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                aria-labelledby="confirm-dialog-title"

            >
                <DialogTitle id="confirm-dialog-title">Confirm Action</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
                    <Button
                        onClick={() => {
                            onConfirm();
                            setConfirmDialogOpen(false);
                        }}
                        color="error"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={faqDialogOpen} onClose={closeFaqDialog}
                fullScreen={isMobile}
                aria-labelledby="add-faq-dialog-title"
            >



                <DialogTitle id="add-faq-dialog-title">Add FAQ</DialogTitle>

                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Question"
                        multiline
                        type="text"
                        fullWidth
                        value={newQuestion}
                        onChange={(e) => setNewQuestion(e.target.value)}
                        variant="outlined"
                        size={isMobile ? "small" : "medium"}
                        inputProps={{ maxLength: 255 }} // Set your desired max length here

                    />
                    <TextField
                        margin="dense"
                        label="Answer"
                        type="text"
                        fullWidth
                        multiline
                        value={newAnswer}
                        onChange={(e) => setNewAnswer(e.target.value)}
                        variant="outlined"
                        size={isMobile ? "small" : "medium"}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={closeFaqDialog}>Cancel</Button>
                    <Button onClick={handleSaveFaq} disabled={loading} size={isMobile ? "small" : "medium"}>
                        Save
                    </Button>
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: '#092F36',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </DialogActions>
            </Dialog>
            <Dialog open={editDialogOpen} onClose={handleCloseEditDialog}
                fullScreen={isMobile}
                aria-labelledby="edit-faq-dialog-title"


            >
                <DialogTitle id="edit-faq-dialog-title">Edit FAQ</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Question"
                        type="text"
                        fullWidth
                        value={editQuestion}
                        onChange={(e) => setEditQuestion(e.target.value)}
                        variant="outlined"
                        size={isMobile ? "small" : "medium"}
                    />
                    <TextField
                        margin="dense"
                        label="Answer"
                        type="text"
                        fullWidth
                        multiline
                        value={editAnswer}
                        onChange={(e) => setEditAnswer(e.target.value)}
                        variant="outlined"
                        size={isMobile ? "small" : "medium"}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog}>Cancel</Button>
                    <Button disabled={loading} onClick={() => {
                        setLoading(true);
                        saveEditedQa();
                    }}
                        color="primary"
                        size={isMobile ? "small" : "medium"}
                    >
                        Save
                    </Button>
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: '#092F36',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default KnowledgeBaseTab;
