import React from 'react';
import '../css/ErrorMessage.css'; // Assuming you have CSS for error messages

const ErrorMessage = ({ message }) => {
  return (
    <div className="error-message">
      <p>{message}</p>
    </div>
  );
};

export default ErrorMessage;
