import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './Navigation.css';
import Scheduler from './components/Scheduler'
import Chatbots from './pages/Chatbots';
import ContactUs from './ContactUs';
import Privacy from './Privacy';
import Terms from './Terms';
import Login from './pages/Login';
import Profile from './components/Profile';
import ResetPassword from './components/ResetPassword';
import HubSpot from './components/HubSpot';
import Signup from './pages/Signup';
import './App.css';
import { AuthProvider } from './components/AuthContext';
import ChatbotDetail from './pages/ChatbotDetail';
import Pricing from './components/PricingPage';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-phone-number-input/style.css'

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
            backgroundColor: '#06525B',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#092F36', // Add your desired hover colour here
            },
          }),
          ...(ownerState.variant === 'text' &&
            ownerState.color === 'primary' && {
              color: '#092F36',
              borderColor: ' #092F36',
              '&:hover': {
                backgroundColor: '#e5e5e5',
                borderColor: '#092F36', // Add your desired hover colour here
            },
          }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
            color: '#092F36',
            borderColor: ' #092F36',
            '&:hover': {
              backgroundColor: '#e5e5e5',
              borderColor: '#092F36', // Add your desired hover colour here
            },
          })
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: '#092F36',
        },
        icon: {
          color: '#092F36',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { backgroundColor: '#fff', // Set the background color to white
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#092F36',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#092F36',
          },
        },
        notchedOutline: {
          borderColor: '#092F36',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#e5e5e5',
            color: '#092F36',
            '&:hover': {
              backgroundColor: '#d4d4d4',
            }
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderRight: '1px solid #ccc',
          minWidth: '13rem',
        },
        indicator: {
          backgroundColor: '#092F36', // Change the color of the tab indicator
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#092F36',
            backgroundColor: '#f2f2f2',
          },
          '&:hover': {
            backgroundColor: '#e5e5e5',
          },
        },
      },
    },
    // Add the style overrides for the Dialog components
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#f5f5f5', // Change the background color
          color: '#06525B', // Change the text color
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          //backgroundColor: '#06525B', // Change the background color of the title
          //color: '#fff', // Change the text color of the title
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: '#f5f5f5', // Change the background color of the content
          color: '#06525B', // Change the text color of the content
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: '#f5f5f5', // Change the background color of the content
          color: '#06525B', // Change the text color of the content
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#092F36',

          '&.Mui-focused': {
            color: '#06525B',
          },
        }
      }
    }
  }
});

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <div className="App">
            <div className="content">
              <Routes>
                <Route path="/" element={<Chatbots />} />
                <Route path="/chatbots" element={<Chatbots />} />
                <Route path="/chatbots/:id" element={<ChatbotDetail />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/terms-and-conditions" element={<Terms />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/hubspot" element={<HubSpot />} />

                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/scheduler" element={<Scheduler />} />

              </Routes>
            </div>
          </div>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
