import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Tooltip, IconButton } from '@mui/material';
import './PricingPage.css';
import { FaCheck } from 'react-icons/fa';
import InfoIcon from '@mui/icons-material/HelpOutline';


const featureTooltips = {
    'Schedule appointments and send reminders': 'AIssie AI can schedule appointments and send booking reminders.',
    'Capture leads': 'The AIssie AI assistant may capture relevant information while chatting with end users.',
    '15 AI assistant responses per month': 'Receive 15 responses from your AI assistant each month on the free plan.',
    '1 AI assistant': 'You can create and manage 1 AI assistant with this plan.',
    '400,000 characters/AI assistant': 'The maximum character limit that can be processed per AI assistant (~5MB). ',
    '1 team member': 'Invite 1 team member to collaborate on your AI assistant.',
    '10 team members': 'Invite 10 team members to collaborate on your AI assistant.',
    'Unlimited links to train on': 'You can use as many links as you want to train your AI assistant.',
    'Embed on unlimited websites': 'Embed your AI assistant on any number of websites.',
    'View chat history': 'Access past interactions your AI assistant has had with users.',
    'GPT-4o-mini model': 'The AI assistant uses the GPT-4o-mini model for generating responses.',
    'AI assistants get deleted after 14 days of inactivity on the free plan.': 'Inactive AI assistants on the free plan are deleted after 14 days of no activity.',
    '500 AI assistant responses per month': 'Receive 500 responses from your AI assistant each month.',
    '5,000,000 characters/AI assistant': 'The maximum character limit that can be processed per AI assistant (~50MB).',
    '2 AI assistants': 'You can create and manage up to 2 AI assistants with this plan.',
    'Facebook, Instagram and WhatsApp integration': 'Integrate your AI assistant with Facebook, Instagram and WhatsApp.',
    '10,000 AI assistant responses/month': 'Receive 10,000 responses from your AI assistant each month.',
    '10 AI assistants': 'You can create and manage up to 10 AI assistants with this plan.',
    '11,000,000 characters/AI assistant': 'The maximum character limit that can be processed per AI assistant (~140MB).',
    'Premium GPT-4o models': 'Access both GPT-4o-mini and premium GPT-4o models.',
    'Eveything in Business + Advanced CRM & API Integrations': 'All Business plan features plus advanced CRM and API integrations.',
    'Custom AI assistant responses/month': 'The number of responses can be customised based on your needs.',
    'Prioritised Support': 'Get prioritised customer support for faster resolutions.',
    'Remove "Powered by AIssie"': 'Remove the "Powered by AIssie" branding from your AI assistant.'
};
const pricingPlans = [

    {
        type: 'Starter',
        originalPrice: '$99', // Original price
        discountedPrice: '$59', // Discounted price
        duration: 'Per Month',
        mostPopular: true, // Added flag
        features: [
            '500 AI assistant responses per month',
            '2 AI assistants',
            '5,000,000 characters/AI assistant',
            '1 team member',
            'Unlimited links to train on',
            'Embed on unlimited websites',
            'View chat history',
            'GPT-4o-mini model',
            'Capture leads',
            'Facebook, Instagram and WhatsApp integration'
        ]
    },
    {
        type: 'Business',
        originalPrice: '$259', // Original price
        discountedPrice: '$199', // Discounted price
        duration: 'Per Month',
        features: [
            '10,000 AI assistant responses/month',
            '10 AI assistants',
            '11,000,000 characters/AI assistant',
            '10 team members',
            'Unlimited links to train on',
            'Embed on unlimited websites',
            'View chat history',
            'Premium GPT-4o models',
            'Capture leads',
            'Facebook, Instagram and WhatsApp integration',
            'Schedule appointments and send reminders'

        ]
    },
    {
        type: 'Enterprise',
        price: 'Custom Pricing',
        features: [
            'Eveything in Business + Advanced CRM & API Integrations',
            'Custom AI assistant responses/month',
            'Prioritised Support',
            'Remove "Powered by AIssie"',
        ]
    }
];

const PricingPage = ({ details, profile }) => {
    const [subscriptionType, setSubscriptionType] = useState(details.subscription_type);
    const [email, setEmail] = useState(profile.email);

    return (
        <Box>
            <Box mb={2}>
                <Typography variant="h4" fontWeight="700" padding="1rem 0">Subscription</Typography>
                <Typography fontWeight="400" marginBottom={"1rem"}>Current subscription: {subscriptionType}</Typography>
                {subscriptionType.toLowerCase() !== "trial" && (
                    <Typography fontWeight="400" marginBottom={"1rem"}>
                        <b>
                            Click <a href={`https://billing.stripe.com/p/login/test_8wM14d5WNeIN0Zq000?prefilled_email=${email}`} target='_blank'>here</a> to manage your subscription and payment details.
                        </b>
                        Please use the same email address that you provided during payment.
                    </Typography>
                )}


            </Box>
            <div className="pricing-container">

                {pricingPlans.map((plan) => (
                    <div
                        className={`pricingCard ${plan.mostPopular ? 'most-popular' : ''}`}
                        key={plan.type}
                        id={plan.type.toLowerCase()}
                    >
                        {plan.mostPopular && <div className="most-popular-label">Most Popular</div>}
                        <h2 className="pricingCard-title">{plan.type}</h2>
                        {/* Show strikethrough for original price and discounted price */}
                        <p className="pricingCard-price">
                            {plan.originalPrice && (
                                <span style={{ textDecoration: 'line-through', color: '#888', marginRight: '8px' }}>
                                    {plan.originalPrice}
                                </span>
                            )}
                            {plan.discountedPrice ? plan.discountedPrice : plan.price}
                        </p>

                        <p className="pricingCard-duration">{plan.duration}</p>
                        <ul className="pricingCard-features">
                            {plan.features.map((feature, index) => (
                                <li key={index} className="pricingCard-feature">
                                    <div className="feature-content">
                                        <FaCheck className="check-icon" /> {feature}
                                        <Tooltip
                                            title={featureTooltips[feature] || ''}
                                            placement="top"
                                            enterTouchDelay={0}
                                            leaveTouchDelay={5000}
                                        >
                                            <IconButton size="small">
                                                <InfoIcon style={{ color: 'rgba(0, 0, 0, 0.88)' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        {subscriptionType.toLowerCase() === "trial" ? (
                            plan.type.toLowerCase() === 'enterprise' ? (
                                <a href="https://www.aissie.com.au/contactus" target='_blank' className="subscribe-button">Contact Us</a>
                            ) : (
                                plan.type.toLowerCase() === "starter" ? (
                                    <a href={`https://buy.stripe.com/fZe6py47j6ws0h2dQQ?prefilled_email=${email}`} target='_blank' className="subscribe-button">Upgrade</a>
                                ) :
                                    (<a href={`https://buy.stripe.com/8wM6py47j5so6FqbIJ?prefilled_email=${email}`} target='_blank' className="subscribe-button">Upgrade</a>
                                    )
                            )
                        ) :
                            plan.type.toLowerCase() === 'enterprise' ? (
                                <a href="https://www.aissie.com.au/contactus" target='_blank' className="subscribe-button">Contact Us</a>
                            ) : (
                                <a href={`https://billing.stripe.com/p/login/fZeg1wdSHg68bAI144?prefilled_email=${email}`} target='_blank' className="subscribe-button">{plan.type === subscriptionType ? 'Manage Subscription' : plan.type !== 'Business' && subscriptionType === "Business" ? 'Downgrade' : 'Upgrade'}</a>
                            )}
                    </div>
                ))}

            </div>
        </Box>
    );
}

export default PricingPage;