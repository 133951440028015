import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  useMediaQuery
} from '@mui/material';
import axios from 'axios';
import Spinner from './Spinner'; // Assuming Spinner component is in the same directory
import { toast } from 'react-toastify';
import { TableVirtuoso } from 'react-virtuoso';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@mui/material/styles';

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} sx={{ elevation: 1, variant: "outlined", square: false, height: '100%' }} {...props} ref={ref} className="table-container" />
  )),
  Table: (props) => (
    <Table {...props} className="table" sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
  TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

const MyTeam = () => {
  const [myTeam, setMyTeam] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => () => { });
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editUser, setEditUser] = useState({ id: null, first_name: '', last_name: '', email: '', role: '' });
  const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);
  const [newUser, setNewUser] = useState({ first_name: '', last_name: '', email: '', role: '' });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile screens


  // Fetch user data on page load
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users`, {
          headers: {
            Authorization: token,
          },
        });
        setMyTeam(response.data.users);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);


  const handleOpenNewUserDialog = () => {
    setNewUserDialogOpen(true);
  };

  const handleCloseNewUserDialog = () => {
    setNewUser({ first_name: '', last_name: '', email: '', role: '' });
    setNewUserDialogOpen(false);
  };

  const handleNewUserChange = (field, value) => {
    setNewUser(prevState => ({ ...prevState, [field]: value }));
  };

  const handleSaveNewUser = async () => {
    try {
      const token = localStorage.getItem('access_token');
      await toast.promise(
        axios.post(`${process.env.REACT_APP_API_URL}/api/users`, newUser, {
          headers: {
            Authorization: token,
          },
        }).then(response => {
          if (response.data.success) {
            // If success, add the new user to the team and close the dialog
            setMyTeam([...myTeam, response.data]);
            handleCloseNewUserDialog();
          } else {
            // If success is false, throw an error with the message from response
            throw new Error(response.data.message || 'Error adding user');
          }
        }),
        {
          pending: 'Saving new user...',
          success: 'User added successfully!',
          error: {
            render({ data }) {
              // Render the message from the error
              return data.message || 'Error adding user';
            }
          }
        },
        {
          position: "top-center"
        }
      );
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };
  

  const openConfirmDialog = (message, onConfirmAction) => {
    setDialogMessage(message);
    setOnConfirm(() => onConfirmAction);
    setConfirmDialogOpen(true);
  };

  const handleDelete = async (user_id) => {
    const deleteUser = async () => {
      const token = localStorage.getItem('access_token');
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/users/${user_id}`, {
        headers: {
          Authorization: token,
        },
      });

      const updatedDetails = myTeam.filter(user => user.id !== user_id);
      setMyTeam(updatedDetails);
    };

    toast.promise(
      deleteUser(),
      {
        pending: 'Deleting...',
        success: 'Deleted successfully',
        error: 'Error deleting'
      },
      {
        position: "top-center"
      }
    ).catch(error => {
      console.error('Error deleting source:', error);
    });
  };

  const handleOpenEditDialog = (user) => {
    setEditUser(user);
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setEditUser({ id: null, first_name: '', last_name: '', email: '', role: '' });
  };

  const handleEditChange = (field, value) => {
    setEditUser(prevState => ({ ...prevState, [field]: value }));
  };
  const handleSaveEdit = async () => {
    try {
      const token = localStorage.getItem('access_token');
      await toast.promise(
        axios.patch(`${process.env.REACT_APP_API_URL}/api/users/${editUser.id}`, {
          first_name: editUser.first_name,
          last_name: editUser.last_name,
          email: editUser.email,
        }, {
          headers: {
            Authorization: token,
          },
        }).then(response => {
          if (response.data.success) {
            // If success, update the user in myTeam
            const updatedDetails = myTeam.map(user =>
              user.id === editUser.id ? response.data : user
            );
            setMyTeam(updatedDetails);
          } else {
            // If success is false, throw an error with the message from response
            throw new Error(response.data.message || 'Error updating user');
          }
        }),
        {
          pending: 'Saving changes...',
          success: 'User updated successfully!',
          error: {
            render({ data }) {
              // Render the message from the error
              return data.message || 'Error updating user';
            }
          }
        },
        {
          position: "top-center"
        }
      );
    } catch (error) {
      console.error('Error updating user:', error);
    } finally {
      handleCloseEditDialog();
    }
  };
  

  return (
    <Box>
      <Typography variant="h4" fontWeight="700" padding="1rem 0">My Team</Typography>
      <Typography fontWeight="400" marginBottom={"1rem"}>You can add more members to help manage the AIssie AI assistant. Each added user will have full control over its operations.</Typography>
      {loading ? (
        <Spinner />
      ) : (
        <Box>
          <Paper elevation={4} square={false} style={{ marginTop: '2em', width: '95%', padding: "0.5rem" }}>
            <Button variant="contained" onClick={handleOpenNewUserDialog} sx={{ marginTop: '1rem' }}>
              Add New User
            </Button>
            <TableVirtuoso
              useWindowScroll
              style={{ height: "100%", overflowX: "initial" }}
              data={myTeam}
              components={VirtuosoTableComponents}
              fixedHeaderContent={() => (
                <TableRow>
                  <TableCell style={{ width: '20%' }}>First Name</TableCell>
                  <TableCell style={{ width: '20%' }}>Last Name</TableCell>
                  <TableCell style={{ width: '30%' }}>Email</TableCell>
                  {/*<TableCell style={{ width: '20%' }}>Role</TableCell>*/}
                  <TableCell style={{ width: '30%' }}>Action</TableCell>
                </TableRow>
              )}
              itemContent={(index, user) => (
                <>
                  <TableCell style={{ width: '20%' , whiteSpace: 'normal', wordBreak: 'break-word' }}>{user.first_name}</TableCell>
                  <TableCell style={{ width: '20%' , whiteSpace: 'normal', wordBreak: 'break-word'}}>{user.last_name}</TableCell>
                  <TableCell style={{ width: '30%' , whiteSpace: 'normal', wordBreak: 'break-word'}}>{user.email}</TableCell>
                  {/*<TableCell style={{ width: '20%' }}>{user.role}</TableCell>*/}
                  <TableCell style={{ width: '30%' , whiteSpace: 'normal', wordBreak: 'break-word'}}>
                    <Button
                      variant="outlined"
                      sx={{ marginRight: '0.5rem' }}
                      onClick={() => handleOpenEditDialog(user)}
                      size={isMobile ? "small" : "medium"}
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      size={isMobile ? "small" : "medium"}

                      color="error"
                      onClick={() => openConfirmDialog('Are you sure you want to delete this user? This action cannot be undone.', () => handleDelete(user.id))}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </>
              )}
            />
          </Paper>
          <Dialog open={newUserDialogOpen} onClose={handleCloseNewUserDialog}>
            <DialogTitle>Add New User</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="First Name"
                type="text"
                fullWidth
                value={newUser.first_name}
                onChange={(e) => handleNewUserChange('first_name', e.target.value)}
              />
              <TextField
                margin="dense"
                label="Last Name"
                type="text"
                fullWidth
                value={newUser.last_name}
                onChange={(e) => handleNewUserChange('last_name', e.target.value)}
              />
              <TextField
                margin="dense"
                label="Email"
                type="email"
                fullWidth
                value={newUser.email}
                onChange={(e) => handleNewUserChange('email', e.target.value)}
              />

            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseNewUserDialog}>Cancel</Button>
              <Button onClick={handleSaveNewUser} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
            <DialogTitle>Confirm Action</DialogTitle>
            <DialogContent>
              <DialogContentText>{dialogMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
              <Button onClick={() => { onConfirm(); setConfirmDialogOpen(false); }} color="error">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={editDialogOpen} onClose={handleCloseEditDialog}>
            <DialogTitle>Edit User</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="First Name"
                type="text"
                fullWidth
                value={editUser.first_name}
                onChange={(e) => handleEditChange('first_name', e.target.value)}
              />
              <TextField
                margin="dense"
                label="Last Name"
                type="text"
                fullWidth
                value={editUser.last_name}
                onChange={(e) => handleEditChange('last_name', e.target.value)}
              />
              <TextField
                margin="dense"
                label="Email"
                type="email"
                fullWidth
                value={editUser.email}
                onChange={(e) => handleEditChange('email', e.target.value)}
              />

            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEditDialog}>Cancel</Button>
              <Button onClick={handleSaveEdit} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </Box>
  );
};

export default MyTeam;
